.carousel {
    overflow: hidden;
}  
.inner {
    display: flex;
    white-space: nowrap;
    transition: transform 0.3s;
}
.status {
    position: absolute;
    top: inherit !important;
    bottom: 15px;
    right: 22px !important;
    width: 44px;
    border-radius: 12px;
    background-color: rgb(0 0 0 / 0.3);
    color: #fff;
    line-height: 20px;
    height: 24px;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    text-shadow: none !important;
    font-size: 12px !important;
    text-align: center;
    letter-spacing: -0.02em;
    display: flex;
    justify-content: center;
    align-items: center;
}