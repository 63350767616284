.other-recommend-contents-wrapper {
    padding-top: 40px;
    padding-left: 20px;
    background: #fff;
}
.other-recommend-contents-wrapper > .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height, or 125% */
    letter-spacing: -0.02em;
    /* black */
    color: #000000;
    text-align: left;
}
.other-recommend-contents-wrapper .divider {
    height: 1px;
    background-color: black;
    margin-top: 10px;
}
.other-item-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E9E7EC;
}
.other-item-wrapper .text-contents {
    margin-right: 25px;
}
.other-recommend-contents-wrapper .other-item-wrapper .title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */
    letter-spacing: -0.02em;
    /* black */
    color: #000000;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
    width: 180px;
}
.other-recommend-contents-wrapper .other-item-wrapper .description-wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    text-align: left;
    /* black */
    color: #000000;
    width: 178px;
}
