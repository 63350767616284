
.zipCard {
    position: relative;
    padding: 35.467% 0 0 0;
    margin: 0 20px 60px 20px;
    cursor: pointer;
}
.contentsWrapper {
    background: linear-gradient(0, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 267px;
    padding: 0 15px 15px 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.txtWrapper {
    text-align: left;
    color: #fff;
}
.typeWrapper {
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    margin-bottom: 10px;
}
.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
}
.subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 10px;
}
.profileImg {
    width: 50px;
    height: 50px;
    background: url('/assets/banner//zip-profile-dummy.png'), #fff;
    background-size: cover;
    border-radius: 50px;
}
.profileName {
    margin-top: 5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    /* identical to box height, or 10px */
    text-align: center;
    letter-spacing: -0.02em;
    /* gray1 */
    color: #F7F7FC;
}