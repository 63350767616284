.brandLink {
  display: flex;
  align-items: center;
  justify-content: center;
  .hasLink {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    .float {
      // float: right;
      // display: flex;
      // align-items: flex-end;
      // shape-outside: inset(calc(100% - 12px) 0 0 0);
      // height: 100%;
      a {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url('/assets/icons/arrow-right-at-brand.svg');
        background-position: center 34px;
        background-size: contain;
        // background: url('/assets/icons/arrow-right-at-brand.svg') no-repeat center / contain;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
