.wrapper {
    margin-bottom: 100px;
}

.title_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    .titles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        strong {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 4px;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.02em;
        }
    }
    .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
.contents {
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
}
.item {
    width: 50%;
    cursor: pointer;
    &:nth-child(1) {
        margin-right: 5px;
    }
}
.img {
    background: url('/assets/banner//dummy-banner-1.png') no-repeat center / cover;
    height: 200px;
    border-radius: 5px;
}
.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.02em;
    color: #000000;
    margin-top: 10px;
    text-align: left;
}
