.map_area {
  width: 100%;
  height: 225px;
}
.map_info {
  h3 {
    color: var(--color-black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    text-align: left;
    margin: 16px 0 6px 0;
  }
  .map_txt {
    display: flex;
    justify-content: flex-start;
    .address_txt {
      color: var(--color-black, #000);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
      letter-spacing: -0.28px;
    }
    .copy {
      color: var(--color-gray-5, #78737d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
      letter-spacing: -0.28px;
      text-decoration-line: underline;
      margin-left: 6px;
      cursor: pointer;
    }
  }
}
.findMapBtn {
  width: 100%;
  display: flex;
  height: 46px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--color-gray-3, #d2d0d5);
  background: var(--color-white, #fff);
  margin-top: 16px;
  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url('/assets/icons/find_map_btn_icon.svg') no-repeat center /
      cover;
    margin-left: 8px;
  }
}
