.joinField {
    margin-top: 40px;
    &:nth-child(1) {
        margin-top: 0;
    }
}
.label {
    height: 26px;
    border-bottom: 1px solid #F7F7FC;
    display: flex;
    padding-left: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: flex-end;
    strong {
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #000000;
    }
    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #D2D0D5;
        margin-left: 5px;
    }
}
.inputWrapper {
    display: flex;
    align-items: center;
    height: 54px;
    input {
        width: 100%;
        height: 24px;
        padding-left: 20px;
        &:disabled {
            color: #D2D0D5 !important;
            -webkit-text-fill-color: #D2D0D5;
            background-color: white;
            opacity: 1;
        }
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #D2D0D5 !important;
        }
    }
    border-bottom: 1px solid #F7F7FC;
}
.warningArea {
    text-align: left;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #FF3E48;
    padding-top: 15px;
    padding-left: 20px;
}
