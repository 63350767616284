.container {
    position: relative;
    min-height: 100vh;
}
.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 46px 0 60px 0;
    strong {
        font-weight: 700;
        font-size: 26px;
        line-height: 100%;
        letter-spacing: -0.02em;
        padding-bottom: 10px;
        color: #000000;
    }
    span {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #78737D;
    }
}
.error {
    color: #FF3E48;
    text-align: left;
    padding: 0 20px;
    margin-top: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    & > span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 8px;
    }
}
.retry {
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    padding-top: 15px;
    padding-left: 20px;
    cursor: pointer;
}
.passwordReset {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #000000;
        padding-right: 20px;
        cursor: pointer;
    }
}

.password {
    margin-top: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    padding: 0 20px;
    color: #78737D;
    text-align: left;
}

.passwordError {
    margin-top: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    padding: 0 20px;
    color: #FF3E48;
    text-align: left;
}

.hide {
    display: none !important;
}

.bottom {
    margin-top: 60px;
    padding: 0 20px;
}

.auth_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .time {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #FF4B84;
        margin-right: 5px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        min-height: inherit !important;
        height: calc(var(--var, 1vh) * 100) !important;
    }
}
