
.qr_popup {
    background-color: #fff;
    white-space: pre;
    padding: 40px 16px 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.32px;
    color: #433e48;
    border-radius: 10px;
  
    img {
      margin: 20px auto 8px auto;
      width: 80px;
      height: 80px;
    }
  
    .desc {
      display: block;
      font-size: 12px;
      line-height: 16px;
    }
  
    .close_btn {
      display: block;
      width: 283px;
      margin-top: 20px;
      border: 1px solid #e9e7ec;
      border-radius: 5px;
      font-size: 16px;
      line-height: 50px;
    }
  }
  
  .dimmed_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 5;
  }
  