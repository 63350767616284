.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000;
  margin: 0 20px;
  padding-bottom: 15px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  h1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
  }
  button {
    display: flex;
    align-items: center;
    font-size: 14px;
    span {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url('/assets/icons/arrow-right-at-brand.svg') no-repeat center /
        contain;
    }
    button {
      display: flex;
      align-items: center;
      font-size: 14px;
      span {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url('/assets/icons/arrow-right-at-brand.svg') no-repeat
          center / contain;
      }
    }
  }
}
