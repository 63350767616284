.wrapper {
  margin: 30px 20px 60px 20px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;

  .imgWrapper {
    position: relative;
    width: 100%;
    padding-bottom: 100%;

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  .contents_title_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 10px 0;

    .title {
      overflow: hidden;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      white-space: pre-line;
    }
    .icon {
      width: 15px;
      height: 14px;
    }
  }

  .contents {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #78737d;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.list {
  display: flex;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e9e7ec;
  cursor: pointer;

  .imgWrapper {
    flex: none;
    width: 68px;
    height: 68px;
    border-radius: 4px;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  .txtWrapper {
    width: calc(100% - 160px);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 0 12px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: -0.28px;
    text-align: left;
  }

  .collectionTitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .collectionSubTitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 4px;
    font-weight: 400;
  }
  .price {
    margin-top: 10px;
    span {
      margin-right: 3px;
      color: #ff4b84;
    }
  }
}

.likeWrapper {
  padding: 4px;

  .likeIcon {
    display: inline-block;
    margin-top: 20px;
    width: 20px;
    height: 20px;
    background: url('/assets/icons/brand_list_unscrap_gray_icon.svg') no-repeat
      center / contain;
    cursor: pointer;
    &.filled {
      background: url('/assets/icons/brand_list_doscrap_icon.svg') no-repeat
        center / contain;
    }
  }
}
// styles.scss

.giftCard {
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: flex-start;
  border: 1px solid var(--G2, #e9e7ec);
  background: #fff;
  margin-top: 16px;
}

.image-container {
  margin-right: 15px;
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.content {
  flex: 1;
  text-align: left;
  padding-left: 12px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.description {
  font-size: 14px;
  color: #757575;
}

.arrow-container {
  margin-left: 15px;
}

.arrow {
  font-size: 20px;
  color: #757575;
}
