.header {
  display: flex;
  justify-content: center;
  align-items: center;
  .headerIcon {
    display: inline-block;
    background: url('/assets/icons/brand_address_selector_down_icon.svg')
      no-repeat center / cover;
    width: 16px;
    height: 8px;
    cursor: pointer;
  }
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 10px;
}

.ios {
  padding-bottom: 50px !important;
}
