.dimmed_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5;
}
.container {
  z-index: 15;
  padding: 30px 20px 13px 20px;
  background: #fff;
  width: 100%;
  border-radius: 10px 10px 0 0;

  &.ios {
    padding-bottom: 43px;
  }

  @media screen and (min-width: 600px) {
    width: 375px;
  }
}

.content_wrapper {
  background: url('/assets/imgs/coupon_img.png') right top no-repeat;
  background-size: 110px;
}

.badgeArea {
  display: flex;
}
.badge {
  padding: 3px 6px;
  background-color: #000;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
}

.message_wrapper {
  margin-top: 10px;
}

.message {
  text-align: left;
  .txt {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.44px;
    white-space: pre;
  }

  .slog {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
    color: #78737d;
  }
}
.black_filled_btn {
  display: block;
  width: 100%;
  margin-top: 31px;
  padding: 15px 0;
  background: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  border-radius: 5px;
  letter-spacing: -0.32px;
}

.txt_button {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #9f9ca3;
  padding: 15px 0;
}

.qr_popup {
  background-color: #fff;
  white-space: pre;
  padding: 40px 16px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
  color: #433e48;
  border-radius: 10px;

  img {
    margin: 20px auto 8px auto;
    width: 80px;
    height: 80px;
  }

  .desc {
    display: block;
    font-size: 12px;
    line-height: 16px;
  }

  .close_btn {
    display: block;
    width: 283px;
    margin-top: 20px;
    border: 1px solid #e9e7ec;
    border-radius: 5px;
    font-size: 16px;
    line-height: 50px;
  }
}
