.banner-scroller .banner-wrapper {
  background: #000;
  position: relative;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1;
}
.banner-scroller .banner-wrapper .whats-new-txt {
  position: absolute;
  color: #fff;
  text-align: left;
  padding: 20px 0 0 20px;
  font-weight: 600;
  font-size: 24px;
  z-index: 3;
  left: 0;
  right: 0;
  height: 140px;
  background: linear-gradient(0, rgba(0, 0, 0, 0) 11.88%, #000000 95.86%);
}
.banner-scroller .home-banner-content {
  position: relative;
  /* height: 540px; */
  min-width: 100%;
  width: 100%;

  background: #000;
}
.banner-scroller .banners::-webkit-scrollbar {
  background: #000;
  display: none;
}
.banner-scroller .home-banner-content.show {
  animation: fade 1s ease-in-out;
}
.banner-scroller .home-banner-content.hide {
  display: none;
}
.banner-scroller .home-banner-content .img-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 111.73%;
}

.banner-scroller .home-banner-content .img-wrapper:before {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  height: 100px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  content: '';
}

.banner-scroller .home-banner-content .img-wrapper:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 156px;
  background: linear-gradient(0, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  content: '';
}

.banner-scroller .home-banner-content .img-wrapper div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.banner-scroller .home-banner-content .img-wrapper .txt {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-left: 20px;
}

.banner-scroller .home-banner-content .txt-wrapper {
  position: absolute;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 30px 40px;
  background-color: transparent;
  text-align: left;
  overflow: hidden;
  margin-bottom: -200px;
  opacity: 0;
  transition: margin-bottom 0.6s, opacity 0.7s 0.3s;
}
.banner-scroller .home-banner-content .txt-wrapper .banner-title {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
  word-break: keep-all;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.banner-scroller .home-banner-content .txt-wrapper .banner-extra-txt {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-top: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.banner-scroller
  .home-banner-content
  .txt-wrapper
  .banner-extra-txt
  .banner-btn {
  width: 115px;
  height: 30px;
  background: #fff;
  color: #000;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  border-radius: 30px;
}
.banner-scroller .banners {
  /* display: flex; */
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.banners .carousel li {
  height: 540px;
}
.banner-scroller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: env(safe-area-inset-top);
}

.banner-scroller.android {
  padding-top: var(--status-bar-height);
}

.category-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 58px;
}
.category-wrapper .category-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 20px; */
  padding: 0 20px;
  cursor: pointer;
  width: 100%;
}
.category-wrapper .category-title-wrapper .arrow-right2-icon {
  display: block;
  width: 20px;
  height: 20px;
}
.category-wrapper .category-title-wrapper .titles {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.category-wrapper .category-title-wrapper .titles .title {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 4px;
  letter-spacing: -0.02em;
}
.category-wrapper .category-title-wrapper .titles .sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.category-wrapper .category-contents {
  margin: 30px 0 0 0;
  padding: 0 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.category-wrapper .home-item-wrapper {
  width: 48.36%;
  /* height: 235px; */
  /* margin-right: 11px; */
  cursor: pointer;
}
.category-wrapper .home-item-wrapper .home-item-img-wrapper {
  width: 100%;
  /* height: 162px; */
}
.category-wrapper .home-item-wrapper .category {
  display: flex;
  justify-content: flex-start;
}
.category-wrapper .home-item-wrapper .category span {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 10px 0 5px 0;
  color: #9f9ca3;
}
.category-wrapper .home-item-wrapper .title {
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
.category-wrapper .divider {
  height: 1px;
  background-color: #e9e7ec;
  /* margin: 70px 20px 0 20px; */
  margin-top: 70px;
  width: 89.34%;
}
.category-wrapper .divider.end {
  width: 100%;
}

@keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
