
.my-info-wrapper {
    /* margin-top: 20px; */
    padding: 20px 20px 30px 20px;
    background: #fff;
    /* min-height: 300px; */
    border-bottom: 1px solid #E9E7EC;
}
.my-info-wrapper .profile {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 20px;
    line-height: 100%;
}
.my-info-wrapper .profile .profile-image-wrapper {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    background: #E9E7EC;
    border-radius: 70px;
    margin-bottom: 20px;
    background-size: cover !important;
}
.my-info-wrapper .profile .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: url('../../assets/imgs/mypage_option.svg') no-repeat center / cover;
}

.my-info-wrapper .profile .edit-user {
    display: flex;
    align-items: flex-start;
    height: 60px;
    
}
.my-info-wrapper .profile .edit-user div {
    display: flex;
    align-items: center;
}
.my-info-wrapper .profile .edit-user .sns-wrapper .sns-icon {
    margin-right: 5px;
    cursor: pointer;
}
.my-info-wrapper .profile .edit-user .edit-profile-button .edit-profile {
    width: 97px;
    height: 26px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #9F9CA3;
    border: 1px solid #E9E7EC;
    border-radius: 12px;
    cursor: pointer;
    margin: 0px 10px;
}
.my-info-wrapper .profile .edit-user .edit-profile-button .edit-profile .pen-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    margin-right: 3px;
}
.my-info-wrapper .profile .edit-user .options .gear-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
}

.my-info-wrapper .profile-name-sns-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.my-info-wrapper .profile-name-sns-link .profile-name {
    display: flex;
    flex-direction: column;
}
.my-info-wrapper .profile-name-sns-link .profile-name .name {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.02em;
    margin-right: 10px;
    max-width: 180px;
}
.my-info-wrapper .profile-name-sns-link .profile-name span {
    word-break: break-all;
    text-align: left;
}
.my-info-wrapper .profile-name-sns-link .profile-name span.creator {
    margin-bottom: 6px;
}
.my-info-wrapper .profile-name-sns-link .profile-name button.link-to-curator {
    width: 65px;
    height: 20px;
    background: #fff;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    padding: 4px 0px 4px 0px;
    color: #FF4B84;
    display: inline-flex;
    align-items: center;
    margin-bottom: 6px;
}
.my-info-wrapper .profile-name-sns-link .profile-name .creator {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #FF4B84;
}
.my-info-wrapper .profile-name-sns-link .profile-name button.link-to-curator .icon {
    display: inline-block;
    width: 10px;
    height: 8px;
    margin-left: 4px;
}
.my-info-wrapper .profile-name-sns-link .sns-wrapper .sns-icon {
    margin-right: 5px;
    cursor: pointer;
}
.my-info-wrapper .introduce {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    margin: 20px 0 30px 0;
    font-weight: 400;
    line-height: 150%;
    /* gray5 */
    color: #78737D;
    text-align: left;
}

.my-info-wrapper .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.my-info-wrapper .button-wrapper button {
    width: 50px;
    height: 74px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.my-info-wrapper .button-wrapper.pc button.link-to-bookmark {
    /* margin-left:44px; */
}
.my-info-wrapper .button-wrapper button.link-to-bookmark {
    width: 63px;
}
.my-info-wrapper .button-wrapper button.link-to-brands {
    width: 63px;
    /* margin-right:44px; */
}
.my-info-wrapper .button-wrapper button.link-to-collection {
    width: 63px;
}
.my-info-wrapper .button-wrapper button .icon-wrapper {
    width: 50px;
    height: 50px;
    min-height: 50px;
    border-radius: 50px;
    border: 1px solid #E9E7EC;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.my-info-wrapper .button-wrapper button span {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #433E48;
}
.my-info-wrapper .button-wrapper button .icon-wrapper .icon {
    width: 16px;
    height: 20px;
}
.my-info-wrapper .button-wrapper button .icon-wrapper .icon.like-icon {
    width: 20px;
}

/* menu */
.my-knewnew-options {
    height: 100vh;
    background: #F7F7FC !important;
}
 .my-info-menus {
    background-color: #F7F7FC;
 }
 .my-info-menus button {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
 }
 .my-info-menus .my-info-menu.btn {
    border-bottom: 1px solid #f7f7fc;
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
 }
 .my-info-menus button .title {
    display: flex;
    align-items: center;
 }
 .my-info-menus button .title .bookmark-icon {
    margin-right: 7px;
    width: 15px;
 }
 .my-info-menus button .title .cnt {
    font-weight: 400;
    color:#9F9CA3;
    margin-left: 5px;
 }
 .my-info-menus button .login-info {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: right;
 }
 .my-info-menus button .arrow-right2-icon {
    display: inline-block;
    width: 8px;
    height: 15px;
 }

/* logo */
.my-knewnew-footer {
    height: calc(100vh - (340px + env(safe-area-inset-top)));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.my-knewnew-footer.android {
    height: calc(100vh - (340px + var(--status-bar-height))) !important;
}
.my-knewnew-footer .bottom-messages {
    padding-left: 20px;
    padding-bottom: 40px;
}
.my-knewnew-footer .bottom-messages .logo-box {
    width: 106px;
    height: 18px;
    background: url('../../assets/imgs/knewnew_black_logo_new.svg') no-repeat center / cover;
    margin-bottom: 21px;
}
.my-knewnew-footer .bottom-messages .message {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9F9CA3;
    text-align: left;
}

 .my-knewnew-contents .modal-wrapper {
    justify-content: center !important;
    align-items: center !important;
    padding: 0 20px;

 }
 .my-knewnew-contents .modal-wrapper .tast-tag-modal {
    background: #fff;
    width: 335px;
    padding: 20px;
    border-radius: 10px;
 }
 .my-knewnew-contents .modal-wrapper .tast-tag-modal .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
 }
 .my-knewnew-contents .modal-wrapper .tast-tag-modal .modal-title .gray-close-icon {
    width: 14px;
    height: 14px;
 }
 .my-knewnew-contents .modal-wrapper .tast-tag-modal .modal-body {
    display: flex;
    flex-wrap: wrap;
 }

 .my-knewnew-contents .modal-wrapper .tast-tag-modal .modal-body .tast-icon {
    margin-top: 5px;
 }
.my-knewnew-contents .no-tast-tag-wrapper {
    margin: 20px 0;
}
 .my-knewnew-contents .no-tast-tag-wrapper .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px !important;
 }

 .my-knewnew-contents .no-tast-tag-wrapper .arrow-right2-icon {
    display: inline-block;
    width: 5px;
    height: 10px;
    margin-left: 5.5px;   
 }


 .my-knewnew-contents .back-navigation {
    padding: 20px 20px 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
}
.my-knewnew-contents .back-navigation .back-btn {
    display: inline-flex;
    width: 89px;
    height: 26px;
    background: #000;
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding: 6px 10px;
    justify-content: flex-start;
    align-items: center;
}
.my-knewnew-contents .back-navigation .back-btn .go-to-list-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
}

 .my-edit-profile {
    height: calc(100vh - 56px);
    margin-top: 30px;
    background-color: #FFF;
 }

 .my-edit-profile  .back-navigation {
    margin: 20px 0 24px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.my-edit-profile  .back-navigation .back-btn {
    display: inline-flex;
    width: 90px;
    height: 26px;
    background: #000;
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding-left: 10px;
    cursor: pointer;
    align-items: center;
}

.my-edit-profile  .back-navigation .back-btn .go-to-list-icon {
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
}

.my-edit-profile .user-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.my-edit-profile .user-profile .profile-image {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: #E9E7EC;
    background-size: cover !important;
}
.my-edit-profile .user-profile .profile-image .no-image-icon {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-size: cover;
}
.my-edit-profile .user-profile .profile-image .circle-white-plus-icon {
    display: inline-block;
    position: absolute;
    bottom: 0;
    width: 18px;
    height: 18px;
    right: 0;
}
.my-edit-profile .user-profile .remove-image-txt {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    text-align: center;
    letter-spacing: -0.02em;
    /* gray3 */
    color: #D2D0D5;
    margin-top: 10px;
}
.my-edit-profile .info-form {
    margin-top: 20px;
}
.my-edit-profile .info-form .input-form .label {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
    padding: 0 20px;
}

.my-edit-profile .info-form .input-form .asterisk{
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    letter-spacing: -0.02em;
    color: #FF4B84;
    text-align: left;    
}

.my-edit-profile .info-form .input-form .label .option {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.02em;
    /* gray3 */
    color: #D2D0D5;
}

.my-edit-profile .info-form .input-form.name .input {
    border-top: 1px solid #F7F7FC;
    border-bottom: 1px solid #F7F7FC;
    height: 54px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
    padding: 0 20px;
}

.my-edit-profile .info-form .input-form.name .input input[type='text'] {
    height: 24px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    letter-spacing: -0.02em;
}
.my-edit-profile .info-form .input-form.name .input input[type='text']::placeholder {
    color: #D2D0D5;
}

.my-edit-profile .info-form .input input[type='number']::placeholder {
    color: #D2D0D5;
}

.my-edit-profile .info-form .input-form.tast-tag .label .my-options-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
}
.my-edit-profile .info-form .input-form.tast-tag .tast-tag-wrapper {
    margin: 20px 0 40px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;
}
.my-edit-profile .info-form .input-form.tast-tag .tast-tag-wrapper .tast-tag-info {
    display: inline-block;
    /* width: 51px; */
    height: 22px;
    border-radius: 13px;
    padding: 5px 10px;
    border: 1px solid #000;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    margin-right: 5px;
    margin-bottom: 5px;
}
.my-edit-profile .info-form .introduction {
    margin-bottom: 55px;
}

.my-edit-profile .info-form .introduction .introduction-contents {
    margin-top: 25px;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    width: 100%;
    resize: none;
    outline-color: #FFF;
    padding: 5px 25px;
}
.my-edit-profile .info-form .introduction .introduction-contents:focus {
    outline: none;
}
.my-edit-profile .info-form .introduction .introduction-contents::placeholder {
    color:#D2D0D5;
}

.my-edit-profile .info-form .input-form.link-sns .sns-input-box {
    display: flex;
    height: 54px;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #F7F7FC;
    padding: 0 20px;
}

.my-edit-profile .info-form .input-form.link-sns .label {
    margin-bottom: 10px;
}
.my-edit-profile .info-form .input-form.link-sns .sns-input-box.youtube {
    border-top: 1px solid #F7F7FC;
}
.my-edit-profile .info-form .input-form.link-sns .sns-input-box .sns-input input {
    width: 300px;
    height: 21px;
    margin-left: 10.5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}
.my-edit-profile .info-form .input-form.link-sns .sns-input-box .sns-input input::placeholder {
    color:#D2D0D5; 
}

.my-edit-profile .btn-wrapper {
    margin: 60px 0;
    padding: 0 20px;
}
