.txt {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.02em;
}
.disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 22px;
    background: #FFF;
    border: 1px solid #9F9CA3;
    border-radius: 5px;
    color: #9F9CA3;
}
.active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 22px;
    background: #FFF;
    border: 1px solid #FF4B84;
    border-radius: 5px;
    color: #FF4B84;
    &:disabled {
        color: #D2D0D5;
        border: 1px solid #D2D0D5;
    }
}
.reSend {
    width: 82px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    border: 1px solid #000;
    border-radius: 5px;
    color: #000;
}
.error {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF4B84;
    width: 64px;
    & > span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 8px;
    }
}
.success {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #FF4B84;
    width: 64px;
    & > span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 8px;
    }
}
