
.slide-wrapper {
  display: flex;
  margin: 35px 0 0 0;
  overflow-x: auto;
  padding-left: 30px;
}
.slide-wrapper .item-wrapper {
  margin: 0 10px 32px 0;
  height: 240px;
  flex: 0 0 auto;
  flex-grow: 0;
}
.slide-wrapper::-webkit-scrollbar {
  display: none;
}
/* pick-item slide */
.pick-items.slide-wrapper .item-wrapper {
  width: 180px;
}
.pick-items.slide-wrapper .item-wrapper .item-contents {
  margin: 10px 0 0 0;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  text-align: center;
  letter-spacing: -0.02em;
}
.pick-items.slide-wrapper .item-wrapper .item-writer {
  margin-top: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  text-align: center;
  letter-spacing: -0.02em;

  color: rgba(255, 255, 255, 0.6);
}
/* //end pick-item slide */
/* new-item slide */
.new-items.slide-wrapper .item-wrapper {
  width: 130px;
}
.new-items.slide-wrapper .item-wrapper .item-contents {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: left;
  /* or 17px */
  margin-top: 10px;
  letter-spacing: -0.02em;
}
.new-items.slide-wrapper .item-wrapper .item-writer {
  margin-top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  letter-spacing: -0.02em;
  text-align: left;
  /* gray4 */

  color: #9F9CA3;
}
/* //end new-item slide */
/* list-item slide */
.list-items.slide-wrapper .item-wrapper {
  width: 130px;
}
.list-items.slide-wrapper .item-wrapper .item-contents {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: left;
  /* or 17px */
  margin-top: 10px;
  letter-spacing: -0.02em;
}
.list-items.slide-wrapper .item-wrapper .item-writer {
  margin-top: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  letter-spacing: -0.02em;
  text-align: left;
  /* gray4 */
  color: #9F9CA3;
}
/* //end list-item slide */

