@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.jsdelivr.net/npm/reset-css@5.0.1/reset.min.css');
@import './styles/css/global.css';
@import './styles/css/icons.css';
@import './styles/css/button.css';
@import './styles/css/pick-items.css';
@import './styles/css/new-items.css';
@import './styles/css/slide.css';
@import './styles/css/badge.css';
@import './styles/css/list-items.css';
@import './styles/css/recommend-contents.css';
@import './styles/css/feed.css';
@import './styles/css/other-recommend-contents.css';
@import './styles/css/foodlog.css';
@import './styles/css/comment.css';
@import './styles/css/filter.css';
@import './styles/css/modal.css';
@import './styles/css/login.css';
@import './styles/css/my-knewnew.css';
@import './styles/css/magazine.css';
@import './styles/css/home.css';
@import './styles/css/bookmark.css';
@import './styles/css/contents.css';
@import './styles/css/community.css';
@import './styles/css/my-contents.css';

html {
  -webkit-tap-highlight-color: transparent;
}

.App {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100%;
}

.App .main {
  /* position: relative; */
  box-shadow: 0px 0px 10px 0px #00000026;
  width: 375px;
  z-index:1;
  background: #fff;
}

.divider-recommend-main {
  height: 10px;
  width: 100%;
  background: #F7F7FC;
}

@media screen and (max-width: 600px) {
  .community .user-content-wrapper .content-image-wrapper::-webkit-scrollbar,
  .my-contents-wrapper .user-content-wrapper .content-image-wrapper::-webkit-scrollbar {
    display: none;
  }
  .App .main {
    width: 100% !important;
    /* min-height: 100vh; */
  }
  .main-menu-wrapper {
    width: 100% !important;
  }
  .banner-scroller .banner-wrapper {
    width: 100% !important;
  }
  .banner-scroller .home-banner-content {
    /* min-width: 100% !important;
    width: 100% !important; */
    flex: 1 0 100%;
  }
  .my-home .my-knewnew-footer {
    width: 100% !important;
  }
  .magazine-detail-footer  {
    width: 100% !important;
  }
  .card-list .banner .titles.news {
    bottom: -12px;
  }
  .content-brand .contents-footer,
  .content-zip .contents-footer {
    position: fixed;
  }
  .content-brand .how-this-contents {
    margin-bottom: 76px;
  }
  .community-detail-contents .community-footer {
    width: 100%;
  }

  .community .add-community-content-wrapper {
    left: inherit !important;
    right: 30px;
  }

  .community-edit-contents .contents-btn-wrapper.preview {
    width: 100%;
  }
  .comments-all .comments-wrapper .enter-comment-wrapper {
    width: 100% !important;
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;
