.curators {
    min-height: 100vh;
    background: black !important;
}
.banner {
    height: 300px;
    background: url('/assets/banner//curators-dummy.png');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.txtWrapper {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 300px;
    background-color: rgba(0,0,0,0.2);
    img {
      height: 22px;
      margin-bottom: 30px;
    }
}
.subTitle {
    font-family: Pretendard;
    font-weight: 200;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
}
.listsWrapper {
    background: #000;
    padding: 30px 30px 30px 30px;
}
.divider {
    background: #E9E7EC;
    height: 1px;
    margin: 0 20px;
}
.lists {
    /* padding-left: 10px;
    padding-right: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000;
}
.curatorRow {
    display: flex;
    margin-bottom: 20px;    
    width: 100%;
    justify-content: space-between;
}
.apply_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    margin-top: 60px;
    margin-bottom: 86px;
    & > span {
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #9F9CA3;
        margin-bottom: 10px;
    }
    & > button {
        border: 1px solid #FFFFFF;
        border-radius: 30px;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.02em;
        text-align: center;
        width: 165px;
        height: 30px;
    }
}