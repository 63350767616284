
.report_reason {
    margin-top: 30px;
}
.message {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    letter-spacing: -0.02em;
    margin-bottom: 40px;
    padding: 0 20px;
}
.report_title {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    line-height: 22.4px;
    padding: 0 20px;
    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16.8px;
    }
}
.report_reason_checker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    padding: 0 20px;
    .report_reason_item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 14px;
        input[type=checkbox] {
            /* display: block; */
            -webkit-appearance: none;
            appearance: none;
            /* For iOS < 15 to remove gradient background */
            background-color: #fff;
            /* Not removed via appearance */
            /* margin: 0; */
        
            margin-right: 10px;
            font: inherit;
            color: currentColor;
            width: 16px;
            height: 16px;
            border: 1px solid #E9E7EC;
            border-radius: 2px;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            &::before {
                content: "";
                width: 12px;
                height: 12px;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
            }
            &:checked {
                background: #000;
                border: 0;
                &::before {
                    transform: scale(1);
                    background-color: #fff;
                    transform-origin: bottom left;
                    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                }
            }
        }
    }
}
.detail_reason_wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 6px;
    padding: 0 20px;
    textarea {
        height: 160px;
        border: 1px solid #E9E7EC;
        border-radius: 5px;
        resize: none;
        padding: 12px 10px;
        outline: none;
    }
    span {
        margin-top: 10px;
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.04em;
        color: #433E48;
    }
    .text-length-counter {
        position: absolute;
        bottom: 52px;
        right: 10px;
        font-weight: 400;
        font-size: 12px;
        color: #D2D0D5;
    }
}
.report_btn_wrapper {
    margin-top: 50px;
    padding: 0 20px;
}
.input_form {
    margin-top: 40px;
    .label {
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        /* identical to box height, or 16px */
        letter-spacing: -0.02em;
        color: #000000;
        text-align: left;
        padding: 0 20px;
    }
    .asterisk{
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #FF4B84;
        text-align: left;    
    }
    .input {
        border-top: 1px solid #F7F7FC;
        border-bottom: 1px solid #F7F7FC;
        height: 54px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        // padding: 0 20px;
        input {
            height: 24px;
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            padding: 0 20px;
            &::placeholder {
                color: #D2D0D5;
            }
        }
    }
}
.request_email {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #433E48;
    margin: 14px 20px 40px 20px;
}
.extra_text {
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #433E48;
    margin: 10px 20px 0 20px;
}
