
html {
  -webkit-tap-highlight-color: transparent;
}

#storybook-root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.main {
  /* position: relative; */
  box-shadow: 0px 0px 10px 0px #00000026;
  width: 375px;
  z-index:1;
  background: #fff;
  min-height: 100vh;
  transition: opacity 2s 0 ease;
  position: relative;
}

@media screen and (max-width: 600px) {
    .main {
        width: 100% !important;
        /* min-height: 100vh; */
    }
}
