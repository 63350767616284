.modal {
  border-radius: 10px;
  background: var(--color-white, #fff);
  display: flex;
  width: 315px;
  padding: 40px 16px 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
