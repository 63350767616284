.itemCard {
    /* margin: 0 20px 20px 20px; */
    margin-bottom: 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%), url('/assets/banner//curator-created-item-dummy.png');
    background-size: cover;
    width: 100%;
    height: 200px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    color: #fff;
}
.txtWrapper {
    margin: 0 15px 15px 15px;
}
.typeInfo {
    font-weight: 700;
    font-size: 12px;
    text-align: left;
}
.title {
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
}
.subtitle {
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 14px;
    text-align: left;
}
