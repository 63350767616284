.push_config {
    min-height: 100vh;
}
.wrapper {
    width: 100%;
    height: 100px;
    padding: 20px;
    &.large {
        height: 110px;
        background: #F7F7FC;
    }
    .title {
        display: flex;
        justify-content: space-between;
        strong {
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #433E48;
            &.disabled {
                color: #D2D0D5;
            }
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: #FF4B84;
        }
    }
    .contents {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #78737D;
        text-align: left;
        margin-top: 10px;
    }
}
