.product_info {
  padding: 30px 20px 40px 20px;
  border-bottom: 1px solid #e9e7ec;
  &.used {
    .status {
      color: #9f9ca3;
    }
  }
  &.cancel {
    .status {
      color: #ff3e48;
    }
  }
  h2 {
    text-align: left;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
  }
  .contents {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .img_wrapper {
      min-width: 90px;
      width: 90px;
      height: 114px;
      background: #000;
    }
    .txt_wrapper {
      margin-left: 15px;
      h2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.32px;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .option {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: -0.28px;
          margin-bottom: 2px;
          text-align: left;
        }
      }
    }
  }
}
.discountInfo {
  padding: 40px 20px 40px 20px;
  border-bottom: 8px solid #f7f7fc;
  h2 {
    text-align: left;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
  }
  .info {
    .label {
      color: var(--5, #78737d);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.28px;
      text-align: left;
      .cnt {
        color: #ff4b84;
        margin-left: 5px;
      }
    }
    .applyCouponArea {
      display: flex;
      align-items: center;
      margin-top: 10px;
      input {
        width: 100%;
        height: 48px;
        border: 1px solid #e9e7ec;
        border-radius: 5px;
        padding: 0 20px;
        font-size: 14px;
        &::placeholder {
          color: #d2d0d5;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.28px;
        }
        &:disabled {
          background: #fff;
        }
      }
      button {
        width: 110px;
        height: 48px;
        border: 1px solid #000;
        border-radius: 5px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
        margin-left: 5px;
        padding: 0 10px;
      }
    }
  }
}
.buyer_info {
  padding: 40px 20px 40px 20px;
  border-bottom: 1px solid #f7f7fc;
  h2 {
    text-align: left;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
  }
  .info {
    .label {
      color: var(--5, #78737d);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.28px;
      text-align: left;
    }
    input {
      width: 100%;
      height: 48px;
      margin-top: 10px;
      border: 1px solid #e9e7ec;
      border-radius: 5px;
      padding: 0 20px;
      font-size: 14px;
      &::placeholder {
        color: #d2d0d5;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.28px;
      }
      &:focus {
        outline: none;
        border: 1px solid #9f9ca3;
      }
      &.error_border {
        border: 1px solid #ff3e48;
      }
    }
    .error {
      color: #ff3e48;
      text-align: left;
      margin-top: 15px;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: -0.02em;
      display: flex;
      justify-content: flex-start;
      & > span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 8px;
      }
    }
  }
}
.buy_info {
  padding: 40px 20px;
  .price_info {
    display: flex;
    justify-content: space-between;
    h2 {
      text-align: left;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.36px;
      margin-bottom: 20px;
    }
    .price {
      color: #ff4b84;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.36px;
      margin-bottom: 20px;
    }
  }
  .discountPrice {
    margin-bottom: 25px;
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;
        color: #78737d;
      }
    }
  }
  .agree_info {
    width: 100%;
    padding: 20px;
    background: #f7f7fc;
    border-radius: 5px;
    & > div {
      display: flex;
      justify-content: space-between;
      color: #78737d;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: -0.28px;
    }
    .message {
      display: flex;
      flex-direction: column;
      color: #78737d;
      margin-top: 20px;
      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 140%;
        letter-spacing: -0.28px;
      }
      div {
        font-weight: 400;
        text-align: left;
      }
    }
  }
  .process {
    margin-top: 40px;
    color: #78737d;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.28px;
  }
  .process_btn {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    background: #000;
    color: #fff;
    margin-top: 20px;
    &.disabled {
      background: #e9e7ec;
    }
  }
}
.personal_info_agree {
  padding: 30px 20px 0 20px;
  h2 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.32px;
    text-align: left;
    margin-bottom: 15px;
  }
  .options {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.312px;
    margin-bottom: 2px;
    .contents {
      text-align: left;
      margin-left: 5px;
    }
  }
}
.loading_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px);
  background: #f7f7fc;
}
.loading {
  .loader_icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/assets/icons/success_loading.gif') no-repeat center /
      cover;
  }
  h2 {
    margin-top: 30px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.4px;
  }
  .wait {
    margin-top: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
  }
}
.failed_info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .warning_icon {
    width: 50px;
    height: 50px;
    background: url('/assets/icons/warning-icon.svg') no-repeat center / cover;
    margin-bottom: 20px;
  }
  h2 {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.32px;
    margin-bottom: 6px;
  }
  .message {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
  }
  .btn_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .failed_btn {
      width: 100px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 7px;
      border-radius: 20px;
      border: 1px solid #000;
    }
  }
}
.success_info {
  padding: 0 0 40px 0;
  &.detail_info {
    .success_message {
      height: 310px;
    }
    .btn_wrapper {
      justify-content: center;
      .btn_request_cancel {
        padding: 8px 16px;
        border-radius: 5px;
        background: #e9e7ec;
        color: var(--b, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.32px;
      }
      .btn_personal_question {
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #e9e7ec;
        padding: 8px 16px;
        .kakao_icon {
          background: url('/assets/icons/kakao-share-icon.svg') no-repeat center /
            contain;
          display: inline-block;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .success_message {
    width: 100%;
    height: 366px;
    background: #000;
    padding: 0 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    > .title_ticket {
      display: block;
      margin-top: 40px;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.36px;
      text-align: left;
      width: 100%;
    }
    .check_wrapper {
      margin-top: 40px;
      background: #fff;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 36px;
      background: url('/assets/icons/success_icon.svg') no-repeat center / cover;
    }
    .message {
      margin-top: 10px;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: -0.4px;
      text-align: center;
    }
    .product_info {
      margin-top: 30px;
      color: #000;
      background: #fff;
      border-radius: 5px;
      width: 100%;
      height: 188px;
      padding: 0;
      .header {
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px dashed #e9e7ec;
        padding: 15px;
        h2 {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          letter-spacing: -0.28px;
          margin: 0;
        }
        .order_number {
          color: #78737d;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.24px;
        }
      }
      .product {
        display: flex;
        justify-content: flex-start;
        padding: 15px;
        .img_wrapper {
          min-width: 90px;
          width: 90px;
          height: 110px;
          background: #000;
        }
        .txt_wrapper {
          margin-left: 15px;
          h2 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: -0.32px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin: 0;
          }
          .option_info {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .option {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              letter-spacing: -0.28px;
            }
          }
        }
      }
    }
  }
  .buyer_info_row {
    padding: 40px 20px 0 20px;
    h2 {
      text-align: left;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.32px;
      padding-bottom: 20px;
      border-bottom: 1px solid #f7f7fc;
    }
    .info_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 15px 0;
      border-bottom: 1px solid #f7f7fc;
      .label {
        text-align: left;
        width: 100px;
        color: #9f9ca3;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.32px;
      }
      .contents {
        text-align: left;
        width: calc(100% - 100px);
        font-size: 14px;
      }
    }
  }
  .pay_info_row {
    padding: 40px 20px 0 20px;
    h2 {
      text-align: left;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.32px;
      padding-bottom: 20px;
      border-bottom: 1px solid #f7f7fc;
    }
    .info_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 15px 0;
      border-bottom: 1px solid #f7f7fc;
      &:nth-child(1) {
        border-top: 1px solid #f7f7fc;
        margin-top: 20px;
      }
      .label {
        text-align: left;
        width: 100px;
        color: #9f9ca3;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.32px;
      }
      .contents {
        text-align: left;
        width: calc(100% - 100px);
        font-size: 14px;
      }
    }
  }
  .alert_message {
    margin-top: 10px;
    color: #9f9ca3;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.24px;
    text-align: left;
    padding: 0 20px;
  }
  .btn_wrapper {
    margin: 50px 0 0 0;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    .btn_home {
      color: #9f9ca3;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      border: 1px solid #e9e7ec;
      margin-right: 5px;
    }
    .btn_buy_list {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #000;
    }
  }
}

.order_list {
  margin-top: 30px;
  padding: 0 20px;
  .no_order_items {
    height: calc(100vh - 108px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-5, #78737d);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
  }
  padding-bottom: 40px;
  .order_list_title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.36px;
    text-align: left;
    padding: 30px 20px 0 20px;
  }
  .order_list {
    // margin-top: 30px;
    // padding: 0 20px;
    .order_item_wrapper {
      &:nth-child(1) {
        margin-top: 0;
      }
      margin-top: 40px;
      .date {
        text-align: left;
        color: #9f9ca3;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.28px;
        margin-bottom: 10px;
      }
      .item_info {
        width: 100%;
        border: 1px solid #e9e7ec;
        border-radius: 5px;
        &.cancel {
          background: #f7f7fc !important;
          color: #9f9ca3 !important;
          .item_contents {
            .img_wrapper {
              opacity: 0.5;
            }
          }
        }
        &.used,
        &.expired {
          .item_title {
            h2 {
              color: #9f9ca3;
            }
          }
        }
        .item_title {
          height: 44px;
          border-bottom: 1px solid #e9e7ec;
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          h2 {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -0.28px;
            &.disabled {
              color: #9f9ca3;
            }
          }
          .go_to_detail {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #433e48;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -0.24px;
          }
        }
        .item_contents {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 15px;
          .txt_wrapper {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.28px;
            width: calc(100% - 85px);
            margin-right: 15px;
            h2 {
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%;
              letter-spacing: -0.32px;
              text-align: left;

              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
            .options {
              margin-top: 10px;
              text-align: left;

              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
            .count {
              text-align: left;
            }
          }
          .img_wrapper {
            width: 70px;
            height: 70px;
            border-radius: 5px;
            background: #000;
          }
        }
      }
    }
  }
}
.cancel_modal_body {
  h2 {
    margin: 20px 0;
    text-align: left;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.32px;
    span {
      color: #ff4b84;
    }
  }
  .option_list {
    .select_options {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
      input[type='radio'] {
        appearance: none;
        border: max(2px, 0.1em) solid #e9e7ec;
        border-radius: 50%;
        width: 16px;
        height: 16px;
      }
      input[type='radio']:checked {
        outline: 1px solid #e9e7ec;
        border: 3.5px solid #fff;
        background: #000;
      }
      // input[type=checkbox] {
      //     /* display: block; */
      //     -webkit-appearance: none;
      //     appearance: none;
      //     /* For iOS < 15 to remove gradient background */
      //     background-color: #fff;
      //     /* Not removed via appearance */
      //     /* margin: 0; */

      //     margin-right: 10px;
      //     font: inherit;
      //     color: currentColor;
      //     width: 16px;
      //     height: 16px;
      //     border: 1px solid #E9E7EC;
      //     border-radius: 2px;
      //     transform: translateY(-0.075em);
      //     display: grid;
      //     place-content: center;
      // }
      // input[type=checkbox]::before {
      //     content: "";
      //     width: 12px;
      //     height: 12px;
      //     transform: scale(0);
      //     transition: 120ms transform ease-in-out;
      //     box-shadow: inset 1em 1em var(--form-control-color);
      // }
      // input[type=checkbox]:checked {
      //     background: #000;
      //     border: 0;
      // }
      // input[type=checkbox]:checked::before {
      //     transform: scale(1);
      //     background-color: #fff;
      //     transform-origin: bottom left;
      //     clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      // }
    }
  }
  .notice {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 39px;
    div {
      text-align: left;
      color: #9f9ca3;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.24px;
      margin-bottom: 3px;
    }
  }
}

.payment_widget {
  .header {
    height: 56px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e9e7ec;
    span {
      color: #000;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.36px;
    }
  }
  .payment_info {
    padding: 20px;
    border-bottom: 10px solid #f7f7fc;
    text-align: left;
  }
  .price_wrapper {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.64px;
  }
  .name {
    color: var(--4, #9f9ca3);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.32px;
  }
  .payment_btn_wrapper {
    padding: 0 20px 40px 20px;
    button {
      width: 100%;
      height: 50px;
      background: #000;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
      border-radius: 5px;
    }
  }
  #payment-request-button {
    width: 100%;
    height: 50px;
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-family: 'Pretendard-Regular';
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.32px;
    border-radius: 5px;
  }
}
