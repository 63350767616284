.contentHeader {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  padding-top: env(safe-area-inset-top);
  padding-left: 20px;
  padding-right: 20px;
  height: calc(56px + env(safe-area-inset-top));
  background: transparent;
  &.android {
    padding-top: var(--status-bar-height);
    height: calc(56px + var(--status-bar-height));
  }
  &.scrolled {
    animation: fadeIn 0.5s;
    position: sticky;
    width: inherit;
    top: 0;
    background: #fff;
    border-bottom: 1px solid rgb(233, 231, 236);
    &.mobile {
      position: fixed !important;
    }
    .back {
      background: transparent;
      width: 36px;
      height: 36px;
      border-radius: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 5px;
      &.kakao {
        min-width: 76px;
      }
      .backIcon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('/assets/icons/overlay_header_back_black_icon.svg')
          no-repeat center / cover;
      }
      .kakaoWrap {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-right: 2px solid #E9E7EC;
        padding: 0 10px;
        .kakaoIcon {
          display: inline-block;
          min-width: 22px;
          height: 20px;
          background: url('/assets/icons/overlay_header_kakao_black.svg')
          no-repeat center / cover;
          margin-right: 4px;
        }
        .kakaoTxt {
          color: var(--color-black, #000);
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 16px */
          letter-spacing: -0.32px;
        }
      }
      .homeIcon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('/assets/icons/overlay_header_home_black.svg')
        no-repeat center / cover;
      }
    }
    .share {
      background: transparent;
      width: 36px;
      height: 36px;
      border-radius: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      .shareIcon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('/assets/icons/overlay_header_share_black_icon.svg')
          no-repeat center / cover;
      }
      cursor: pointer;
    }
    h3 {
      flex: 1;
      font-size: 18px;
      font-weight: 500;
      line-height: 100%; /* 18px */
      letter-spacing: -0.36px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .back {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    cursor: pointer;
    .backIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('/assets/icons/overlay_header_back_icon.svg')
        no-repeat center / cover;
    }
    .kakaoIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('/assets/icons/overlay_kakao_logo.svg')
      no-repeat center / cover;
    }
    .homeIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('/assets/icons/overlay_home_icon.svg')
      no-repeat center / cover;
    }
  }
  .share {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .shareIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('/assets/icons/overlay_popup_share_icon.svg')
        no-repeat center / cover;
    }
    cursor: pointer;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}
