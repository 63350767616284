.benefitItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background: #fff;
  border-bottom: 1px solid #e9e7ec;
  cursor: pointer;
  &:nth-child(1) {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:nth-last-child(1) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 0;
  }

  .benefitImg {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: url('/assets/banner//dummy-banner-1.png') no-repeat center /
      cover;
    margin-right: 12px;
  }
  .txtArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    overflow: hidden;

    .txts {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;

      .date {
        margin-bottom: 8px;
        color: #78737d;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
        text-align: left;
        &.red {
          color: #ff4b84;
        }
      }
      .title {
        color: #433e48;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .txtArrow {
      flex: none;
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url('/assets/icons/my_benefit_arrow.svg') no-repeat center /
        cover;
    }
  }
}
.benefits {
  padding: 30px 20px;
  background: #f7f7fc;
  .benefitInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .cnt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      strong {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 20.8px */
        letter-spacing: -0.32px;
      }
      span {
        color: #78737d;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 20.8px */
        letter-spacing: -0.32px;
        margin-left: 5px;
      }
    }
    .moveTo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      .txt {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
      }
      .move_to_link {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url('/assets/icons/my_benefit_move_to.svg') no-repeat center /
          contain;
        margin-left: 2px;
      }
    }
  }
}
.noList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #fff;
  height: 80px;
  border-radius: 4px;
  .txt {
    color: #9f9ca3;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    letter-spacing: -0.42px;
    text-align: left;
  }
  button {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 14px;
    background: #000;
    border-radius: 16px;
    text-align: left;
    .move_txt {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      color: #fff;
    }
    .move_to {
      display: inline-block;
      margin-left: 4px;
      width: 12px;
      height: 12px;
      background: url('/assets/icons/benefit_btn_arrow.svg') no-repeat center /
        cover;
    }
  }
}
