.push_list_item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 62px;
    margin-bottom: 20px;
    cursor: pointer;
    &.read {
        .img {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url('/assets/banner//brand-dummy-1.png') no-repeat center / cover;
        }
        .contents {
            color: #D2D0D5 !important;
            .message {
                .txt {
                    color: #D2D0D5 !important;
                }
            }
        }
    }
}
.img {
    min-width: 50px;
    height: 100%;
}
.img_dummy {
    background: url('/assets/banner//push_dummy.png') no-repeat center / cover !important;
}
.contents {
    margin-left: 15px;
    text-align: left;
    .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
    }
    .message {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 5px;
        .txt {
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #78737D;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .time {
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            display: flex;
            align-items: center;
            letter-spacing: -0.02em;
            color: #D2D0D5;
            margin-top: 8px;
        }
    }
}
.collection_icon {
    background: url('/assets/icons/my-page-heart-icon.svg') no-repeat center / cover;
    width: 20px;
    height: 20px;
    display: inline-block;
}
.couponWrapper {
    margin-bottom: 20px;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 5px 10px 0px rgba(0, 0, 0, 0.02), 0px 15px 14px 0px rgba(0, 0, 0, 0.01);
    .couponTxtArea {
        border-bottom: 1px solid #E9E7EC;
        
        .discountTxt {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .discountAmt {
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%; /* 24px */
                letter-spacing: -0.48px;
            }
            .only {
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%; /* 12px */
                letter-spacing: -0.24px;
                color: #9F9CA3;
            }
        }
        .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: -0.32px;
            color: #000;
            margin: 10px 0;
            text-align: left;
        }
    }
    .couponDateArea {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        div:nth-child(1) {
            text-align: left;
            .expireDate {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 14px */
                letter-spacing: -0.28px;
                color: #9F9CA3;
                .ddayCnt {
                    color: #FF4B84;
                    margin-left: 6px;
                }
            }
            .minAmount {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 14px */
                letter-spacing: -0.28px;
                color: #9F9CA3;
                margin-top: 8px;
            }
            .maxDiscount {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 14px */
                letter-spacing: -0.28px;
                color: #9F9CA3;
                margin-top: 8px;
            }
        }
        .useButtonArea {
            display: flex;
            align-items: flex-end;
            .useButton {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 24px;
                border-radius: 16px;
                padding: 6px 10px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 12px */
                letter-spacing: -0.24px;
                color: #000;
                background: #F7F7FC;
                .useButtonIcon {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: url('/assets/icons/coupon_warning_rightr_arrow.svg') no-repeat center / cover;
                }
            }
        }
    }
}
.menuLinks {
    display: flex;
    flex-direction: column;
    border: 1px solid #E9E7EC;
    border-radius: 5px;
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &:nth-child(1) {
            border-bottom: 1px solid #E9E7EC;
        }
        button {
            width: 100% !important;
            display: flex;
            flex-direction: row !important;
            justify-content: space-between !important;
            align-items: center;
            height: 52px !important;
            padding: 20px 10px 20px 20px;
            &:nth-child(1) {
                border-right: 1px solid #E9E7EC;
            }
            strong {
                display: inline-block;
                font-weight: 400;
                font-size: 14px;
                &.coupon {
                    position: relative;
                    &.no_auth {
                        &::after {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            top: 1px;
                            width: 4px;
                            height: 4px;
                            border-radius: 4px;
                            background: #FF4B84;
                        }
                    }
                }
            }
            .move_to_arraow_icon {
                display: flex;
                justify-content: center;
                align-items: center;
                weight: 600;
                font-size: 14px;
                color: #FF4B84;
                .couponCnt {
                    weight: 600;
                    font-size: 14px;
                    margin-right: 6px;
                    color: #9F9CA3;
                    &.pink {
                        color: #FF4B84;
                    }
                }
                &.no_auth {
                    color: #9F9CA3;
                }
                .arrow_icon {
                    width: 12px;
                    height: 12px;
                    background: url('/assets/icons/my_knewnew_arrow_right.svg') no-repeat center / cover;
                }
            }
        }
    }
}
.coupon_banner {
    width: 100%;
    height: 65px;
    background-image: url('/assets/banner//coupon_banner.png');
    background-size: cover;
    background-position: center center;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}
.coupon_identified_banner {
    width: 100%;
    height: 65px;
    background-size: 332px 65px;
    background-position: left;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;

}
