.header_wrapper {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4 !important;
  padding-top: env(safe-area-inset-top);
  background: transparent;
  transition: all 0.5s;

  &.android {
    padding-top: var(--status-bar-height);
  }
  &.bg_black {
    background: #000;
    color: #fff;
  }

  &.bg_white {
    background: #fff;
    color: #000;
    border-bottom: solid 1px #e9e7ec;
  }

  &.bg_transparent {
    position: fixed;
    left: auto;
    right: auto;
    background: transparent;
    border: none;
    width: 375px;

    @media screen and (max-width: 600px) {
      width: 100% !important;
    }
  }

  &.scrolled {
    animation: fadeIn 0.5s;
    background: rgba(247, 247, 252, 0.7);
    backdrop-filter: blur(20px);

    .app_install {
      display: none;
    }

    .ticketBtn {
      background-image: url('/assets/icons/ticket-icon-bk.svg');

      &.new {
        background-image: url('/assets/icons/ticket-icon-new-bk.svg');
      }
    }
    .logo {
      background-image: url('/assets/banner//home-black-logo.svg');
    }
  }
}

.app_install {
  // position: fixed;
  // left: auto;
  // right: auto;
  display: flex;
  padding: 10px 20px;
  background-color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: #fff;
  text-align: left;
  .lft {
    display: flex;
    .appIcon {
      width: 24px;
      height: 24px;
      border-radius: 8px;
      background: #433e48;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      .appLogo {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url('/assets/icons/appIcon.svg') no-repeat center / cover;
      }
    }
  }
}

.install_btn {
  margin-left: auto;
  padding: 5px 12px;
  border-radius: 16px;
  background-color: #ff4b84;
  font-size: 12px;
  line-height: 14px;
}

.header {
  justify-content: space-between;
  display: flex;

  .mainBack {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .mainBackIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('/assets/icons/main_header_back_icon.svg') no-repeat
        center / cover;
    }
  }
}
.ticketBtn {
  display: inline-block;
  padding: 3px 0;
  width: 20px;
  height: 20px;
  background: url('/assets/icons/ticket-icon.svg') no-repeat center;

  &.new {
    background-image: url('/assets/icons/ticket-icon-new.svg');
  }
}
.logo {
  width: 106px;
  height: 20px;
  margin: 18px 20px;
  background-image: url('/assets/banner//home-white-logo.svg');
}
.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  letter-spacing: -0.02em;
  /* white */
  // color: #FFFFFF;
  padding: 16px 0 11px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerText {
  font-weight: 700;
  font-size: 15px;
  line-height: 17.9px;
  letter-spacing: -0.04em;
}

.headerDropdown {
  width: 121px;
  height: 28px;
  margin: 16px 20px 16px 0;
  padding: 5px 0;
  color: #fff;
  justify-content: center;
  background: url('/assets/icons/arrow-down.svg') no-repeat center right;
  cursor: pointer;
}

.headerUser {
  width: 121px;
  height: 28px;
  margin: 16px 20px 16px 0;
  padding: 5px 0;
  color: #fff;
  justify-content: center;
  background: url('/assets/icons/user.svg') no-repeat center right;
  cursor: pointer;
}

.brandIcon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url('/assets/icons/brand_home_scrap_icon.svg') no-repeat center /
    contain;
}

.headerDropdownList {
  position: absolute;
  width: 141px;
  top: 50px;
  color: #fff;
  display: block;
  padding: 0 15px 5px 28px;
  background: #000;
  li {
    margin-bottom: 15px;
    cursor: pointer;
    text-align: left;
  }
  li:nth-child(1) {
    border-top: 1px solid #fff;
    padding-top: 15px;
  }
  li.active {
    color: #ff4b84;
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 14px;
  color: #78737d;
  .icon {
    position: relative;
    width: 15px;
    height: 18px;
    &.unread::after {
      position: absolute;
      right: -2px;
      top: 0;
      display: block;
      content: '';
      width: 3px;
      height: 3px;
      background: #ff4b84;
      border-radius: 3px;
    }
  }
  cursor: pointer;
}
.options {
  display: flex;
  cursor: pointer;
  .icon {
    display: inline-block;
    width: 18px;
    height: 18px;
  }
  justify-content: center;
  align-items: center;
}
.option_wrapper {
  display: flex;
  margin-right: 21px;
}

.qr_popup {
  background-color: #fff;
  white-space: pre;
  padding: 40px 16px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
  color: #433e48;
  border-radius: 10px;

  img {
    margin: 20px auto 8px auto;
    width: 80px;
    height: 80px;
  }

  .desc {
    display: block;
    font-size: 12px;
    line-height: 16px;
  }

  .close_btn {
    display: block;
    width: 283px;
    margin-top: 20px;
    border: 1px solid #e9e7ec;
    border-radius: 5px;
    font-size: 16px;
    line-height: 50px;
  }
}

.dimmed_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5;
}
