
.shareBtn {
    min-width: 42px;
    height: 50px;
    border: 1px solid #E9E7EC;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    display: inline-block;
    background: url('/assets/icons/share.svg') no-repeat center;
    margin-right: 5px;
    cursor: pointer;
}

.share_items_left_panel {
    position: absolute;
    left: 20px;
    bottom: 86px;
    background: #fff;
    z-index: 10;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.06);
    width: 172px;
    height: 96px;
    padding: 16px;
    border-radius: 5px;
    .share_item {
        display: flex;
        justify-content:flex-start;
        align-items: center;
        cursor: pointer;
        .kakao_icon {
            background: url('/assets/icons/kakao-share-icon.svg') no-repeat center;
            width: 22px;
            height: 20px;
            display: inline-block;
        }
        .link_copy_icon {
            background: url('/assets/icons/link-copy-icon.svg') no-repeat center;
            width: 22px;
            height: 20px;
            display: inline-block;
        }
        .txt {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            padding-left: 22px;
        }
        &:nth-child(1) {
            padding-bottom: 10px;
            border-bottom: 1px solid #E9E7EC;
        }
        &:nth-child(2) {
            padding-top: 10px;
        }
    }
}
.funcBtn {
    width: 100%;
    height: 50px;
    background: #000;
    border-radius: 5px;
    color: #fff;
    &:disabled {
        background: #E9E7EC;
    }
}

.dimmed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: rgba(255,255,255, 0.8);
    .txt {
        margin-top: 240px;
        color: #000;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        letter-spacing: -0.32px;
    }
    .ringBtn {
        height: 36px;
        padding: 0px 12px;
        background: #000;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .ring {
            display: inline-block;
            background: url('/assets/icons/brand_open_white_ring_icon.svg') no-repeat center / cover;
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }
    }
}