
.card {
    aspect-ratio: 194 / 224;
    width: 50%;
    background: #fff;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    .txt {
        display: block;
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        padding: 18px 0 10px 10px;
        text-align: left;
        background: linear-gradient(0, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    }
}
