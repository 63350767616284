.community_wrapper {
    padding: 60px 0 80px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-top: 20px;
}
.detail {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-top: 10px;
}
.number_wrapper {
    .number {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 30px;
        border-radius: 30px;
        background: #000;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;
    }
}
.img {
    height: 400px;
    width: 100%;
    background: url('/assets/banner//about_community.jpeg') no-repeat center / cover;
    margin-top: 20px;
}