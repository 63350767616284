
/* badge style */
.badge-wrapper {
  width: 63px;
  height: 22px;
  border-radius: 3px;
  background: linear-gradient(90deg, #FF3E48 0%, #FF435F 44%, #FF4B84 100%);
  align-items: center;
  justify-content: space-between;
}
.recommend-main-wrapper .badge-wrapper.pick {
  margin: 40px 0 0 30px;
}
.recommend-main-wrapper .badge-wrapper.best {
  margin: 0 0 0 20px;
}
.badge-wrapper .badge-icon {
  margin: 6px 0 6px 8px;
  width: 10px;
  height: 10px;
}
.badge-wrapper .badge-icon.pick {
  background: url('../../assets/icons/star.svg') no-repeat center;
}
.badge-wrapper .badge-icon.best {
  background: url('../../assets/icons/tropy.svg') no-repeat center;
}
.badge-wrapper .badge-text {
  font-family: 'Y_Spotlight';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-align: left;
  margin-right: 8px;
  color: #fff;
}
