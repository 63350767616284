.cardLists {
  min-height: 100vh;
}
.banner {
  position: relative;
  height: 200px;
  color: #fff;
  video {
    object-fit: initial;
    min-width: 100%;
    height: 200px;
  }
}
.newzip_video {
  filter: brightness(0.6);
}
.newbrand_video {
  filter: brightness(0.8);
}
.newnews_video {
  filter: brightness(0.5);
}
.titles {
  position: absolute;
  height: 200px;
  width: 100%;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background: linear-gradient(0, #000000 0%, rgba(0, 0, 0, 0) 100%);
  img {
    height: 22px;
    margin-bottom: 30px;
  }
}
.subTitle {
  font-weight: 200;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
}
.tabs {
  padding: 20px;
  display: flex;
  align-items: flex-start;
  overflow: auto;
  border-bottom: 1px solid #e9e7ec;
}
.tabs::-webkit-scrollbar {
  height: 5px;
}
.tabs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  border-radius: 20px;
  background-color: #fff;
}
.tabs::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #e9e7ec;
  border-radius: 20px;
}
.tabs::-webkit-scrollbar-thumb:hover {
  background-color: #e9e7ec;
}

.fixed {
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 3;
  margin: 0;
  padding: 20px;
  width: 100%;
  padding-top: calc(20px + env(safe-area-inset-top));
  &.android {
    padding-top: calc(20px + var(--status-bar-height)) !important;
  }
}
.tab {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  border-radius: 30px;
  border: 1px solid #e9e7ec;
  margin-right: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
}
.active {
  background: #000;
  color: #fff;
}
.listContents {
  padding-top: 20px;
}
.listContentsZip {
  padding-top: 30px;
}

@media screen and (max-width: 600px) {
  .tabs::-webkit-scrollbar {
    display: none;
  }
}
