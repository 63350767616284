
.brandCard {
    margin: 0 20px;
    cursor: pointer;
}
.brandType {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.type {
    margin: 20px 10px 10px 0;
    color: #000;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
}
.writer {
    margin: 20px 0 10px 0;
    padding-left: 10px;
    border-left: 1px solid #E9E7EC;
    color: #78737D;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
}
.title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    margin-top: 20px;
}
.description {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    margin-top: 8px;
    margin-bottom: 60px;
    text-align: left;
    color: #78737D;
}