.menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 56px;
    cursor: pointer;
    .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
    }
    .right {
        color: #78737D;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        letter-spacing: -0.24px;
    }
}
.menuArea {
    padding: 20px 0;
}