.brandBanner {
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;

  .image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.height {
      width: auto;
      height: 100%;
    }
  }
}

.detailRecommendArea {
  padding: 20px 0 0 0;
  border-top: 10px solid rgb(247, 247, 252);
}

.tab {
  z-index: 100;
  position: sticky;
  top: calc(55px + env(safe-area-inset-top));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000;
  background-color: #fff;

  .tabItem {
    flex: 1;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-gray-4, #9f9ca3);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    cursor: pointer;

    &.active {
      color: #fff;
      background-color: #000;
    }
  }
}

:global(.android) {
  .tab {
    top: calc(55px + var(--status-bar-height));
  }
}
.tabContent {
  width: 100%;
  min-height: 500px;
}
.brandMap {
  .brandInfoTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 16px 20px;
    strong {
      color: var(--black, var(--color-black, #000));
      font-size: 18px;
      font-weight: 700;
      line-height: 100%; /* 18px */
      letter-spacing: -0.36px;
    }
    span {
      color: var(--color-black, #000);
      font-size: 14px;
      font-weight: 400;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &::after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url('/assets/icons/collection_brand_info_right_arrow.png')
          no-repeat center / cover;
      }
    }
  }
}
.mapArea {
  height: 220px;
}
.divider {
  height: 10px;
  background: var(--color-gray-1, #f7f7fc);
  width: 100%;
}
.brandInfoAddress {
  padding: 20px 0;
  margin: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .addressTxt {
    color: var(--color-black, #000);
    font-size: 14px;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.28px;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      display: inline-block;
      background: url('/assets/icons/location_icon.svg') no-repeat center /
        contain;
    }
  }
  .copy {
    color: var(--color-gray-5, #78737d);
    font-size: 14px;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.28px;
    text-decoration-line: underline;
    margin-left: 6px;
    cursor: pointer;
  }
}
.brandInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  flex: none;
  .img {
    flex: none;
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;

    .brandTitle {
      color: var(--b, var(--color-black, #000));
      font-size: 16px;
      font-weight: 700;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
      display: flex;
      align-items: center;
      &.hasArrow {
        &::after {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url('/assets/icons/collection_brand_title_arrow_right.svg')
            no-repeat center / contain;
        }
      }
    }
    .brandDescription {
      color: var(--color-gray-5, #78737d);
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
      letter-spacing: -0.28px;
      margin-top: 8px;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &:before {
    top: 0;
    left: 20px;
    right: 20px;
    position: absolute;
    display: block;
    height: 1px;
    background-color: #e9e7ec;
    content: '';
  }
}

.titleWrapper {
  padding: 0 20px;

  h3 {
    margin: 0 !important;
  }
}

.recommendImageArea {
  display: flex;
  padding: 0 20px;
  margin-top: 16px;

  .brandItem {
    flex: 1;
    cursor: pointer;

    img {
      overflow: hidden;
      border-radius: 4px;
      width: 100%;
      height: 120px;
      object-fit: cover;
    }

    & + .brandItem {
      margin-left: 8px;
    }
  }

  .itemTitle {
    margin-top: 10px;
    color: #433e48;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.24px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
.bestCollectionDivider {
  height: 1px;
  width: 100%;
  background: #f7f7fc;
}

.btn_wrapper {
  margin: 24px 20px 40px;
  .btn {
    display: block;
    width: 100%;
    padding: 12px 0;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: -0.28px;
    border: 1px solid #d2d0d5;
  }
}
