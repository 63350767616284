.wrapper {
    display: flex;
    position: absolute;
    justify-content: center;
    width: 100%;
    padding-bottom:constant(safe-area-inset-bottom);
    padding-bottom:env(safe-area-inset-bottom);
    padding-left: 20px;
    padding-right: 20px;
    bottom: 0;
    left: 0;
    background: #fff;
    border-top: 1px solid #E9E7EC;
}