.footerWrapper {
  background: #f7f7fc;
}
.footerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  a {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
    color: var(--5, #78737d);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.24px;
    border-right: 1px solid #e9e7ec;
    &:nth-last-child(1) {
      border-right: 0;
    }
  }
}
.txt {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  cursor: pointer;
}
.logoWrapper {
  padding: 20px 20px 40px;
  display: flex;
  flex-direction: column;
}
.footerLogo {
  background: url('/assets/imgs/knewnew_black_logo_new.svg');
  background-size: cover;
  width: 106px;
  height: 18px;
  margin-bottom: 10px;
}
.footerTxt {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #433e48;
  cursor: pointer;
  text-align: left;
}
.footerInfoTxt {
  margin-top: 12px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #9f9ca3;
  text-align: left;

  .termOfUse {
    color: var(--5, #78737d);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    margin-top: 4px;
  }
  p {
    margin: 8px 0;
  }
  span {
    display: block;
  }
}
.aboutFooterContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 80px;
}
.description {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #78737d;
}
.askSomething {
  width: 165px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 30px;
  /* padding: 8px 56px; */
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 4px;

  &.up {
    transform: rotate(180deg);
  }
}
