.popularArea {
    > div {
        display: flex;
        align-items: center;
        padding: 20px 0 20px 20px;
        overflow: auto;
        background: #fff;
        &::-webkit-scrollbar {
            display: none;
        }
        cursor: pointer;
        &.active {
            cursor: grabbing;
            cursor: -webkit-grabbing;
            transform: scale(1);
        }
    }
}
.brandList {
    background: #fff;
    padding: 30px 0;
}
.contents {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}