.checkIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  button {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 5px;
    background: var(--B, #000);
    color: var(--W, #fff);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    width: 165px;
    height: 50px;
    &.toHome {
      border-radius: 5px;
      border: 1px solid var(--G4, #9f9ca3);
      background: var(--color-white, #fff);
      margin-right: 5px;
      color: #000;
    }
  }
}
.txtWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.txt {
  color: var(--color-black, #000);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
  margin-top: 16px;
}
.imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.itemInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  .tit {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f7f7fc;
    width: 100%;
    text-align: left;
  }
  .itemCnts {
    padding: 15px 0;
    border-bottom: 1px solid #f7f7fc;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .label {
      color: var(--4, var(--color-gray-4, #9f9ca3));
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: -0.28px;
      width: 100px;
      text-align: left;
    }
  }
}
.useInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
  padding: 20px;
  .tit {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f7f7fc;
    width: 100%;
    text-align: left;
  }
  .itemCnts {
    padding: 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .label {
      color: var(--4, var(--color-gray-4, #9f9ca3));
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: -0.28px;
      width: 100px;
      text-align: left;
    }
    &.withLines {
      border-bottom: 1px solid #f7f7fc;
    }
  }
  .itemCntsWrapper {
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #f7f7fc;
    .detailWrapper {
      margin-top: 9px;
      list-style: disc;
      padding-left: 20px;
      li {
        &::marker {
          color: #9f9ca3 !important;
        }
        text-align: left;
        .smLabel {
          display: inline-block;
          color: var(--4, var(--color-gray-4, #9f9ca3));
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          letter-spacing: -0.28px;
          width: 100px;
          text-align: left;
        }
        .smVal {
          color: var(--4, var(--color-gray-4, #9f9ca3));
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          letter-spacing: -0.28px;
        }
      }
    }
  }
}
.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  &.normal {
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
  }
  &.ex {
    margin-top: 40px;
  }
}
.closeBtn {
  border-radius: 5px;
  border: 1px solid var(--G4, #9f9ca3);
  background: #fff;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  width: 100%;
}
