.container {
  padding: 0 0 20px 0;
  height: calc(100vh - 142px);
}
.searchArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px 7px 20px;

  position: sticky;
  top: 0;
  z-index: 10;
  justify-content: center;
  align-items: center;
  padding-top: calc(7px + env(safe-area-inset-top));
  height: calc(56px + env(safe-area-inset-top));
  background: #fff;
  &.android {
    padding-top: calc(7px + var(--status-bar-height));
    height: calc(56px + var(--status-bar-height));
  }
  .inputWrapper {
    flex: 1;
    margin-left: 10px;
    position: relative;
    input {
      padding: 13px 16px;
      border-radius: 8px;
      background: var(--G1, #f7f7fc);
      width: 100%;
      ::placeholder {
        color: var(--gray4, var(--color-gray-4, #9f9ca3));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
      }
    }
    .subBtns {
      position: absolute;
      right: 16px;
      top: 17px;
    }
  }
}
.popularBrands {
  padding: 20px 20px;
  .tit {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    text-align: left;
  }
  .popBrandsContainer {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .brandInfo {
    border-radius: 30px;
    border: 1px solid var(--color-gray-2, #e9e7ec);
    background: var(--color-white, #fff);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .brandName {
      color: var(--G6, #433e48);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 12px */
      letter-spacing: -0.24px;
    }
    .brandCategory {
      color: var(--G5, #78737d);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.24px;
    }
  }
}

.popularArea {
  padding-top: 30px;
  .popularAreaTitle {
    color: var(--G6, #433e48);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.32px;
    text-align: left;
    margin-bottom: 16px;
    padding-left: 20px;
  }
  .popularAreaList {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .popularItem {
    margin-right: 12px;
    cursor: pointer;
    .txt {
      margin-top: 10px;
      color: var(--color-gray-6, #433e48);
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 13px */
      letter-spacing: -0.26px;
    }
  }
}
.areaImg {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  &.sengsu {
    background: url('../../assets/imgs/areas/seongsu.jpg') no-repeat center /
      cover;
  }
  &.yongsan {
    background: url('../../assets/imgs/areas/yeongsan.jpg') no-repeat center /
      cover;
  }
  &.apgujeong {
    background: url('../../assets/imgs/areas/apgujung.jpg') no-repeat center /
      cover;
  }
  &.eljiro {
    background: url('../../assets/imgs/areas/eljiro.jpg') no-repeat center /
      cover;
  }
  &.jongro {
    background: url('../../assets/imgs/areas/jongro.jpg') no-repeat center /
      cover;
  }
  &.mangwon {
    background: url('../../assets/imgs/areas/mangwon.jpg') no-repeat center /
      cover;
  }
  &.samgakji {
    background: url('../../assets/imgs/areas/samgakji.jpg') no-repeat center /
      cover;
  }
  &.jamsil {
    background: url('../../assets/imgs/areas/jamsil.jpg') no-repeat center /
      cover;
  }
  &.munre {
    background: url('../../assets/imgs/areas/munre.jpg') no-repeat center /
      cover;
  }
}

.searchItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f7f7fc;
  padding-bottom: 12px;
  padding-top: 12px;
  .leftArea {
    display: flex;
    .itemImg {
      width: 60px;
      height: 60px;
      background: #e2e2e2;
    }
    .itemTxtArea {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 12px;
      .itemTit {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: var(--G5, #78737d);
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.32px;
        margin-bottom: 8px;
        .highlight {
          color: var(--P, #ff4b84);
        }
      }
      .itemRegion {
        color: var(--G5, #78737d);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 12px */
        letter-spacing: -0.24px;
      }
    }
  }
  .rightArea {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.no_result_img {
  margin-top: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no_result_tit {
  color: var(--color-black, #000);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  margin-top: 10px;
  text-align: center;
}
.search_notice {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 20px 0;
  list-style: disc;
  padding: 0 40px;
  li {
    text-align: left;
    color: var(--color-gray-5, #78737d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.68px;
  }
}
.recommend_brands {
  button {
    border-radius: 5px;
    border: 1px solid var(--color-gray-2, #e9e7ec);
    background: var(--color-white, #fff);
    padding: 13px;
    color: var(--6, var(--color-gray-6, #433e48));
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
  }
}
.recommend_desc {
  color: var(--color-gray-5, #78737d);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
  margin-top: 16px;
  margin-bottom: 40px;
}
.divider {
  height: 1px;
  background: #e9e7ec;
  margin: 0 20px;
}
.brandInput {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
  h3 {
    color: var(--black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
    margin-bottom: 10px;
  }
  input {
    border-radius: 5px;
    border: 1px solid var(--color-gray-2, #e9e7ec);
    background: var(--white, #fff);
    padding: 16px 20px;
    width: 100%;
    &::placeholder {
      color: var(--G3, #d2d0d5);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
    }
    &:focus {
      border-radius: 5px;
      border: 1px solid var(--color-gray-2, #e9e7ec);
    }
  }
}
.footer {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 13px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    flex: 1;
    padding: 17px;
    &.cancelBtn {
      border-radius: 5px;
      border: 1px solid var(--color-black, #000);
      background: var(--color-white, #fff);
      color: var(--black, var(--color-black, #000));
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
    }
    &.apply {
      border-radius: 5px;
      background: var(--color-black, #000);
      color: var(--white, var(--color-white, #fff));
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
      margin-left: 5px;
      &:disabled {
        background: var(--color-gray-2, #e9e7ec);
        color: var(--white, var(--color-white, #fff));
      }
    }
  }
}
.searchBar {
  &::placeholder {
    color: var(--gray4, var(--color-gray-4, #9F9CA3));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
  }
}