.wrapper {
    min-height: 100vh;
}
.count {
    margin: 0 0 20px 0;
    padding: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    text-align: left;
    .cnt {
        color: #9F9CA3;
    }
}
.list {
    padding: 20px 20px 0 20px;
}
.no_cnt {
    height: calc(100vh - 134px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #78737D;
}