.filter-group {
    display: flex;
    justify-content: flex-start;
}
.shop-filter {
    height: 24px;
    padding: 5px 10px;
    border-radius: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    margin-right: 10px;
}
.shop-filter.pre {
    border: 1px solid #000;
    background: #fff;
    color: #000;
}
.shop-filter.setup {
    border: 1px solid #000;
    background: #000;
    color: #fff;
}
.shop-filter span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.shop-filter .icon {
    width: 10px;
    height: 100%;
    margin-left: 6px;
}
