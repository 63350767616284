.feed-list-wrapper {
    background: #fff;
}
.feed-header-wrapper {
    display: flex;
    padding: 0 20px;
    margin: 40px 0 20px 0;
    height: 30px;
    width: 100%;
    justify-content: space-between;
}
.feed-header-wrapper .profile-info {
    display: flex;
    justify-content: flex-start;
}
.feed-header-wrapper .profile-info .feed-user-img {
    margin-right: 5px;
}
.feed-header-wrapper .profile-info .profile-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    color: #000000;
}
.feed-header-wrapper .share-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.feed-header-wrapper .share-info .share-icon {
    display: inline-block;
    width: 16px;
    height: 18px;
    background: url('../../assets/icons/share.svg') no-repeat center;
    margin-right: 19px;
}
.feed-header-wrapper .share-info .bookmark-icon {
    display: inline-block;
    width: 15px;
    height: 18px;
    background: url('../../assets/icons/bookmark.svg') no-repeat center;
}
.feed-wrapper .hashtag-wrapper {
    display: flex;
    padding: 0 20px;
    margin-bottom: 15px;
}
.hash-tag {
    padding: 4px 8px;
    height: 22px;
    /* gray1 */
    background: #F7F7FC;
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    margin-right: 5px;
}
.feed-wrapper .feed-items {
    margin: 0 !important;
    padding-left: 20px !important;
}
.feed-wrapper .feed-items .item-wrapper {
    margin-right: 15px !important;
}
.is-best-wrapper {
    display: flex;
    padding: 20px 20px 0 20px;
    margin-bottom: 11px;
}
.is-best-wrapper .is-best-icon {
    display: inline-block;
    width: 15px;
    height: 14.22px;
    background: url('../../assets/icons/ionic-ios-heart.svg') no-repeat center;
    margin-right: 5px;
}
.is-best-wrapper .txt {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    color: #FF4B84;
}
.buy-info {
    height: 57px;
    background: #FFFFFF;
    /* gray2 */
    border: 1px solid #E9E7EC;
    border-radius: 5px;
    margin: 0 20px 0 20px;
    padding: 13px 10px;
}
.buy-info div {
    text-align: left;
}
.buy-info div.shop-name {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.02em;
    /* gray4 */
    color: #9F9CA3;
    margin-bottom: 5px;
}
.buy-info div.product-info {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    /* black */
    color: #000000;
}
.buy-info .right-direction-icon {
    display: inline-block;
    width: 5px;
    height: 10px;
    background: url('../../assets/icons/arrow-right2.svg') no-repeat center;
    margin-left: 8.5px;
}
.move-shop-btn {
    margin: 10px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.move-shop-btn {
    width: 335px;
    height: 30px;
    border-radius: 5px;
    background: #FFFFFF;
    /* black */
    border: 1px solid #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    text-align: center;
    letter-spacing: -0.02em;
    /* black */
    color: #000000;
}
.move-shop-btn span::after {
    content: ' ';
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url('../../assets/icons/left-arrow.svg') no-repeat center;
    background-size: 100% 100%;
    margin: 0 0 0 6px;
    transform:rotate(-45deg);
}
.feed-texts-wrapper {
    padding: 0 20px;
}
.feed-texts-wrapper .text-content {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    /* or 19px */
    letter-spacing: -0.02em;
    /* gray6 */
    color: #000;
    text-align: left;
    position: relative;
}
.feed-texts-wrapper .blur-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}
.feed-texts-wrapper .short-contents p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
}
.feed-texts-wrapper .contents-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}
.feed-texts-wrapper .contents-buttons .expand-contents-btn {
    width: 78px;
    height: 28px;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    margin-top: 33px;
    /* identical to box height, or 12px */
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    /* black */
    color: #000000;
    padding: 8px 10px;
    background: #F7F7FC;
    border-radius: 2px;
}
.feed-texts-wrapper .contents-buttons .expand-contents-btn .arrow-icon.down {
    width: 10px;
    height: 5px;
    display: inline-block;
    background: url('../../assets/icons/arrow-down-black.svg') no-repeat center;
    margin-left: 6px;
}
.feed-texts-wrapper .contents-buttons .expand-contents-btn .arrow-icon.up {
    width: 10px;
    height: 5px;
    display: inline-block;
    background: url('../../assets/icons/arrow-up.svg') no-repeat center;
    margin-left: 6px;
}

.feed-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 31px;
}
.feed-footer .report-warning {
    display: flex;
}
.feed-footer .report-warning .icon-ciren {
    display: inline-block;
    width: 16.67px;
    height: 16.67px;
    background: url('../../assets/icons/ciren.svg') no-repeat center;
    margin-right: 6.67px;
}
.feed-footer .report-warning .report-text {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    padding-top: 3px;
    /* black */
    color: #000000;
}
.feed-footer .feed-comments-wrapper {
    display: flex;
}
.feed-footer .feed-comments-wrapper .like {
    margin-right: 28px;
    display: flex;
}
.feed-footer .feed-comments-wrapper .like .icon-like {
    display: inline-block;
    width: 18px;
    height: 17px;
    background: url('../../assets/icons/like-btn.svg') no-repeat center;
    margin-right: 6px;
}
.feed-footer .feed-comments-wrapper .like .like-cnt {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    align-items: center;
    letter-spacing: -0.02em;
    padding-top: 1px;
    /* black */
    color: #000000;
}
.feed-footer .feed-comments-wrapper .comment {
    display: flex;
}
.feed-footer .feed-comments-wrapper .comment .icon-comment {
    display: inline-block;
    width: 18px;
    height: 17px;
    background: url('../../assets/icons/comment-btn.svg') no-repeat center;
    margin-right: 6px;
}
.feed-footer .feed-comments-wrapper .comment .comment-cnt {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    align-items: center;
    letter-spacing: -0.02em;
    padding-top: 1px;
    /* black */
    color: #000000;
}

.feed-wrapper .feed-divider {
    width:  100%;
    height: 10px;
    background: #F7F7FC;
    border-top: 1px solid #EAEAF4;

}