.banner {
  position: relative;
  height: 100px;
  margin-bottom: 30px;
  cursor: pointer;
  .bannerIndicator {
    display: inline-flex;
    // padding: 4px 8px;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    width: 40px;
    height: 20px;
    position: absolute;
    bottom: -6px;
    right: 10px;
    color: #fff;
    font-size: 12px;
  }
}
.filterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 16px 0 20px 0;
  .cnt {
    color: var(--5, #78737d);
    font-size: 12px;
    font-weight: 400;
    line-height: 100%; /* 12px */
    letter-spacing: -0.24px;
  }
  .filter {
    background-color: #f7f7fc;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
    .filterIcon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url('/assets/icons/barnd_filter_icon.svg') no-repeat center /
        cover;
    }
    .filterTxt {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 12px */
    }
  }
}
.contents {
  animation: fadeIn 1s;
}
.keywordSelectorWrapper {
  position: sticky;
  bottom: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .keywordSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    .keyword {
      width: 76px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f7f7fc;
      &.keyword_left {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      &.keyword_right {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &.selected {
        background: #000;
        color: #fff;
      }
    }
  }
}
.benefits {
  // padding: 16px 20px;
  padding-left: 16px;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--P, #ff4b84);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
}

.custom_checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 8px;
}

.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  height: 24px;
  width: 24px;
  display: block;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  background: #fff;
  border: 1px solid #e9e7ec;
}

.custom_checkbox input:checked ~ .checkmark {
  background-color: #433e48;
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.custom_checkbox input:checked ~ .checkmark::after {
  display: block;
}

.custom_checkbox .checkmark::after {
  left: 7px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.currentPosWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  .loop {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../../assets/icons/loop.svg') no-repeat center / cover;
    cursor: pointer;
    margin-right: 4px;
  }
  .currentPosition {
    color: var(--color-pink, #ff4b84);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    letter-spacing: -0.36px;
    margin-right: 8px;
  }
}
.addr2 {
  color: var(--black, var(--color-black, #000));
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  letter-spacing: -0.36px;
}
.addr3 {
  color: var(--black, var(--color-black, #000));
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
  letter-spacing: -0.36px;
}
.othersAreaWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .mapIcon {
    width: 60px;
    height: 60px;
    background: url('../../../assets/icons/brand_map_icon.svg') no-repeat center /
      cover;
  }
  .othersArea {
    color: var(--6, var(--color-gray-6, #433e48));
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    border-radius: 5px;
    border: 1px solid var(--color-gray-2, #e9e7ec);
    background: var(--color-white, #fff);
    padding: 13px 19px;
    margin-top: 20px;
  }
}

.newpick {
  background: #ebf3fa;
  color: #124778;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -2%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100%;
  span {
    margin: 0 6px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
