.wrapper {
    background: #000;
    padding: 40px 0 63px 0;
}
.title_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    margin-bottom: 40px;
    & > strong {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 4px;
    }
    & > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02em;
    }
}
.scroll {
    display: flex;
    overflow: auto;
    margin-bottom: 40px;
    padding-left: 20px;
}
.scroll::-webkit-scrollbar {
    height: 5px;
}
.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    border-radius: 20px;
	background-color: #000;
}

.scroll::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #333;
    border-radius: 20px;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background-color: #333;
}
.curatorCard {
    min-width: 150px;
    height: 200px;
    background: linear-gradient(180deg, #504E4F 0%, #18171A 100%);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 16.8px;
    flex-direction: column;
    margin-right: 10px;
}
.curatorImg {
    width: 90px;
    height: 90px;
    border-radius: 90px;
    background: url('/assets/banner//curator-dummy-img.png');
    margin-top: 15px;
}
.nickName {
    margin-top: 20px;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
}
.hashtags {
    margin-top: 10px;
    padding: 0 10px;
    color: #9F9CA3;
}
.tag {
    margin-right: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
}
.btn_wrapper {
    display: flex;
    justify-content: center;
    & > button {
        width: 165px;
        height: 30px;
        border: 1px solid #fff;
        border-radius: 30px;
        color: #fff;
    }
}

@media screen and (max-width: 600px) {
    .scroll::-webkit-scrollbar {
        display: none;
    }
}
