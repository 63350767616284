@font-face {
  font-family: 'SBAggroB';
  src: url('SB_AggroOTF_B.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SBAggroM';
  src: url('SB_AggroOTF_M.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SB AggroOTF';
  src: url('SB_AggroOTF_M.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SBAggroL';
  src: url('SB_AggroOTF_L.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}
