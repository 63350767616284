/* 읽지않은 콘텐츠 <!-- start --> */
.new-content {
  background: #fff;
  padding-top: 40px;
}
.new-content .dot {
  width: 6px;
  height: 6px;
  background: #FF4B84;
  margin: 0 0 0 20px;
  border-radius: 10px;
}
.new-content .title {
  font-family: 'SBAggroB';
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 6px 0 20px 20px;
}
.new-content .title-line {
  margin-left: 20px;
  height: 1px;
  background-color: #E9E7EC;
}
.new-content .sub-title {
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  letter-spacing: -0.02em;
}
.new-content .description {
  margin-top: 10px;
  margin-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */
  letter-spacing: -0.02em;
  /* gray6 */
  color: #433E48;
  text-align: left;
}
.new-content .show-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90px;
    height: 170px;
    flex: 0 0 auto;
    flex-grow: 0;
}
.new-content .show-all .show-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 90px;
    border-radius: 150px;
    background-color: #F7F7FC;
    text-align: center;
}
.new-content .show-all .show-content .arrow {
    width: 100%;
    height: 20px;
    background: url('../../assets/imgs/coupon_img.png') no-repeat center;
}

.new-content .show-all .show-content .text {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.02em;
    margin-top: 10px;
    margin-right: 10px;
    color: #433E48;
}