.footer {
  position: sticky;
  position: -webkit-sticky;
  /* position: fixed; */
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  height: 76px;
  z-index: 3;
  background: #fff;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
  border-top: 1px solid #e9e7ec;
}
.popup_footer {
  margin-top: auto;
  position: sticky;
  position: -webkit-sticky;
  /* position: fixed; */
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100; // 컬렉션 tab 대응하기 위해 수정, tab은 지도 대응하기 위해 z-index: 100
  background: #fff;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 13px 20px max(13px, env(safe-area-inset-bottom));
  border-top: 1px solid #e9e7ec;
}
.buyButton {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;

  /* white */

  color: #ffffff;
}
.share_btn {
  width: 18px;
  display: inline-block;
  background: url('/assets/icons/share.svg') no-repeat center;
  margin-right: 20px;
  cursor: pointer;
}
.bookmark {
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    width: 18px;
    display: inline-block;
    margin-right: 8.5px;
  }
  .cnt {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
  }
}
.share_items_panel {
  position: absolute;
  right: 20px;
  bottom: 86px;
  background: #fff;
  z-index: 10;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.06);
  width: 172px;
  height: 96px;
  padding: 16px;
  border-radius: 5px;
  .share_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .kakao_icon {
      background: url('/assets/icons/kakao-share-icon.svg') no-repeat center;
      width: 22px;
      height: 20px;
      display: inline-block;
    }
    .link_copy_icon {
      background: url('/assets/icons/link-copy-icon.svg') no-repeat center;
      width: 22px;
      height: 20px;
      display: inline-block;
    }
    .txt {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-left: 22px;
    }
    &:nth-child(1) {
      padding-bottom: 10px;
      border-bottom: 1px solid #e9e7ec;
    }
    &:nth-child(2) {
      padding-top: 10px;
    }
  }
}
.share_items_left_panel {
  position: absolute;
  left: 20px;
  bottom: 86px;
  background: #fff;
  z-index: 10;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.06);
  width: 172px;
  height: 96px;
  padding: 16px;
  border-radius: 5px;
  .share_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .kakao_icon {
      background: url('/assets/icons/kakao-share-icon.svg') no-repeat center;
      width: 22px;
      height: 20px;
      display: inline-block;
    }
    .link_copy_icon {
      background: url('/assets/icons/link-copy-icon.svg') no-repeat center;
      width: 22px;
      height: 20px;
      display: inline-block;
    }
    .txt {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-left: 22px;
    }
    &:nth-child(1) {
      padding-bottom: 10px;
      border-bottom: 1px solid #e9e7ec;
    }
    &:nth-child(2) {
      padding-top: 10px;
    }
  }
}

.extra_btn {
  min-width: 42px;
  height: 50px;
  border: 1px solid #e9e7ec;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  .share {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url('/assets/icons/share.svg') no-repeat center / cover;
    cursor: pointer;
  }
  .bookmark {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url('/assets/icons/brand_home_scrap_icon.svg') no-repeat center /
      contain;
    &.active {
      background: url('/assets/icons/brand_list_doscrap_icon.svg') no-repeat
        center / contain;
    }
  }
}
.unscrapIcon {
  background: url('/assets/icons/brand_home_scrap_icon.svg') no-repeat center /
    contain;
}
.doscrapIcon {
  background: url('/assets/icons/brand_list_doscrap_icon.svg') no-repeat center /
    contain;
}

.tooltipWrap {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltip {
  max-width: 300px;
  background: rgba($color: #ff4b84, $alpha: 0.8);
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.28px;
  display: inline-block;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: 0;
  animation: fadeIn 1s 3s forwards;

  &::after {
    content: '';
    display: block;
    opacity: 0.8;
    background: url('/assets/icons/brand_like_notice_triangle.svg') no-repeat
      center / cover;
    position: absolute;
    bottom: -7px;
    width: 8px;
    height: 8px;
    left: 50%;
    transform: translateX(-50%);
  }
}
