.bookmark-wrapper {
  min-height: 100vh;
}
.bookmark-wrapper .tabs {
  padding: 20px 0 20px 0;
  display: flex;
  overflow: auto;
}
.bookmark-wrapper .tabs.fixed {
  position: sticky;
  top: 60px;
  background: #fff;
  margin: 0;
  padding: 40px 0 0 0;
  overflow-x: scroll;
  z-index: 5;
}
.bookmark-wrapper .tabs::-webkit-scrollbar {
  display: none;
}
.bookmark-wrapper .tabs .tab {
  background: #ffffff;
  border: 1px solid #e9e7ec;
  border-radius: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  /* padding: 8px 14px; */
  min-width: 56px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}
.bookmark-wrapper .tabs .tab:nth-child(1) {
  margin-left: 20px;
}
.bookmark-wrapper .tabs .tab:nth-child(2) {
  min-width: 110px;
}
.bookmark-wrapper .tabs .tab:nth-child(3) {
  min-width: 90px;
}
.bookmark-wrapper .tabs .tab:nth-child(4) {
  min-width: 103px;
  margin-right: 20px;
}
.bookmark-wrapper .tabs .tab .cnt {
  color: #9f9ca3;
  margin-left: 10px;
}

.bookmark-wrapper .tabs .tab.active {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}
.bookmark-wrapper .no-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 160px;
  align-items: center;
  /* height: calc(100vh - 56px); */
}
.bookmark-wrapper .no-contents .txt {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
  color: #9f9ca3;
  white-space: pre;
}
.bookmark-wrapper .no-contents .go-to-contents {
  width: 165px;
  /* height: 30px; */
  border: 1px solid #000;
  border-radius: 30px;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 30px;
  cursor: pointer;
}

.bookmark-wrapper
  .contents
  .brand-card
  .bookmark-info-wrapper
  .bookmarked-icon-wrapper
  .bookmark-pink-filled,
.bookmark-wrapper
  .contents
  .brand-card
  .bookmark-info-wrapper
  .bookmarked-icon-wrapper
  .bookmark-pink {
  display: inline-block;
  width: 15px;
  height: 18px;
  cursor: pointer;
}

.bookmark-wrapper .contents .zip-card {
  position: relative;
  margin: 0 20px 60px 20px;
}
.bookmark-wrapper .contents .zip-card .contents-wrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  bottom: 15px;
  left: 15px;
  right: 15px;
}
.bookmark-wrapper .contents .zip-card .contents-wrapper .txt-wrapper {
  text-align: left;
  color: #fff;
}
.bookmark-wrapper
  .contents
  .zip-card
  .contents-wrapper
  .txt-wrapper
  .type-wrapper {
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  margin-bottom: 10px;
}
.bookmark-wrapper .contents .zip-card .contents-wrapper .txt-wrapper .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.bookmark-wrapper .contents .zip-card .contents-wrapper .txt-wrapper .tags {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 10px;
}
.bookmark-wrapper
  .contents
  .zip-card
  .contents-wrapper
  .txt-wrapper
  .tags
  .tag {
  margin-right: 10px;
}
.bookmark-wrapper
  .contents
  .zip-card
  .contents-wrapper
  .profile-wrapper
  .profile-img {
  width: 50px;
  height: 50px;
  background: url('../../assets/banner/zip-profile-dummy.png'), #fff;
  background-size: cover;
  border-radius: 50px;
}
.bookmark-wrapper
  .contents
  .zip-card
  .contents-wrapper
  .profile-wrapper
  .profile-name {
  margin-top: 5px;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  /* identical to box height, or 10px */
  text-align: center;
  letter-spacing: -0.02em;
  /* gray1 */
  color: #f7f7fc;
}
.bookmark-wrapper .contents .article {
  margin: 0 20px 60px 20px;
}
.bookmark-wrapper .contents .article .title {
  margin-bottom: 8px;
}

/* pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 29px 47px 30px;
}
.pagination .previews .pre-preview {
  background: url('../../assets/icons/pre-preview-left-deactive.svg') no-repeat
    center;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.pagination .previews .pre-preview.active {
  background: url('../../assets/icons/pre-preview-left-active.svg') no-repeat
    center;
}
.pagination .previews {
  display: flex;
  align-items: center;
}
.pagination .previews .preview {
  background: url('../../assets/icons/preview-left-deactive.svg') no-repeat
    center;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 9px;
}
.pagination .previews .preview.active {
  background: url('../../assets/icons/preview-left-active.svg') no-repeat center;
}
.pagination .nexts {
  display: flex;
  align-items: center;
}
.pagination .nexts .next {
  background: url('../../assets/icons/next-right-deactive.svg') no-repeat center;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 9px;
}
.pagination .nexts .next.active {
  background: url('../../assets/icons/next-right-active.svg') no-repeat center;
}
.pagination .nexts .next-next {
  background: url('../../assets/icons/next-next-right-deactive.svg') no-repeat
    center;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 5px;
}
.pagination .nexts .next-next.active {
  background: url('../../assets/icons/next-next-right-active.svg') no-repeat
    center;
}
.pagination .pages .page-item {
  width: 30px;
  height: 17px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  margin: 0 9px;
}
.pagination .pages .page-item.active {
  color: #ff4b84;
}
