/* foodlog header */
.food-log-wrapper {
    background: #fff;
}
.food-log-header {
    position: relative;
    width: 100%;
    height: 335px;
    background: url('../../assets/imgs/foodLogHeaderBg.svg') no-repeat center;
}
.food-log-header .navigator {
    position: relative;
    width: 100%;
    height: 60px;
}
.food-log-header .navigator .back {
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 16px;
  left: 26px;
  top: 26px;
  background: url('../../assets/icons/arrow-left-white.svg') no-repeat center;
}
.food-log-header .food-log-slider {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.food-log-header .food-log-slider .images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: auto;
}
.food-log-header .food-log-slider .images img {
    width: inherit;
}
.food-log-header .food-log-slider .image-navigator {
    margin-top: 317.13px;
    display: flex;
    justify-content: center;
    z-index: 2;
}
.food-log-header .food-log-slider .image-navigator .dot {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #fff;
}
.food-log-header .food-log-slider .image-navigator .dot.active {
    background-color: #FF4B84;
}
.food-log-header .food-log-slider .image-counter {
    position: absolute;
    bottom: 13.4px;
    right: 20px;
    width: 38px;
    height: 17.87px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 11px;
    padding-top: 2.57px;

    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* or 12px */

    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    z-index: 2;
}

/* foodlog profile */
.food-log-profile-wrapper {
    display: flex;
    width: 100%;
    padding: 16px 20px;
    justify-content: space-between;
}
.food-log-profile-wrapper .profile-info {
    display: flex;
    height: 40px;
}
.food-log-profile-wrapper .profile-info .names-follwers-cnt {
    margin-left: 10px;
}
.food-log-profile-wrapper .profile-info .names-follwers-cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.food-log-profile-wrapper .profile-info .names-follwers-cnt .name {
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    letter-spacing: -0.02em;
    /* black */
    color: #000000;
}
.food-log-profile-wrapper .profile-info .names-follwers-cnt .followers {
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.02em;
    /* gray4 */
    color: #9F9CA3;
}
.food-log-profile-wrapper .profile-info .names-follwers-cnt .followers .follow-cnt {
    color: #433E48;
    font-weight: 600;
    font-size: 12px;
    margin-left: 5px;
}
.food-log-profile-wrapper .follow-btn-wrapper .follow-btn {
    width: 95px;
    height: 30px;
    border: 1px solid #000;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
}
.food-log-profile-wrapper .follow-btn-wrapper .follow-btn .follow-contents {
    display: flex;
    justify-content: center;
    margin-top: 2px;
}
.food-log-profile-wrapper .follow-btn-wrapper .follow-btn .plus-icon {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-left: 5px;
    margin-top: -1px;
}
.food-log-wrapper .move-shop-btn {
    margin-bottom: 10px !important;
}
.food-log-wrapper .is-best-wrapper {
    margin-bottom: 16px !important;
}
.food-log-wrapper .blogs {
    padding: 16px 20px 30px 20px;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    letter-spacing: -0.02em;

    /* gray6 */

    color: #433E48;
}
.food-log-wrapper .hash-tag-wrapper {
    display: flex;
    padding: 0 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: no-wrap;
}
.food-log-wrapper .hash-tag-wrapper::-webkit-scrollbar {
    display: none;
}
.food-log-wrapper .hash-tag-wrapper .hash-tag {
  flex: 0 0 auto;
}
.food-log-wrapper .dates {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    letter-spacing: -0.02em;

    /* gray3 */

    color: #D2D0D5;
    text-align: left;
    padding: 0 20px;
}
.food-log-wrapper .divider {
    height: 1px;
    width: 100%;
    background-color: #E9E7EC;
}
.food-log-wrapper .food-log-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E9E7EC;
    padding: 15px 20px;
}
.food-log-wrapper .food-log-footer .like-share-comment {
    display: flex;
    justify-content: center;
    align-items: center;
}
.food-log-wrapper .food-log-footer .like-share-comment .like-wrapper span {
    display: inline-block;
}
.food-log-wrapper .food-log-footer .like-share-comment .like-wrapper {
    margin-left: 11px;
}
.food-log-wrapper .food-log-footer .like-share-comment .like-wrapper .like-icon {
    width: 18px;
    background: url('../../assets/icons/like-btn.svg') no-repeat center;
    margin-right: 6px;
}
.food-log-wrapper .food-log-footer .like-share-comment .comment-wrapper {
    margin-left: 21px;
}
.food-log-wrapper .food-log-footer .like-share-comment .comment-wrapper span {
    display: inline-block;
}
.food-log-wrapper .food-log-footer .like-share-comment .comment-wrapper .comment-icon {
    width: 18px;
    background: url('../../assets/icons/comment-btn.svg') no-repeat center;
    margin-right: 6px;
}
.food-log-wrapper .food-log-footer .like-share-comment .share-wrapper {
    margin-left: 21px;
}
.food-log-wrapper .food-log-footer .like-share-comment .share-wrapper .share-icon {
    width: 18px;
    display: inline-block;
    background: url('../../assets/icons/share.svg') no-repeat center;
    margin-right: 6px;
}
.food-log-wrapper .food-log-footer .food-log-book-mark-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 36px;
    border: 1px solid #FF4B84;
    border-radius: 18px;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #FF4B84;
}
.food-log-wrapper .food-log-footer .food-log-book-mark-btn .pink-book-mark-icon {
    display: inline-block;
    width: 11.7px;
    height: 14.4px;
    background: url('../../assets/icons/bookmark-pink.svg') no-repeat center;
    margin-right: 7px;
}
