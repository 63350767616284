.wrapper {
    margin: 60px 0 100px 0;
}
.title_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    .titles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        strong {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            margin-bottom: 4px;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.02em;
        }
    }
    .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}
.scroll {
    margin-top: 20px;
}
.item {
    background: url('/assets/banner//zip-card-dummy.png') no-repeat center / cover;
    min-width: 260px;
    height: 314px;
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 5px;
    transition: opacity 0.5s ease-out;
    &:nth-child(1) {
        margin-left: 20px;
    }
    &.inactive {
        opacity: 0.3;
    }
    cursor: pointer;
    .text_wrapper {
        padding: 10px;
        border-radius: 5px;
        background: linear-gradient(0, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        .contents_title {
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
            display: flex;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            text-align: left;
            margin-bottom: 10px;
        }
        .contents_desc {
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            text-align: left;
        }
    }
}