.quick_menu {
  padding: 20px 20px 20px 20px;
  margin-top: 111.73%;
  background: var(--G1, #F7F7FC);
  &.android {
    margin-top: calc(111.73% + var(--status-bar-height));
  }

  &.ios {
    margin-top: calc(126.4% - env(safe-area-inset-top));
  }
}
.brandTitle {
  color: var(--G6, #433E48);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.32px;
  text-align: left;
}
.brandLinkWrapper {
  display: flex;
  margin-top: 12px;
  .brandLink {
    flex: 1;
    border: 1px solid var(--G2, #E9E7EC);
    background: #FFF;
    padding: 16px 9.5px 10px 16px;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    height: 100px;
    cursor: pointer;
    .linkTxtWrapper {
      color: var(--B, #000);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 18px */
      letter-spacing: -0.36px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .linkDesc {
      color: var(--G5, #78737D);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px; /* 16.8px */
      letter-spacing: -0.28px;
      text-align: left;
      margin-top: 8px;
    }
  }
}
.linkIconWrapper {
  .linkIconLocation {
    display: inline-block;
    width: 60px;
    height: 60px;
    background: url('/assets/banner/graphic-1.svg') no-repeat center;
    margin-top: 16px;
  }
  .linkIconArea {
    display: inline-block;
    width: 60px;
    height: 60px;
    background: url('/assets/banner/graphic-2.svg') no-repeat center;
    margin-top: 16px;
  }
}
.bannerLinkBtn {
  width: 100%;
  height: 53px;
  background: #383838;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 18px 16px;
  color: var(--W, #FFF);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  letter-spacing: -0.24px;
  .shop {
    color: var(--W, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    margin-right: 8px;
  }
  .shop_arrow {
    display: inline-block;
    width: 6px;
    height: 12px;
    background: url('/assets/banner/shop-arrow.svg') no-repeat center;
  }
}

.menu_button {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
}

.txt {
  width: 100%;
  margin-top: 10px;
  flex: 1;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.24px;
  max-height: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.imgArea {
  overflow: hidden;
  align-items: center;
  width: 62px;
  height: 62px;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
}
