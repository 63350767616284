.title-wrapper {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 47.92%, rgba(0, 0, 0, 0.3) 100%), rgba(0, 0, 0, 0.2);
    height: 356px;
    padding: 179px 0 0 20px;
}
.title-wrapper .badge-wrapper {
    margin-left: 0 !important;
}
.title-wrapper .title {
    font-family: 'SB AggroOTF';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 34px;
    /* or 131% */
    letter-spacing: -0.02em;
    /* white */
    color: #FFFFFF;
    margin-top: 10px;
    text-align: left;
}
.title-wrapper .description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: -0.02em;
    /* gray2 */
    color: #E9E7EC;
    margin-top: 4px;
    text-align: left;
}
.title-wrapper .date {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.5);
    margin: 20px 0;
    text-align: left;
}
.title-description {
    padding: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    letter-spacing: -0.02em;
    /* black */
    color: #000000;
    text-align: left;
}
.contents-title-wrapper .divider {
    height: 1px;
    background-color: black;
    margin-left: 20px;
}
.market-title-wrapper {
    margin-top: 44px;
}
.market-title-wrapper .contents-wrapper {
    padding: 0 20px;
}
.market-title-wrapper .product-title {
    width: 335px;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
}
.market-title-wrapper .product-title div {
    text-align: left;
}
.market-title-wrapper .price {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #78737D;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}
.market-title-wrapper .filter-group {
    margin-top: 40px;
}
.market-title-wrapper .divider {
    height: 1px;
    background-color: black;
    margin-left: 20px;
    margin-top: 26px;
}