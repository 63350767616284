
.aboutItemsWrapper {
    padding: 40px 0 40px 20px;
}
.magazineInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.magazineInfo {

    .number_wrapper {
        .number {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 56px;
            height: 30px;
            border-radius: 30px;
            background: #000;
            color: #fff;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
        }
    }
    h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-align: left;
        margin: 20px 0 10px 0;
    }
    div {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-right: 20px;
        text-align: left;
        /* or 143% */
    
        letter-spacing: -0.02em;
    }
}
.divider {
    height: 1px;
    background-color: #E9E7EC;
    margin-top: 30px;
    width: 100%;
}
.aboutMagazineItems {
    margin-top: 40px;
}
