.mainMenuWrapper {
  position: fixed;
  background-color: #000;
  z-index: 999;
  width: 375px;
  min-height: 100vh;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  top: 0;
  bottom: 0;
}
.closeBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // margin-top: calc(env(safe-area-inset-top) + 23.29px);
  // &.android {
  //     margin-top: calc(var(--status-bar-height) + 23.29px);
  // }
}
.menuLogo {
  display: inline-block;
  width: 106px;
  height: 18px;
  background: url('/assets/imgs/menu-logo.svg') no-repeat center / cover;
  margin-left: 20px;
  cursor: pointer;
  margin: 0 0 0 20px;
}
.closeIcon {
  margin-right: 23px;
  width: 14px;
  height: 14px;
}
.menuBtnWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  bottom: 140px;
  right: 0;
}
.menuButton {
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #fff;
  margin-right: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  height: 45px;
}
.menuButtonActive {
  background: linear-gradient(90deg, #ff3e48 0%, #ff435f 44%, #ff4b84 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &::after {
    content: ' ';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #ff4b84;
    margin-bottom: 5px;
    margin-left: 11px;
  }
}
.magazineSubMenu {
  color: #9f9ca3;
  text-align: right;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin-right: 30px;
  margin-bottom: 20px;
  li {
    margin-bottom: 15px;
    cursor: pointer;
  }
  li.active {
    text-decoration: underline;
    color: #fff;
  }
  li:nth-child(4) {
    margin-bottom: 0;
  }
}
.bottom {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.loginWrapper {
  margin-right: 30px;
  margin-bottom: 30px;
}
.loginBtn {
  border: 1px solid #fff;
  color: #fff;
  width: 64px;
  height: 34px;
  padding: 4px 10px;
  border-radius: 20px;
  cursor: pointer;
}
.loginBtnPreLogin {
  color: #ff4b84;
  border: 1px solid #ff4b84;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.loginBtnMypage {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  span {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 6px;
  }
}

@media screen and (max-width: 600px) {
  .mainMenuWrapper {
    width: 100%;
  }
}
