// 상세정보
.detailInformation {
  padding: 24px 20px;
  background: #fff;
}
.infoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .logo {
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background: #000;
    }
    .txtWrapper {
      display: flex;
      flex: 1;
      align-items: center;
      margin: 0 8px;

      .title {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 22px */
        letter-spacing: -0.44px;
        overflow: hidden;
        text-align: left;
        word-break: break-all;
        margin-right: 8px;
        margin-top: 4px;
      }
      .areaWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 4px;
        .area {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 14px */
          letter-spacing: -0.28px;
        }
        .divider {
          width: 1px;
          height: 12px;
          background: #d2d0d5;
          margin: 0 6px;
        }
        .areaSelector {
          display: flex;
          align-items: center;
          .selectedArea {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            letter-spacing: -0.28px;
          }
          .areaDropDown {
            width: 16px;
            height: 16px;
            border-radius: 16px;
            background-color: #eee;
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
            .dropdownIcon {
              display: inline-block;
              width: 12px;
              height: 12px;
              background: url('/assets/icons/brand_info_area_selection_down.svg')
                no-repeat center / cover;
            }
          }
        }
      }
    }
  }
}

.descriptions {
  margin-top: 16px;
  word-break: break-all;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.28px;
  text-align: left;
}

.tagsWrapper {
  display: flex;
  margin-top: 16px;
}

.tag {
  display: inline;
  text-align: center;
  padding: 4px 8px;
  background-color: #ededed;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.24px;

  & + .tag {
    margin-left: 4px;
  }
}

// 혜택 카드
.benefitCardWrapper {
  min-width: 150px;
  height: 120px;
  border-radius: 4px;
  background: url('/assets/banner/benefit_dummy.png') no-repeat center / cover;
  position: relative;
  margin-right: 10px;
  &:nth-child(1) {
    margin-left: 20px;
  }
  &:nth-last-child(1) {
    margin-right: 20px;
  }
  .benefit {
    padding: 6px;
    position: absolute;
    top: 0;
    left: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: #ff4b84;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 15px */
  }
  .benefitDownload {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    .txt {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 12px */
      letter-spacing: -0.24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .downloadIcon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url('/assets/icons/brand_benefit_download.svg') no-repeat
        center / cover;
    }
  }
  .benefitSoldOutWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    .soldout {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      background: #000;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .benefitComingSoon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    .comingSoon {
      color: #e9e7ec;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

// 알람 배너
.alarmBannerWrapper {
  border-radius: 5px;
  background: #f7f7fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin: 24px 20px 0 20px;
  .txtArea {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 18.2px */
    letter-spacing: -0.26px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }
  .alarmBtn {
    height: 36px;
    border-radius: 5px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    cursor: pointer;
    .alarmIcon {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url('/assets/icons/brand_alarm_banner_ring.svg') no-repeat
        center / cover;
    }
    .alarmTxt {
      display: inline-block;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      margin-left: 4px;
    }
  }
}

// 혜택 없을 때 나오는 배너
.noBenefitWrapper {
  height: 120px;
  padding: 28px 20px;
  border-radius: 4px;

  h3 {
    color: #ff4b84;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 12px */
    text-align: left;
  }
  .pending {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    color: #fff;
    margin-top: 8px;
    text-align: left;
  }
  &.defaults {
    background: url('/assets/banner/no_benefit_back.png') no-repeat center /
      cover;
  }
}

// product 카드
.productCard {
  position: relative;
  flex: 1;
  cursor: pointer;
  .soldoutWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
  }
  .productImg {
    background: url('/assets/banner/brand_product_img.png') no-repeat center /
      cover;
    border-radius: 4px;
    position: relative;
    .before_open_dimmed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 100%; /* 16px */
      border-radius: 4px;
    }
    .badge {
      padding: 6px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 600;
      line-height: 100%; /* 12px */
      letter-spacing: -0.24px;
      background: #fff;
      position: absolute;
      top: 6px;
      left: 6px;
      &.closed {
        background: #000;
        color: #fff;
      }
    }
  }
  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 16px */
    letter-spacing: -0.32px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  p {
    color: #78737d;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.28px;
    margin-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .originPrice {
    color: #9f9ca3;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    letter-spacing: -0.24px;
    text-decoration: line-through;
    &.noDiscount {
      text-decoration: inherit;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      color: #000;
    }
  }
  .discountInfoArea {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .discountRatio {
      color: #ff4b84;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
    }
    .discountPrice {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      margin-left: 3px;
    }
  }
}
// placeinfo
.placeInfoWrapper {
  .mapArea {
    height: 220px;
    margin: 16px 20px 0 20px;
  }
  .placeInfo {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.28px;
    margin-bottom: 16px;
    margin-left: 20px;
    &:nth-child(1) {
      margin-top: 40px;
    }
    .copy {
      color: #78737d;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
      letter-spacing: -0.28px;
      text-decoration: underline;
      cursor: pointer;
      margin-left: 6px;
    }
    .link {
      text-decoration: underline;
      cursor: pointer;
      margin-right: 6px;
      text-align: left;
    }
    &.address {
      .icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url('/assets/icons/location_icon.svg') no-repeat center /
          contain;
        margin-right: 6px;
      }
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &.schedule {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url('/assets/icons/schedule_icon.svg') no-repeat center /
          contain;
      }
      .scheduleInfo {
        margin-left: 6px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 18.2px */
        letter-spacing: -0.28px;
        display: flex;
        flex-direction: column;
        span {
          text-align: left;
        }
      }
    }
    &.call {
      .icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url('/assets/icons/call_icon.svg') no-repeat center /
          contain;
        margin-right: 6px;
      }
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &.links {
      .icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url('/assets/icons/network_icon.svg') no-repeat center /
          contain;
        margin-right: 6px;
      }
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .findMapBtn {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #d2d0d5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    .icon {
      width: 16px;
      height: 16px;
      display: inline-block;
      background: url('/assets/icons/find_map_btn_icon.svg') no-repeat center /
        cover;
      margin-left: 8px;
    }
  }
}

// 추천 커뮤니티
.communityItem {
  padding: 20px 16px;
  border: 1px solid #e9e7ec;
  border-radius: 5px;
  margin-top: 8px;
  .profiles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .profile {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .pimg {
        width: 28px;
        height: 28px;
        border-radius: 28px;
        background: url('/assets/icons/user_default.svg') no-repeat center /
          cover;
      }
      .name {
        margin-left: 5px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 18.2px */
        letter-spacing: -0.28px;
      }
    }
    .date {
      color: var(--gray4, #9f9ca3);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
      letter-spacing: -0.24px;
    }
  }
  .contents {
    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
      text-align: left;
    }
    .desc {
      overflow: hidden;
      color: #78737d;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 8px 0;
      width: 100%;
      text-align: left;
    }
    .imgs {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      .comImg {
        width: 100%;
        margin-right: 1px;
        object-fit: cover;
        object-position: center;
        min-height: 10px;
      }
      .moreImg {
        width: 25%;
        min-width: 25%;
        position: relative;
        .more {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            background: #fff;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            margin-right: 5px;
          }
        }
      }
      img:nth-child(4) {
        background: #000;
      }
    }
  }
}

.more {
  margin-bottom: 8px;
  span {
    color: var(--5, #78737d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    padding-bottom: 5px;
    &::after {
      content: ' ';
      display: inline-block;
      width: 5px;
      height: 9px;
      background: url('/assets/icons/brand_home_more_icon.svg') no-repeat center /
        cover;
      margin-left: 5px;
      padding-bottom: 2px;
    }
  }
}

.likeWrapper {
  position: relative;
  margin-left: auto;
  margin-top: 10px;

  .likeIcon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('/assets/icons/brand_list_unscrap_gray_icon.svg') no-repeat
      center / contain;
    cursor: pointer;
    &.filled {
      background: url('/assets/icons/brand_list_doscrap_icon.svg') no-repeat
        center / contain;
    }
  }
}

.wrongInfoBtn {
  border: 1px solid #e9e7ec;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -2%;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 0 13px;
  color: #78737d;
}

.mapIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 20px 0 20px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    background: var(--G1, #F7F7FC);
    padding: 13px 0;
    flex: 1;
    color: var(--6, #433E48);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    height: 40px;
  }
}
