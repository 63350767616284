.joinContents {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 44px);
  padding: calc(50px + env(safe-area-inset-top)) 20px 30px 20px;
  // background: #000;
  background: url('/assets/banner//login_bg.png') no-repeat center / cover;
  color: #fff;
  &.android {
    padding: calc(50px + var(--status-bar-height)) 20px 30px 20px !important;
    min-height: 100vh;
  }
  &.ios {
    min-height: 100vh;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navigator {
  position: absolute;
  top: env(safe-area-inset-top);
  &.android {
    top: var(--status-bar-height) !important;
  }
  padding-top: 20px;
  .back {
    display: inline-block;
    width: 10px;
    height: 17px;
    cursor: pointer;
  }
  .home {
    display: inline-block;
    width: 19px;
    height: 18px;
    margin-left: 16px;
    cursor: pointer;
  }
}
.place {
  width: 210px;
  height: 36px;
  background: url('/assets/banner//knewnew_login_txt.svg') no-repeat center /
    cover;
}
.taste {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
  margin-top: 10px;
}
.logo {
  height: 62px;
  width: 300px;
  margin: 16px auto 110px auto;
  background: url('/assets/imgs/app_logo.png') no-repeat center / cover;
}
.joinTxt {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.02em;
}
.snsIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.snsIconWrapper {
  margin-left: 30px;
  cursor: pointer;
  &:nth-child(1) {
    margin-left: 0;
  }
}
.sns {
  display: flex;
  width: 50px;
  height: 50px;
}
.snsLoginButton {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: -0.32px;
  & > span {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  margin-top: 10px;
  &.kakao {
    background: #fee500;
  }
  &.google {
    background: #fff;
  }
  &.apple {
    background: #fff;
  }
}
.emailLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  & a {
    text-decoration: underline;
  }
  margin-top: 30px;
}
.joinProblem {
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-decoration: underline;
  color: #e9e7ec;
}
.policyInfo {
  margin-top: 40px;
  font-weight: 200;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.04em;
  color: #e9e7ec;
  margin-bottom: 10px;
}
.policyLink {
  color: #e9e7ec;
  font-weight: 600;
}
.completeMessage {
  margin-top: 116px;
  h1 {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 26px */
    letter-spacing: -0.52px;
  }
  div {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    letter-spacing: -0.4px;
    margin-top: 10px;
  }
}
.completeImg {
  width: 100%;
  margin-top: 40px;
}
.completeInfo {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
  text-align: center;
  margin-top: 40px;
}
.startButtonWrapper {
  position: absolute;
  bottom: 40px;
  left: 20px;
  right: 20px;
  .startButton {
    height: 50px;
    background: #000;
    border-radius: 5px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
  }
}

.app_install {
  // position: fixed;
  // left: auto;
  // right: auto;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.24px;
  color: #fff;
  text-align: left;
  .lft {
    display: flex;
    .appIcon {
      width: 24px;
      height: 24px;
      border-radius: 8px;
      background: #433e48;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      .appLogo {
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url('/assets/icons/appIcon.svg') no-repeat center / cover;
      }
    }
  }
}

.install_btn {
  margin-left: auto;
  padding: 5px 12px;
  border-radius: 16px;
  background-color: #ff4b84;
  font-size: 12px;
  line-height: 14px;
}

.qr_popup {
  background-color: #fff;
  white-space: pre;
  padding: 40px 16px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
  color: #433e48;
  border-radius: 10px;

  img {
    margin: 20px auto 8px auto;
    width: 80px;
    height: 80px;
  }

  .desc {
    display: block;
    font-size: 12px;
    line-height: 16px;
  }

  .close_btn {
    display: block;
    width: 283px;
    margin-top: 20px;
    border: 1px solid #e9e7ec;
    border-radius: 5px;
    font-size: 16px;
    line-height: 50px;
  }
}

.dimmed_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.title {
  padding-bottom: 30px;
}
