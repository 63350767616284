.wrapper {
  margin: 40px 20px;
  cursor: pointer;
  position: relative;
  .not_login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    strong {
      color: var(--black, var(--color-black, #000));
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 25.2px */
      letter-spacing: -0.36px;
    }
    button {
      width: 335px;
      height: 50px;
      background: #000;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
      border-radius: 5px;
      margin-top: 34px;
      position: relative;
      span {
        position: absolute;
        color: var(--G6, #433e48);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 12px */
        letter-spacing: -0.24px;
        border-radius: 11px;
        background: #fee502;
        width: 148px;
        height: 22px;
        top: -20px;
        left: 29%;
        padding-top: 5px;
        &::after {
          content: ' ';
          display: block;
          width: 10px;
          height: 10px;
          background: url('/assets/icons/yello_polygon.svg') no-repeat center /
            cover;
          position: absolute;
          left: 50%;
        }
      }
    }
  }
}
.imgWrapper {
  width: 100%;
  height: 144px;
  overflow: hidden;
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: auto;
    width: 100%;
    img {
      // flex: 1;
      width: 33.3%;
      margin-right: 1px;
      &:nth-child(1) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:nth-child(3) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 12px;
  strong {
    color: var(--B, #000);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    letter-spacing: -0.4px;
  }
  .benefit {
    border-radius: 2px;
    background: rgba(255, 75, 132, 0.2);
    display: flex;
    height: 24px;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    color: var(--P, #ff4b84);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    letter-spacing: -0.24px;
  }
}
.locationWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--B, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: -0.28px;
  margin-top: 8px;
}
.infoWrapper {
  overflow: hidden;
  color: var(--G5, #78737d);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.28px;
  text-align: left;
  margin-top: 8px;
}

@keyframes fadeInUp {
  from {
    transform: translateY(80%);
  }
  to {
    transform: translateY(0);
  }
}

.fade_in_up {
  animation: fadeInUp 0.5s ease-out forwards;
}

.anim_container {
  transform: translateY(80%);
}
