.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 30px 20px;
  cursor: pointer;
}
.txtWrapper {
  h3 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 18px */
    letter-spacing: -0.36px;
    text-align: left;
    margin-bottom: 2px;
  }
  span {
    color: #78737d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
  }
}
.linkWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  .linkTxt {
    color: #ff4b84;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.24px;
    border-radius: 4px;
    background: rgba(255, 75, 132, 0.06);
    padding: 0 6px;
    height: 24px;
    display: flex;
    align-items: center;
  }
  .arrow {
    display: inline-block;
    background: url('/assets/icons/new_brand_arrow.svg') no-repeat center /
      cover;
    width: 20px;
    height: 20px;
  }
}
