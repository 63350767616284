.input {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9e7ec;
  background: #fff;
  margin-right: 10px;
  span {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -0.4px;
  }
  &.active {
    border: 1px solid #9f9ca3;
  }
}
.inputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7fc;
  min-height: 346px;
}

.keyboardWrapper {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 0 20px;
}

.giftKeyboardWrapper {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 0 20px;
}

.key {
  flex: 1 0 33%;
  display: flex;
  height: auto;
  padding: 17px 0;
  justify-content: center;
  align-items: center;
  color: #433e48;
  font-size: 26px;
  font-weight: 700;
  line-height: 100%; /* 26px */
  letter-spacing: -0.52px;
  .backBtn {
    display: inline-block;
    background: url('/assets/icons/back_btn_market_keyboard.svg') no-repeat
      center / cover;
    width: 24px;
    height: 26px;
  }
}

.giftKey {
  flex: 1 0 33%;
  display: flex;
  height: auto;
  padding: 12px 0;
  justify-content: center;
  align-items: center;
  color: #433e48;
  font-size: 26px;
  font-weight: 700;
  line-height: 100%; /* 26px */
  letter-spacing: -0.52px;
  .backBtn {
    display: inline-block;
    background: url('/assets/icons/back_btn_market_keyboard.svg') no-repeat
      center / cover;
    width: 24px;
    height: 26px;
  }
}
.confirmBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 20px;
  background: #fff;
  border-top: 1px solid #e9e7ec;
  width: 100%;
  margin-top: 30px;
}
.giftConfirmBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 20px;
  background: #fff;
  border-top: 1px solid #e9e7ec;
  width: 100%;
  margin-top: 10px;
}
.confirmBtn {
  height: 50px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  color: #fff;
  border-radius: 8px;
  &.disabled {
    background: #e9e7ec;
  }
}
