.curatorCard {
    width: 47.62%;
    height: 200px;
    background: linear-gradient(180deg, #504E4F 0%, #18171A 100%);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 16.8px;
    flex-direction: column;
}
/* .curators .curator-lists .curator-row .curator-card.left {
    margin-right: 15px;
} */
.curatorImg {
    width: 90px;
    height: 90px;
    border-radius: 90px;
    background: url('/assets/banner//curator-dummy-img.png');
    margin-top: 15px;
}
.nickName {
    margin-top: 20px;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
}
.hashtags {
    margin-top: 10px;
    padding: 0 10px;
    color: #9F9CA3;
}
.tag {
    margin-right: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
}