.comments-wrapper {
    padding: 0 20px;
    margin-top: 20px;
}
.comments-wrapper .comment-cnt {
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    /* black */
    color: #000000;
    margin-bottom: 20px;
    padding-top: 20px;
}
.comments-wrapper .comment-cnt .cnt {
    color: #9F9CA3;
    font-weight: 400;
    font-size: 13px;
    margin-left: 5px;
}
.comments-wrapper .comment-list .comment-item {
    margin-bottom: 15px;
}
.comments-wrapper .comment-list .comment-item .main-comment {
    margin-bottom: 15px;
}
.comments-wrapper .comment-list .comment-item .main-comment .user-info {
    display: flex;
    justify-content: space-between;
}
.comments-wrapper .comment-list .comment-item .main-comment .user-info .user-cont {
    display: flex;
    cursor: pointer;
}
.comments-wrapper .comment-list .comment-item .main-comment .user-info .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 30px;
}
.comments-wrapper .comment-list .comment-item .main-comment .user-info .options .dot {
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: #E9E7EC;
    margin-top: 2px;
}
.comments-wrapper .comment-list .comment-item .main-comment .user-info .user-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.comments-wrapper .comment-list .comment-item .main-comment .user-info .user-contents .user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.comments-wrapper .comment-list .comment-item .main-comment .user-info .user-contents .user .name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    /* black */
    color: #000000;
    margin-left: 6px;
    text-align: left;
}
.comments-wrapper .comment-list .comment-item .writer-sticker {
    min-width: 38px;
    height: 18px;
    border: 1px solid #9F9CA3;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    /* identical to box height, or 10px */
    align-items: center;
    letter-spacing: -0.02em;
    /* gray4 */
    color: #9F9CA3;
    padding: 4px;
    margin-left: 5px;
}
.comments-wrapper .comment-list .comment-item .main-comment .user-info .user-contents .user .date {
    min-width: 28px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.02em;
    /* gray3 */
    color: #D2D0D5;
    margin-left: 6px;
} 
.comments-wrapper .comment-list .comment-item .main-comment .comment-body {
    padding-left: 36px;
    margin-top: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */
    letter-spacing: -0.02em;
    /* gray6 */
    color: #433E48;
    text-align: left;
    word-break: break-all;
}

.comments-wrapper .comment-list .comment-item .comment-footer {
    display: flex;
    padding-left: 36px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.02em;
    color: #000000;
    /* margin-bottom: 15px; */
}
.comments-wrapper .comment-list .comment-item .comment-footer .dot {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.comments-wrapper .comment-list .comment-item .comment-footer .dot div {
    width: 2px;
    height: 2px;
    background-color: #000;
    border-radius: 2px;
}
.comments-wrapper .comment-list .comment-item .comment-footer .like-btn-wrapper {
    margin-left: 5px;
}
.comments-wrapper .comment-list .comment-item .comment-footer .like-btn-wrapper .bold {
    font-weight: 700;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper {
    background: #f8f8f8;
    margin-bottom: 2px;
    padding: 10px 15px;
}

.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .user-info-wrapper {
    display: flex;
    justify-content: space-between;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .user-info-wrapper .user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .user-info-wrapper .user-info .pic-wrapper {
    margin-left: 5px;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .user-info-wrapper .user-info .pic-wrapper .child-writer-image-default{
    background: url('../../assets/imgs/user_default.svg') center no-repeat;
    background-size: cover;
    width: 24px;
    height: 24px;
    border-radius: 24px;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .user-info-wrapper .user-info .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    color: #000000;
    margin-left: 6px;
    text-align: left;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .user-info-wrapper .user-info .date {
    min-width: 28px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.02em;
    /* gray3 */
    color: #D2D0D5;
    margin-left: 6px;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .user-info-wrapper .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width:24px;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .user-info-wrapper .options .dot {
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: #E9E7EC;
    margin-top: 2px;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .comment-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */
    letter-spacing: -0.02em;
    color: #433E48;
    text-align: left;
    padding-left: 43px;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .comment-body .author {
    color: #FF4B84;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .comment-footer {
    padding-left: 43px !important;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .user-info-wrapper .user-info .nested-comment-icon {
    display: block;
    background: url('../../assets/icons/nested_comment_icon.svg') no-repeat left;
    width: 8px;
    height: 8px;
}

.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .enter-answer-wrapper {
    position: relative;
    /* width: 100%; */
    margin: 0 2px;
    height: 42px;
    margin-bottom: 60px;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .enter-answer-wrapper .enter-comments {
    width: 100%;
    padding: 14px 45px 14px 20px;
    /* white */
    background: #FFFFFF;
    /* gray2 */
    border: 1px solid #E9E7EC;
    border-radius: 21px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 14px */

    letter-spacing: -0.02em;
}
.comments-wrapper .comment-list .comment-item .nested-comment-wrapper .enter-answer-wrapper .submit {
    position: absolute;
    right: 20px;
    top: 17px;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;

    /* gray4 */
    color: #9F9CA3;
}
.comments-wrapper .more-comment-btn {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #F7F7FC;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.comments-wrapper .more-comment-btn .arrow-right-icon {
    background: url('../../assets/icons/arrow-right2.svg') no-repeat center;
    width: 5px;
    height: 10px;
    margin-left: 5.5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
}
.divider {
    height: 1px;
    background-color: #E9E7EC;
    width: 100%;
}
.enter-something {
    margin-bottom: 40px;
    padding-top: 20px;
}
.comments-wrapper .enter-comments-wrapper {
    position: relative;
    /* width: 343px; */
    border: 1px solid #E9E7EC;
    border-radius: 21px;
}

.enter-something .enter-answer-wrapper {
    position: relative;
    /* width: 100%; */
    margin: 0 20px;
    height: 42px;
    margin-bottom: 2px;
    box-shadow: 0px -2px 10px rgba(0,0,0,0.1);
    border-radius: 21px;
}

.enter-comments-wrapper {
    border: 1px solid #E9E7EC;
}

.enter-something .enter-answer-wrapper .enter-answer-comments {
    width: 100%;
    padding: 14px 45px 14px 20px;
    /* white */
    background: #FFFFFF;
    /* gray2 */
    border: 1px solid #E9E7EC;
    border-radius: 21px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
}

.enter-something .enter-answer-wrapper .enter-answer-comments :focus {
  outline: none;
  border:1px solid #FFF;

}

.comments-all .enter-something .enter-comments-wrapper {
    margin: 0 20px;
}
.enter-something .enter-answer-wrapper .submit {
    position: absolute;
    right: 20px;
    top: 35%;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;

    /* gray4 */
    color: #9F9CA3;
}

.comments-wrapper .enter-comments-wrapper .enter-comments {
    width: 100%;
    padding: 14px 45px 14px 20px;
    /* white */
    background: #FFFFFF;
    /* gray2 */
    border-radius: 21px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: left;
    cursor: auto;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
}
/* .community-detail-wrapper .comments-wrapper .enter-comments-wrapper {
    margin: 20px 0 60px 0;
} */
.comments-wrapper .enter-comments-wrapper.active {
    border: 1px solid #FF4B84;
}
.comments-wrapper .enter-comments-wrapper .enter-comments:focus {
    outline: none;
}
.comments-wrapper .enter-comments-wrapper .enter-comments.active {
    /* border: 1px solid #FF4B84; */
    /* outline: 1px solid #FF4B84; */
    outline: none;
}
.comments-wrapper .enter-comments-wrapper .enter-comments:empty:before {
    content: attr(placeholder);
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    /* gray4 */
    color: #9F9CA3;
    text-align: left;
}

.comments-wrapper .enter-comments-wrapper .submit {
    position: absolute;
    right: 20px;
    top: 36%;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;

    /* gray4 */
    color: #9F9CA3;
}
.comments-wrapper .enter-comments-wrapper .submit.active {
    color: #FF4B84;
}
.comments-wrapper .no-reply {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    text-align: center;
    letter-spacing: -0.02em;

    /* gray4 */

    color: #9F9CA3;
    margin: 40px 0;
}

.report-page .report-reason {
    margin-top: 30px;
    padding: 0 20px;
}
.report-page .report-reason .report-title {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    line-height: 22.4px;
}
.report-page .report-reason .report-title span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.8px;
}
.report-page .report-reason .report-reason-checker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}
.report-page .report-reason .report-reason-checker .report-reason-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 16px;
}

.report-page .report-reason .report-reason-checker .report-reason-item input[type=checkbox] {
    /* display: block; */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    /* margin: 0; */

    margin-right: 17px;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 1px solid #E9E7EC;
    border-radius: 2px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
.report-page .report-reason .report-reason-checker .report-reason-item input[type=checkbox]::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}
.report-page .report-reason .report-reason-checker .report-reason-item input[type=checkbox]:checked {
  background: #000;
  border: 0;
}
.report-page .report-reason .report-reason-checker .report-reason-item input[type=checkbox]:checked::before {
  transform: scale(1);
  background-color: #fff;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.report-page .report-reason .detail-reason-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 40px;
}
.report-page .report-reason .detail-reason-wrapper textarea {
    height: 160px;
    border: 1px solid #E9E7EC;
    border-radius: 5px;
    resize: none;
    padding: 12px 10px;
    outline: none;
}
.report-page .report-reason .detail-reason-wrapper span {
    margin-top: 10px;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #433E48;
}
.report-page .report-reason .detail-reason-wrapper .text-length-counter {
    position: absolute;
    bottom: 52px;
    right: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #D2D0D5;
}
.report-page .report-btn-wrapper {
    margin-top: 140px;
    padding: 0 20px;
}
.writer-image-default {
    background: url('../../assets/imgs/user_default.svg') center no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    border-radius: 30px;
}