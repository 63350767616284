.magazine-wrapper {
    background: #fff;
}
.magazine-wrapper .magazine-header {
    position: relative;
    padding: 20px 20px 0 20px;
}
.magazine-wrapper .magazine-header .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: left;
}
.magazine-wrapper .magazine-header .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 26px;
    margin-bottom: 15px;
}
.magazine-wrapper .magazine-header .menu-wrapper .tabs .tab {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #000;
    margin-right: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    cursor: pointer;
}
.magazine-wrapper .magazine-header .menu-wrapper .tabs .tab.active {
    background: #000;
    color: #fff;
}
.magazine-wrapper .magazine-header .help-wrapper .help-circle-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    cursor: pointer;
}
.magazine-wrapper .bottom {
    display: flex;
    justify-content: flex-end;
}
.magazine-wrapper .bottom .bottom-line {
    height: 1px;
    background-color: #000;
    width: calc(100% - 20px);

}
.magazine-wrapper .magazine-contents {
    margin-top: 40px;
    padding: 0 20px;
    /* box-shadow: 0px 0px 10px 0px #00000026; */
}
.magazine.item {
    margin-top: 50px;
    cursor: pointer;
}
.magazine.item:nth-child(1) {
    margin-top: 0;
}
.magazine.item .picture-wrapper {
    position: relative;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
}
.magazine.item .picture-wrapper .magazine-badge {
    font-family: 'SB AggroOTF';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 60px;
    height: 20px;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
}
.magazine.item .picture-wrapper .magazine-badge.foodlife {
    background-color: #000;
    color: #fff;
    padding: 5px 8px 3px 8px;
    border-radius: 3px;
}
.magazine.item .picture-wrapper .magazine-badge.article {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #FF3E48 0%, #FF435F 44%, #FF4B84 100%);
    border-radius: 2px;
    color: #fff;
    padding: 5px 8px 3px 8px;
}
.magazine.item .writer-dates {
    display: flex;
    margin: 10px 0;
}
.magazine.item .writer-dates .writer {
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    padding-right: 10px;
    border-right: 1px solid #E9E7EC;
    letter-spacing: -0.02em;
}
.magazine.item .writer-dates .date {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    padding-left: 10px;
    color: #78737D;
}
.magazine.item .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    letter-spacing: -0.02em;
    /* gray5 */
    color: #78737D;
    text-align: left;
}
.magazine.item .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height, or 125% */
    letter-spacing: -0.02em;
    text-align: left;
    margin-top: 10px;
}
.magazine.item .tags {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
}
.magazine-item-list .btn-wrapper {
    margin-top: 50px;
    margin-bottom: 60px;
}
.magazine-item-list .btn-wrapper .arrow-down-black-icon {
    display: inline-block;
    width: 10px;
    height: 5px;
    margin-left: 6px;
}
.magazine-detail-contents {
    background: #000;
}
.magazine-detail-contents .main-image-wrapper {
    position: fixed;
    z-index:4;
    background-size: cover !important;
}
.magazine-detail-contents .magazine-detail-wrapper {
    position: relative;
    padding-top: 240px;
    border-radius: 15px 15px 0px 0px;
    background: #fff;
    z-index: 5;
}
.magazine-detail-contents .magazine-detail-wrapper .food-life {
    color: #9F9CA3;
    font-size: 14px;
}
.magazine-detail-contents .magazine-detail-wrapper .magazine-title {
    font-family: SB AggroOTF;
    font-size: 28px;
    line-height: 34px;
    margin-top: 10px;
}
.magazine-detail-contents .magazine-detail-wrapper .magazine-sub-title {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -2%;
    margin: 8px 0 40px 0;
}
.magazine-detail-contents .magazine-detail-wrapper .magazine-profile-wrapper {
    background: #F7F7FC;
    border: 1px solid #F7F7FC;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}
.magazine-detail-contents .magazine-detail-wrapper .magazine-profile-wrapper .profiles {
    display: flex;
    justify-content: center;
    align-items: center;
}
.magazine-detail-contents .magazine-detail-wrapper .magazine-profile-wrapper .profiles .profile-image {
    margin-right: 10px;
}
.magazine-detail-contents .magazine-detail-wrapper .magazine-profile-wrapper .profiles .nick-name {
    font-weight: 500;
}
.magazine-detail-contents .magazine-detail-wrapper .magazine-profile-wrapper .follow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #000000;
    background: #000;
    color: #fff;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    padding: 8px 17px;
    height: 28px;
}

.magazine-detail-contents .magazine-detail-wrapper .magazine-profile-divider {
    display: block;
    height: 1px;
    background: #000;
    width: 95%;
    margin: 20px 0 0 20px;
}
.magazine-detail-footer {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 31px;
    z-index: 6;
    width: 375px;
    background: #fff;
}
.magazine-detail-footer .like-comment-wrapper {
    display: flex;
}
.magazine-detail-footer .share-wrapper .share-icon {
    width: 16px;
    height: 18px;
    display: inline-block;
}
.magazine-detail-contents .magazine-detail-footer .like-comment-wrapper .like-wrapper {
    width: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.magazine-detail-contents .magazine-detail-footer .like-comment-wrapper .comment-wrapper {
    width: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 28px;
}
.magazine-detail-contents .magazine-detail-footer .like-icon {
    width: 18px;
    height: 17px;
    display: inline-block;
}
.magazine-detail-contents .magazine-detail-footer .reaction-best-large-icon.active {
    width: 20px;
    height: 19px;
    display: inline-block;
    background-size: cover;
}
.magazine-detail-contents .magazine-detail-footer .comment-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
}

@media screen and (max-width: 600px) {
  .magazine-detail-footer {
    width: 100% !important;
  }
  .main-image-wrapper {
    width: 100% !important;
  }
}
