.modalWrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 11;
}
.modalContents {
  position: absolute;
  /* bottom: -500px; */
  padding: 0 20px;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border-radius: 10px 10px 0px 0px;
  padding: 23px;
  width: 375px;
  height: 415px;
  z-index: 12;
  &.active {
    animation: slideUp 0.5s ease;
  }
  &.close {
    transform: translateY(500px);
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    .headerIcon {
      display: inline-block;
      background: url('/assets/icons/brand_address_selector_down_icon.svg')
        no-repeat center / cover;
      width: 16px;
      height: 8px;
      cursor: pointer;
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.modalBody {
  margin-top: 10px;
  margin-bottom: 20px;
  li {
    font-weight: 500;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    font-size: 16px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f7f7fc;
    cursor: pointer;
    color: #9f9ca3;
    &.active {
      color: #000;
    }
  }
}

.wrapper {
  margin-bottom: 20px;
  .tit {
    color: var(--B, #000);
    font-family: 'Pretendard Variable';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    margin-bottom: 4px;
    text-align: left;
  }
  .desc {
    color: var(--G5, #78737d);
    font-family: 'Pretendard Variable';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    text-align: left;
  }
}

.mapArea {
  height: 220px;
  width: 100%;
  margin-top: 16px;
}

.findMapBtn {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #d2d0d5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: -0.32px;
  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url('/assets/icons/find_map_btn_icon.svg') no-repeat center /
      cover;
    margin-left: 8px;
  }
}
.placeInfo {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.28px;
  // &:nth-child(1) {
  //   margin-top: 40px;
  // }
  &.tit {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 6px;
  }
  .copy {
    color: #78737d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.28px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 6px;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 6px;
    text-align: left;
  }
  &.running {
    text-align: left;
    margin-bottom: 16px;
    white-space: pre-line;
  }
  &.address {
    .icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url('/assets/icons/location_icon.svg') no-repeat center /
        contain;
      margin-right: 6px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
  }
  &.schedule {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url('/assets/icons/schedule_icon.svg') no-repeat center /
        contain;
    }
    .scheduleInfo {
      margin-left: 6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
      letter-spacing: -0.28px;
      display: flex;
      flex-direction: column;
      span {
        text-align: left;
      }
    }
  }
  &.call {
    .icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url('/assets/icons/call_icon.svg') no-repeat center / contain;
      margin-right: 6px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &.links {
    .icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url('/assets/icons/network_icon.svg') no-repeat center /
        contain;
      margin-right: 6px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
@keyframes slideUp {
  from {
    /* 하위 120px 에서 시작합니다. */
    transform: translateY(500px);
  }
  to {
    /* 원 위치로 이동합니다. */
    transform: translateY(0);
  }
}
