.shareSheet {
    padding: 10px 20px 20px 20px;
    .item {
        font-weight: 500;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
        font-size: 16px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #F7F7FC;
        cursor: pointer;
        color: #9F9CA3;
        cursor: pointer;
        text-align: center;
        &.active {
            color: #000;
        }
    }
    .divider {
        width: 100%;
        height: 1px;
        color: #F7F7FC;
    }
}
