.divider {
    width: 100%;
    height: 8px;
    background: #F7F7FC;
}
.myEditProfile {
    margin-top: 30px;
    background-color: #fff;
    .userProfile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .profileImage {
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            border-radius: 60px;
            background: #E9E7EC;
            background-size: cover !important;
            .noImageIcon {
                width: 100%;
                height: 100%;
                display: inline-block;
                background: url('/assets/imgs/user_default.svg') no-repeat center / cover;
            }
            .circleWhitePlusIcon {
                display: inline-block;
                position: absolute;
                bottom: 0;
                width: 18px;
                height: 18px;
                right: 0;
                background: url('/assets/icons/circle-white-plus.svg') no-repeat center;
            }
        }
        .removeImageTxt {
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            /* identical to box height, or 12px */
            text-align: center;
            letter-spacing: -0.02em;
            /* gray3 */
            color: #D2D0D5;
            margin-top: 10px;
        }
    }
    .infoForm {
        margin-top: 20px;
        .inputForm {
            .label {
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                /* identical to box height, or 16px */
                letter-spacing: -0.02em;
                color: #000000;
                text-align: left;
                padding: 0 20px;
                .option {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 100%;
                    /* identical to box height, or 12px */
                    letter-spacing: -0.02em;
                    /* gray3 */
                    color: #D2D0D5;
                }
            }
            .asterisk {
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                /* identical to box height, or 16px */
                letter-spacing: -0.02em;
                color: #FF4B84;
                text-align: left;    
            }
            &.name {
                position: relative;
                .input {
                    border-top: 1px solid #F7F7FC;
                    border-bottom: 1px solid #F7F7FC;
                    height: 54px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 40px;
                    padding: 0 20px;
                    input[type='text'] {
                        height: 24px;
                        width: 100%;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 150%;
                        /* identical to box height, or 24px */
                    
                        letter-spacing: -0.02em;
                        &::placeholder {
                            color: #D2D0D5;
                        }
                    }
                    input[type='number']::placeholder {
                        color: #D2D0D5;
                    }
                }
            }
            &.introduction {
                margin-bottom: 55px;
                .introductionContents {
                    margin-top: 25px;
                    text-align: left;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                    width: 100%;
                    resize: none;
                    outline-color: #FFF;
                    padding: 5px 25px;
                    &:focus {
                        outline: none;
                    }
                    &::placeholder {
                        color:#D2D0D5;
                    }
                }
            }
            &.linkSns {
                .snsInputBox {
                    display: flex;
                    height: 54px;
                    justify-content: flex-start;
                    align-items: center;
                    border-bottom: 1px solid #F7F7FC;
                    padding: 0 20px;
                    &.youtube {
                        border-top: 1px solid #F7F7FC;
                    }
                    .snsInput {
                        input {
                            width: 300px;
                            height: 21px;
                            margin-left: 10.5px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 150%;
                            &::placeholder {
                                color:#D2D0D5; 
                            }
                        }
                    }
                }
                .label {
                    margin-bottom: 10px;
                }
            }
        }
        .deleteWrapper {
            display: flex;
            justify-content: flex-end;
        }
        .deleteUser {
            color: #9F9CA3;
            text-align: right;
            font-size: 12px;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
            letter-spacing: -0.24px;
            text-decoration-line: underline;
            margin: 0 20px 40px 0;
        }
    }
}
.additionalInfo {
    margin-bottom: 30px;
}
.btnWrapper {
    position: sticky;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0 20px;
    background: #fff;
    .editBtn {
        background: #000;
        color: #fff !important;
    }
}