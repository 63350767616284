.wrapper {
  width: 100%;
  margin-bottom: 50px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .title {
    display: flex;
    align-items: center;
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 100%; /* 18px */
      letter-spacing: -0.36px;
    }
    .subtitle {
      color: #78737d;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      margin-left: 6px;
    }
  }
  .more {
    span {
      color: var(--5, #78737d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      &::after {
        content: ' ';
        display: inline-block;
        width: 5px;
        height: 9px;
        background: url('/assets/icons/brand_home_more_icon.svg') no-repeat
          center / cover;
        margin-left: 5px;
      }
    }
  }
}
.images {
  display: flex;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
  cursor: pointer;
  &.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    transform: scale(1);
  }
  .brandItem {
    min-width: 36.267%;
    width: 36.267%;
    margin-right: 4px;
    img {
      width: 100%;
      // height: 136px;
      border-radius: 4px;
      object-fit: cover;
      object-position: center;
    }
    .itemTitle {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 14px */
      letter-spacing: -0.28px;
      text-align: left;
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 2줄 말줄임으로 변경
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    .itemDesc {
      width: 100%;
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 6px 0 0 0;

      color: #78737d;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
      letter-spacing: -0.24px;
      text-align: left;
    }
    .itemPrice {
      text-align: left;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.28px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;

      span {
        color: #ff4b84;
        margin-right: 3px;
      }
    }
    &:nth-child(1) {
      margin-left: 20px;
    }
    &:nth-last-child(1) {
      margin-right: 20px;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
