.button {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    cursor: pointer;
}
