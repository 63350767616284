
/* community */
.my_communities {
    background: #fff;
    // padding: 40px 0 0 0;
    min-height: calc(100vh - 56px - 265px - 85px);
    &.other {
        min-height: calc(100vh - 56px - 210px - 85px);
    }
}

.header {
    padding: 0 20px;
    .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #000000;
        text-align: left;
    }
    .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #9F9CA3;
        margin-top: 8px;
        text-align: left;
    }
    &.community {
        margin: 30px 0 20px 0;
    }
    &.my {
        display: none;
    }
}
.no_communities {
    height: 322px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    &.normal {
        .txt {
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
        }
        button {
            margin-top: 115px;
            width: 100%;
            height: 50px;
            border-radius: 5px;
            color: #fff;
            background: #000;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: -0.02em;
        }
    }
    &.creator {
        .txt {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #78737D;
        }
        button {
            margin-top: 15px;
            width: 165px;
            height: 30px;
            border: 1px solid #000;
            border-radius: 30px;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: #000;

        }
    }
    &.other_show {
        .txt {
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #78737D;
        }
        button {
            margin-top: 15px;
            width: 165px;
            height: 30px;
            border: 1px solid #000;
            border-radius: 30px;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: #000;
        }
    }
}
.communities {
    display: inline-block;
    width: 100%;
    .row {
        display: flex;
        margin-bottom: 1px;
        div:nth-child(1) {
            margin-right: 1px;
        }
    }
}
