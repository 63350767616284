.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    width: 100%;
}
.grayFilled {
    background: #E9E7EC;
    border-radius: 5px;
    color: #FFF;
}
.whiteFilled {
    background: #FFF;
    border-radius: 5px;
    color: #000;
    border: 1px solid #000;
}
.blackFilled {
    background: #000;
    border-radius: 5px;
    color: #FFF;
    border: 1px solid #000;
}
