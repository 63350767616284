.wrapper {
  width: 100%;
  height: calc(100vh - 56px - 85px);
  display: flex;
  align-items: center;
  justify-content: center;
  &.hide {
    animation: fadeOut 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100vh - 56px - 85px);
    background: #fff;
    z-index: 999;
  }

  &.fixedHeader {
    margin-top: 56px;
  }
}
.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('/assets/icons/loading.gif') no-repeat center /
    cover;
  &.black {
    background: url('/assets/icons/black_spinner.gif') no-repeat
      center / cover;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    display: flex;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
