.contentHeader {
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
  justify-content: center;
  align-items: center;
  width: 375px;
  padding-top: env(safe-area-inset-top);
  height: calc(56px + env(safe-area-inset-top));
  background: #fff;
  &.android {
    padding-top: var(--status-bar-height);
    height: calc(56px + var(--status-bar-height));
  }
}
.black {
  background: #000;
  color: #fff;
}

.firstblock {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contentBack {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 20px;
  background: url('/assets/icons/arrow-right.svg') no-repeat center;
  &.black {
    background: url('/assets/icons/arrow-left-white.svg') no-repeat
      center;
  }
  &.popup {
    background: url('/assets/icons/icon-popup-back.svg') no-repeat
      center;
  }
}

.contentHome {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  right: 0;
  background: url('/assets/icons/home-active-icon.svg') no-repeat center;
}
.whiteHome {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  right: 0;
}

.middleblock {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  &.left {
    justify-content: flex-start !important;
  }
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  margin-left: 0px;
  .dropdown {
    width: 12px;
    height: 12px;
    display: inline-block;
    background: url('/assets/icons/magazine_category_dropdown.svg')
      no-repeat center / cover;
    margin-left: 8px;
  }
}

.lastblock {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.options {
  margin-right: 20px;
  width: 20px;
  height: 20px;
  background: url('/assets/icons/more.svg') no-repeat center / cover;
  cursor: pointer;
}

.dot {
  display: block;
  height: 4px;
  width: 4px;
  background: #000;
  margin-bottom: 3px;
  border-radius: 6px;
}

.tempSave {
  position: absolute;
  right: 28px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.04em;
  color: #78737d;
}

.activeTempSave {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #ff4b84;
  margin-left: 3px;
}

@media screen and (max-width: 600px) {
  .contentHeader {
    width: 100%;
  }
}
