
.enterCouponNumberWrapper {
    background: #fff;
    margin: 30px 20px;
    .enterArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
            width: 100%;
            height: 48px;
            padding: 16px 20px;
            border-radius: 5px;
            background: var(--w, #FFF);
            border: 1px solid #9F9CA3;
            &.disabled {
                border: 1px solid var(--2, #E9E7EC);
            }
        }
        button {
            background: #000;
            border-radius: 5px;
            min-width: 100px;
            height: 48px;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 16px */
            letter-spacing: -0.32px;
            color: #fff;
            margin-left: 5px;
            &:disabled {
                background: #E9E7EC;
            }
        }
    }
    .couponWarning {
        color: #9F9CA3;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: -0.24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 8px;
    }
}
.couponList {
    margin: 20px 0 0 0;
    padding: 0 20px 20px 20px;
    background: #F7F7FC;
    min-height: calc(100vh - 205px);
    .listHeader {
        display: flex;
        justify-content: space-between;
        padding: 20px 0 30px 0;
        align-items: center;
        div:nth-child(1) {
            .ownCoupon {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%; /* 14px */
                letter-spacing: -0.28px;
                color: #000000;
                margin-right: 5px;
            }
            .ownCouponCnt {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                letter-spacing: -0.28px;
                color: #9F9CA3;
            }
        }
        div:nth-child(2) {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            letter-spacing: -0.28px;
            color: #000;
            .rightArrowIcon {
                display: inline-block;
                width: 12px;
                height: 12px;
                background: url('/assets/icons/coupon_warning_rightr_arrow.svg') no-repeat center / cover;
                margin-left: 2px;
            }
        }
    }
    .couponNoAuth {
        .couponIntroArea {
            background: url('/assets/banner/welcome_coupon_pack.png') no-repeat center / cover;
            min-height: 341px;
            border-radius: 5px;
            margin: 0px;
        }
        .authButtonArea {
            margin: 10px 0px 0 0px;
            button {
                height: 50px;
                border-radius: 5px;
                background-color: #000;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
            p {
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 16.8px */
                letter-spacing: -0.24px;
                color: #9F9CA3;
            }
        }
    }
}
.couponNoneArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    div {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;
        color: #000;
    }
    p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 16.8px */
        letter-spacing: -0.24px;
        color: #9F9CA3;
        margin-top: 10px;
    }
    .move_to_push {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
        padding: 7px 25px;
        border: 1px solid #000;
        border-radius: 16px;
        margin-top: 20px;
        .bell {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            background: url('/assets/icons/coupon_aram_icon.svg') no-repeat center / cover;
        }
    }
}