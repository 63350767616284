.content-divider {
    height: 1px;
    background-color: black;
    margin: 0 20px 0 20px;
}
.content-brand,
.content-zip {
    height: 100%;
    min-height: 100vh;
    /* min-height: -webkit-fill-available; */
}
.content-brand .brand-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 480px;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%), url('../../assets/banner/brand-content-banner-dummy.png');
    background-size: cover;
}
.content-zip .content-zip-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 390px;
    background: #000;
    background-size: cover;
}
.content-brand .brand-banner .back-navigation {
    position: absolute;
    top:20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.content-zip .content-zip-banner .back-navigation {
    position: absolute;
    top:20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.content-brand .brand-banner .back-navigation .back-btn {
    display: inline-flex;
    width: 90px;
    height: 26px;
    background: #000;
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding: 6px 10px;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
}
.content-zip .content-zip-banner .back-navigation .back-btn {
    display: inline-flex;
    width: 90px;
    height: 26px;
    background: #000;
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding-top: 6px;
    padding-left: 10px;
    cursor: pointer;
    align-items: center;
}
.content-brand .brand-banner .back-navigation .back-btn .go-to-list-icon,
.content-zip .content-zip-banner .back-navigation .back-btn .go-to-list-icon {
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
}
.content-zip .content-zip-banner .banner-contents {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    height: 400px;
    background: url('../../assets/banner/zip-contents-dummy-img.png');
    border-radius: 5px;
}
.content-brand .brand-banner .txt-content {
    color: #fff;
    padding: 0 20px;
    height: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(0, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.content-zip .content-zip-banner .banner-contents .txt-content {
    width: 100%;
    color: #fff;
    padding: 0 20px;
    height: 267px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

}

.content-brand .brand-banner .txt-content .type-info,
.content-zip .content-zip-banner .banner-contents .txt-content .type-info {
    color: #FF4B84;
    font-weight: 700;
    font-size: 12px;
    text-align: left;
}
.content-brand .brand-banner .txt-content .category-info {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    text-align: left;
    letter-spacing: -0.02em;
    margin-bottom: 30px;
    /* gray4 */
    color: #9F9CA3;
}
.content-brand .brand-banner .txt-content .title {
    margin-top: 10px;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: left;
    letter-spacing: -0.02em;
}

.content-zip .content-zip-banner .banner-contents .txt-content .title {
    margin-top: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
}

.content-brand .brand-banner .txt-content .sub-title {
    margin-top: 8px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    line-height: 100%;
}

.content-zip .content-zip-banner .banner-contents .txt-content .sub-title {
    color: rgba(255, 255, 255, 0.6);
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
}

.content-zip .content-zip-banner .banner-contents .txt-content .title-contents {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.content-zip .content-zip-banner .banner-contents .txt-content .title-contents .profiles .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: url('../../assets/banner/zip-contents-curator.png');
    background-size: cover;
}
.content-zip .content-zip-banner .banner-contents .txt-content .title-contents .profiles .nick-name {
    font-weight: 400;
    font-size: 10px;
    color: #F7F7FC;
    line-height: 100%;
    letter-spacing: -0.02em;
    margin-bottom: 30px;
    height: 15px;
    margin-top: 5px;
}
.content-brand .main-content .contents-info,
.content-zip .main-content .contents-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}
.content-zip .main-content .contents-info {
    margin-top: 45px;
}
.content-brand .main-content .contents-info {
    margin-top: 20px;
}
.content-brand .main-content .contents-info .dates,
.content-zip .main-content .contents-info .dates {
    font-weight: 400;
    font-size: 12px;
    color: #9F9CA3;
}
.content-brand .main-content .contents-info .see-count,
.content-zip .main-content .contents-info .see-count {
    color: #9F9CA3;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-brand .main-content .contents-info .see-count .see-count-icon,
.content-zip .main-content .contents-info .see-count .see-count-icon {
    display: inline-block;
    width: 14px;
    height: 9px;
    margin-right: 6px;
}
.content-brand .main-content .detail-main-contents {
    /* height: 682px; */
    position: relative;
    margin: 20px;
    /* background: #F7F7FC; */
}
.content-brand .main-content .detail-main-contents .logins {
    position: absolute;
    display: flex;
    flex-direction: column;
    top:800px !important;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, #FFFFFF 100%);
    justify-content: flex-end;
}

.news-contents .main-content .logins {
    position: absolute;
    display: flex;
    flex-direction: column;
    top:600px !important;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, #FFFFFF 100%);
    justify-content: flex-end;
}

.content-zip .main-content .zip-contents-wrapper .logins {
    position: absolute;
    display: flex;
    flex-direction: column;
    top:1600px !important;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, #FFFFFF 100%);
    justify-content: flex-end;
}

.content-zip .main-content .logins,
.news-contents .main-content .logins {
    padding: 0 20px;
}
.content-brand .main-content .detail-main-contents.show-login .logins,
.content-zip .main-content .zip-contents-wrapper.show-login .logins,
.news-contents .main-content.show-login .logins {
    top: 0;
}
.content-brand .main-content .detail-main-contents .logins .login-txt,
.content-zip .main-content .zip-contents-wrapper .logins .login-txt,
.news-contents .main-content .logins .login-txt {
    opacity: 0;
}
.content-brand .main-content .detail-main-contents.show-login .logins .login-txt,
.content-zip .main-content .zip-contents-wrapper.show-login .logins .login-txt,
.news-contents .main-content.show-login .logins .login-txt {
    opacity:  100;
}
.content-brand .main-content .detail-main-contents .logins .login-txt p,
.content-zip .main-content .zip-contents-wrapper .logins .login-txt p,
.news-contents .main-content .logins .login-txt p {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 30px;
    text-align: center;
}
.content-brand .main-content .detail-main-contents .logins .login-txt h1,
.content-zip .main-content .zip-contents-wrapper .logins .login-txt h1,
.news-contents .main-content .logins .login-txt h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 30px;
    text-align: center;
}
.content-brand .main-content .detail-main-contents .logins .login-txt .btn,
.content-zip .main-content .zip-contents-wrapper .logins .login-txt .btn {
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    padding-bottom: 14px;
}
.content-brand .main-content .detail-main-contents .logins .login-txt .message-box,
.content-zip .main-content .zip-contents-wrapper .logins .login-txt .message-box,
.news-contents .main-content .logins .login-txt .message-box {
    /* background: url('../../assets/banner/its-free.png') no-repeat center / cover; */
    height: 28px;
    width: 163px;
    margin: 0 auto;
    margin-top: -58px;
}


.news-contents .main-content .logins .login-txt .btn {
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 14px;
    background: url('../../assets/banner/kakao-login-btn.png') no-repeat center / cover;
    width: 100%;
    height: 50px;
    border-radius: 5px;
}
.content-brand .about-market-wrapper {
    margin: 0 20px 60px 20px;
}
.content-brand .about-market-wrapper .about-market {
    position: relative;
}
.content-brand .about-market-wrapper .about-market .about-market-header {
    height: 50px;
    background: #000;
    color: #fff;
    padding: 15px 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.content-brand .about-market-wrapper .about-market .about-market-header .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-left: 10px;
}
.content-brand .about-market-wrapper .about-market .market-body {
    /* height: 120px; */
    border: 1px solid #E9E7EC;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 22px 30px;
}
.content-brand .about-market-wrapper .about-market .market-body .market-info {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    margin-bottom: 10px;
}
.content-brand .about-market-wrapper .about-market .market-body .market-info .title {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #78737D;
    text-align: left;
    margin-right: 20px;
}
.content-brand .about-market-wrapper .about-market .market-body .market-info .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
}
.content-brand .about-market-wrapper .brand-more {
    position: absolute;
    z-index: 1;
    bottom: -15px;
    left: 27%;
    width: 165px;
    height: 30px;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #000;
    border-radius: 30px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-brand .about-market-wrapper .brand-more .brand-more-txt {
    margin-right: 8px;
}
.content-brand .about-market-wrapper .brand-more .arrow-right-at-brand {
    display: inline-block;
    width: 12px;
    height: 12px;
}
.content-brand .curator-info,
.content-zip .curator-info {
    display: flex;
    margin: 0 20px;
    padding-bottom: 40px;
}
.content-zip .curator-info {
    margin-top: 70px;
}
.content-brand .curator-info .curator-img,
.content-zip .curator-info .curator-img {
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: url('../../assets/banner/brand-contents-profile-pic.png');
    background-size: cover;
    margin-right: 15px;
}
.content-brand .curator-info .curator-txt-info,
.content-zip .curator-info .curator-txt-info {
    width: 100%
}
.content-brand .curator-info .curator-txt-info .nick-name,
.content-zip .curator-info .curator-txt-info .nick-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
}
.content-brand .curator-info .curator-txt-info .nick-name .curator-profile,
.content-zip .curator-info .curator-txt-info .nick-name .curator-profile  {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.content-brand .curator-info .curator-txt-info .nick-name .curator-profile .curator-txt,
.content-zip .curator-info .curator-txt-info .nick-name .curator-profile .curator-txt {
    margin-right: 6px;
}
.content-brand .curator-info .curator-txt-info .nick-name .curator-profile .arrow-right-at-brand,
.content-zip .curator-info .curator-txt-info .nick-name .curator-profile .arrow-right-at-brand {
    display: inline-block;
    width: 12px;
    height: 12px;
}
.content-brand .curator-info .curator-txt-info .curator-message,
.content-zip .curator-info .curator-txt-info .curator-message {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #78737D;
    text-align: left;
    margin-top: 12px;
}
.curator-other-contents {
    padding-bottom: calc(76px + 40px);
}
.curator-other-contents h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin: 60px 0 30px 0;
    font-family: Poppins;
}
.content-brand .curator-other-contents .hangul,
.content-zip .curator-other-contents .hangul {
    font-family: unset;
}
.content-brand .curator-other-contents .contents-info {
    display: flex;
    align-items: flex-start;
    margin: 0 20px 30px 20px;
}
.content-zip .curator-other-contents .contents-info {
    display: flex;
    align-items: flex-start;
    margin: 0 20px 30px 20px;
}
.content-brand .curator-other-contents .contents-info .item,
.content-zip .curator-other-contents .contents-info .item {
    width: 48.36%;
    cursor: pointer;
}

.content-brand .curator-other-contents .contents-info .item:nth-child(1),
.content-zip .curator-other-contents .contents-info .item:nth-child(1) {
    margin-right: 15px;
}
.content-brand .curator-other-contents .contents-info .item .item-img,
.content-zip .curator-other-contents .contents-info .item .item-img {
    width: 100%;
    height: 162px;
    background: url('../../assets/banner/curator-other-content.png');
    background-size: cover;
}
.content-brand .curator-other-contents .contents-info .item .type-info,
.content-zip .curator-other-contents .contents-info .item .type-info {
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #9F9CA3;
    text-align: left;
}
.content-brand .curator-other-contents .contents-info .item .title,
.content-zip .curator-other-contents .contents-info .item .title {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    padding-top: 10px;
}
.content-brand .how-this-contents {
    position: relative;
}
.content-brand .how-this-contents h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 20px;
}
.content-brand .how-this-contents .this-contents-row {
    display: flex;
    height: 188px;
}
.content-brand .how-this-contents .this-contents-row .other-contents {
    width: 50%;
    height: 188px;
    background: url('../../assets/banner/how-this-content-1.png');
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}
.content-brand .how-this-contents .this-contents-row .other-contents:nth-child(2) {
    background: url('../../assets/banner/how-this-content-2.png');
}
.content-brand .how-this-contents .this-contents-row .other-contents .brand-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 14.32px;
    color: #fff;
    margin: 0 0 10px 10px;
    text-align: left;
}
.content-brand .how-this-contents .more-other-contents {
    position: absolute;
    bottom: 40px;
    z-index:3;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}
.content-brand .how-this-contents .more-other-contents span {
    width: 165px;
    height: 30px;
    border: 1px solid #000;
    border-radius: 30px;
    background: #fff;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.content-brand .contents-footer,
.content-zip .contents-footer {
    position: sticky;
    position: -webkit-sticky;
    /* position: fixed; */
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    height: 76px;
    z-index: 3;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-top: 1px solid #E9E7EC;
}

.content-brand .contents-footer .share-wrapper .share-icon,
.content-zip .contents-footer .share-wrapper .share-icon {
    width: 18px;
    display: inline-block;
    background: url('../../assets/icons/share.svg') no-repeat center;
    margin-right: 6px;
}
.content-brand .contents-footer .share-items-panel, 
.content-zip .contents-footer .share-items-panel{
    position: absolute;
    bottom: 80px;
    background: #fff;
    z-index: 3;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.06);
    width: 172px;
    height: 96px;
    padding: 16px;
    border-radius: 5px;
}

.content-brand .contents-footer .share-items-panel .share-item:nth-child(1),
.content-zip .contents-footer .share-items-panel .share-item:nth-child(1) {
    padding-bottom: 10px;
    border-bottom: 1px solid #E9E7EC;
}
.content-brand .contents-footer .share-items-panel .share-item:nth-child(2),
.content-zip .contents-footer .share-items-panel .share-item:nth-child(2) {
    padding-top: 10px;
}
.content-brand .contents-footer .share-items-panel .share-item,
.content-zip .contents-footer .share-items-panel .share-item {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    cursor: pointer;
}
.content-brand .contents-footer .share-items-panel .share-item .kakao-icon,
.content-zip .contents-footer .share-items-panel .share-item .kakao-icon {
    background: url('../../assets/icons/kakao-share-icon.svg') no-repeat center;
    width: 22px;
    height: 20px;
    display: inline-block;
}
.content-brand .contents-footer .share-items-panel .share-item .link-copy-icon,
.content-zip .contents-footer .share-items-panel .share-item .link-copy-icon {
    background: url('../../assets/icons/link-copy-icon.svg') no-repeat center;
    width: 22px;
    height: 20px;
    display: inline-block;
}
.content-brand .contents-footer .share-items-panel .share-item .txt,
.content-zip .contents-footer .share-items-panel .share-item .txt {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-left: 22px;
}

.content-brand .contents-footer .food-log-book-mark-btn,
.content-zip .contents-footer .food-log-book-mark-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 36px;
    border: 1px solid #FF4B84;
    border-radius: 18px;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #FF4B84;
    cursor: pointer;
}
.content-brand .contents-footer .food-log-book-mark-btn .pink-book-mark-icon,
.content-zip .contents-footer .food-log-book-mark-btn .pink-book-mark-icon {
    display: inline-block;
    width: 11.7px;
    height: 14.4px;
    background: url('../../assets/icons/bookmark-pink.svg') no-repeat center;
    margin-right: 7px;
}
.content-brand .contents-footer .food-log-book-mark-btn.active,
.content-zip .contents-footer .food-log-book-mark-btn.active {
    background: #FF4B84;
    color: #fff;
}
.content-brand .contents-footer .food-log-book-mark-btn.active .book-mark-icon,
.content-zip .contents-footer .food-log-book-mark-btn.active .book-mark-icon {
    display: inline-block;
    width: 11.7px;
    height: 14.4px;
    background: url('../../assets/icons/bookmark-white.svg') no-repeat center;
    margin-right: 7px;
}
.content-brand .evaluate-contents {
    margin: 60px 20px 0 20px;
    padding-bottom: 60px;
    /* border-bottom: 1px solid #000; */
}
.content-brand .evaluate-contents h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}
.content-brand .evaluate-contents .sub-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 15px;
}
.content-brand .evaluate-contents .smile-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.content-brand .evaluate-contents .smile-icons .smile-icon {
    width: 80px;
    height: 80px;
    border: 1px solid #D2D0D5;
    border-radius: 80px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    cursor: pointer;
}
.content-brand .evaluate-contents .smile-icons .smile-icon.active {
    background: #000;
    border: 1px solid #000;
    color: #fff;
}
.content-brand .evaluate-contents .smile-icons .smile-icon .bad-icon {
    background: url('../../assets/icons/smile-bad-icon.svg') no-repeat center / cover;
    width: 35px;
    height: 35px;
    display: inline-block;
}
.content-brand .evaluate-contents .smile-icons .smile-icon .normal-icon {
    background: url('../../assets/icons/smile-normal-icon.svg') no-repeat center / cover;
    width: 35px;
    height: 35px;
    display: inline-block;
}
.content-brand .evaluate-contents .smile-icons .smile-icon .good-icon {
    background: url('../../assets/icons/smile-good-icon.svg') no-repeat center / cover;
    width: 35px;
    height: 35px;
    display: inline-block;
}
.content-brand .evaluate-contents .smile-icons .smile-icon.active .bad-icon {
    background: url('../../assets/icons/smile-bad-icon-active.svg') no-repeat center / cover;
    width: 35px;
    height: 35px;
    display: inline-block;
}
.content-brand .evaluate-contents .smile-icons .smile-icon.active .normal-icon {
    background: url('../../assets/icons/smile-normal-icon-active.svg') no-repeat center / cover;
    width: 35px;
    height: 35px;
    display: inline-block;
}
.content-brand .evaluate-contents .smile-icons .smile-icon.active .good-icon {
    background: url('../../assets/icons/smile-good-icon-active.svg') no-repeat center / cover;
    width: 35px;
    height: 35px;
    display: inline-block;
}
.content-brand .evaluate-contents .smile-icons .smile-icon .txt {
    color: #D2D0D5;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 6px;
}
.content-brand .evaluate-contents .take-comments {
    margin-top: 40px;
    font-weight: 500;
    font-size: 12px;
    color: #78737D;
}

.content-brand .evaluate-contents .take-comments .go-to-comments {
    margin-top: 10px;
}

.content-brand .evaluate-contents .take-comments .go-to-comment-btn {
    display: inline-block;
    width: 165px;
    height: 30px;
    border: 1px solid #000;
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    padding-top: 4px;
    cursor: pointer;
    color: #000;
}

.content-brand .go-to-list-wrapper,
.content-zip .go-to-list-wrapper {
    margin-bottom: 115px;
}
.content-brand .go-to-list-wrapper .go-to-list-btn,
.content-zip .go-to-list-wrapper .go-to-list-btn {
    display: inline-block;
    width: 165px;
    height: 30px;
    padding-top: 5px;
    border: 1px solid #000;
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}
.content-zip .main-content .zip-contents-wrapper {
    position: relative;
}
.content-zip .main-content .pre-message {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.02em;
    margin: 40px 20px 0 20px;
    text-align: left;
}
.content-zip .main-content .pick-reason h2 {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.02em;
    margin: 40px 20px 0 20px;
    text-align: left;
}
.content-zip .main-content .pick-reason figure {
    margin: 20px 20px 100px 20px;
    background: #F7F7FC;
    text-align: left;
}
.content-zip .main-content .pick-reason figure strong {
    padding-left: 20px;
}
.content-zip .main-content .pick-reason figure ul {
    list-style: url('../../assets/icons/checkbox.svg');
    padding-left: 40px !important;
    padding-top: 10px;
    text-align: left;
}
.content-zip .main-content .pick-reason figure ul li {
    padding-left: 5px;
    padding-right: 15px;
    line-height: 140%;
}
.content-zip .main-content .pick-reason h1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;
}
.content-zip .main-content .pick-reason .pick-reason-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.content-zip .main-content .pick-reason .pick-reason-content .icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
.content-zip .main-content .pick-reason .pick-reason-content .txt {
    font-weight: 400;
    font-size: 16px;
}
.content-zip .main-content .zip-content-card-list .zip-content-card {
    /* margin-left: 20px; */
    margin-bottom: 100px;
}
.content-zip .main-content .zip-content-card-list .zip-content-card h1 {
    /* margin-left: 20px; */
    text-align: left;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: -0.02em;
    /* padding-bottom: 20px; */
}
.content-zip .main-content .zip-content-card-list .zip-content-card .points {
    margin-left: 20px;
}
.content-zip .main-content .zip-content-card-list .zip-content-card .points .zip-point {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E9E7EC;
}
.content-zip .main-content .zip-content-card-list .zip-content-card .points .zip-point .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    margin-right: 15px;
}
.content-zip .main-content .zip-content-card-list .zip-content-card .points .zip-point .contents {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: left;
}
.content-zip .main-content .zip-content-card-list .zip-content-card .zip-card-img-slider {
    display: flex;
    overflow-x: scroll;
}
.content-zip .main-content .zip-content-card-list .zip-content-card .zip-card-img-slider .zip-card-img {
    min-width: 340px;
    height: 340px;
    /* background: url('../../assets/banner/zip-content-card-dummy.png');
    background-size: cover; */
}
.content-zip .main-content .zip-content-card-list .zip-content-card .market-info {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}
.content-zip .main-content .zip-content-card-list .zip-content-card .market-info .info-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-left: 1px solid #78737D;
    padding-top: 5px;
}
.content-zip .main-content .zip-content-card-list .zip-content-card .market-info .info-item .title {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    /* gray5 */
    color: #78737D;
    margin: 0 20px;
}
.content-zip .main-content .zip-content-card-list .zip-content-card .market-info .info-item .contents {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: inline-block;
    text-align: left;
}
.content-zip .main-content .more-brand h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
}
.content-zip .main-content .more-brand .brand-contents {
    margin-top: 20px;
    background: #F7F7FC;
    padding: 30px 38px;
}
.content-zip .main-content .more-brand .brand-contents .brand-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.content-zip .main-content .more-brand .brand-contents .brand-row:nth-last-child(1) {
    margin-bottom: 0;
}
.content-zip .main-content .more-brand .brand-contents .brand-row .brand-item {
    width: 80px;
    height: 107px;
    margin-right: 30px;
}
.content-zip .main-content .more-brand .brand-contents .brand-row .brand-item:nth-child(3) {
    margin-right: 0;
}
.content-zip .main-content .more-brand .brand-contents .brand-row .brand-item .brand-img {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    background: url('../../assets/banner/zip-content-brand-dummy.png');
    background-size: cover;
}
.content-zip .main-content .more-brand .brand-contents .brand-row .brand-item .brand-txt {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
}

.content-brand .main-content .detail-main-contents.show-login {
    height: 1350px;
    overflow: hidden;
}

.news-contents .main-content.show-login {
    height: 1200px;
    overflow: hidden;
    position: relative;
}
.content-zip .main-content .zip-contents-wrapper.show-login {
    height: 2100px;
    overflow: hidden;
}

.content-brand .main-content .detail-main-contents.show-login .logins,
.content-zip .main-content .zip-contents-wrapper.show-login .logins,
.news-contents .main-content.show-login .logins {
    animation: showLogin 1s ease-in-out;
}
.content-brand .main-content .detail-main-contents.show-login .logins,
.content-zip .main-content .zip-contents-wrapper.show-login .logins .login-txt,
.news-contents .main-content.show-login .logins .login-txt {
    animation: showLoginBtns 1s ease-in-out;
}


@keyframes showLogin {
    from {
        top: 600px;
    }
    to {
        top: 0;
    }
}

@keyframes showLoginBtns {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}
