.cardView {
  padding-top: 40px;
  background: #fff;
  .cardTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      padding-left: 20px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 18px */
      letter-spacing: -0.36px;
      margin: 0 0 8px 0;
    }
  }
  .cardDiscription {
    padding-left: 20px;
    margin-bottom: 16px;
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
      letter-spacing: -0.28px;
      margin: 0;
      text-align: left;
    }
  }
}
