.community {
    position: relative;
    min-height: 100vh;
}
.community .community-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #E9E7EC;
}
.community .community-title-wrapper .community-title {
    font-family: Poppins;
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
    margin-top: 40px;
    margin-left: 20px;
}
.community .community-title-wrapper .community-sub-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    margin-top: 10px;
    margin-left: 20px;
}
.community .community-title-wrapper .category-wrapper {
    margin-left: 20px;
    margin-bottom: 20px;
}
.community .community-title-wrapper .category-wrapper .txt {
    cursor: 'pointer';
} 
.community .community-title-wrapper .category-wrapper .community-category-selector {
    padding: 8px 21.7px 8px 24.5px;
    border: 1px solid #000;
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
}
.community .community-title-wrapper .category-wrapper .community-category-selector.has-val {
    background: #000;
    color: #fff;
}
.community .community-title-wrapper .category-wrapper .community-category-selector .icon {
    display: inline-block;
    width: 10px;
    height: 5px;
    margin-left: 6.2px;
}
.community .user-content-wrapper {
    padding-top: 20px;
    border-bottom: 1px solid #F7F7FC;
    cursor:pointer;
}
.community .user-content-wrapper .content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}
.community .user-content-wrapper .content-top .writer {
    display: flex;
    align-items: center;
}
.community .user-content-wrapper .content-top .writer .profile {
    width: 26px;
    height: 26px;
    border-radius: 30px;
    margin-right: 5px;
    background-size: cover;
    background-position: center;
}
.community .user-content-wrapper .content-top .writer .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
}
.community .user-content-wrapper .content-top .writer .delete-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #9F9CA3;

}
.community .user-content-wrapper .content-top .content-category {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #9F9CA3;
}
.community .user-content-wrapper .content-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 20px 0 20px;
}
.community .user-content-wrapper .content-bottom .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
}
.community .user-content-wrapper .content-bottom .bottom-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 12px 0 22px 0;
    color: #9F9CA3;
}
.community .user-content-wrapper .content-bottom .bottom-info .additional-content-info {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
}
.community .user-content-wrapper .content-bottom .bottom-info .feedback-info {
    display: flex;
}
.community .user-content-wrapper .content-bottom .bottom-info .feedback-info .like-wrapper {
    width: 42px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.community .user-content-wrapper .content-bottom .bottom-info .feedback-info .comment-wrapper {
    width: 42px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
}
.community .user-content-wrapper .content-bottom .bottom-info .feedback-info .community-like-icon {
    width: 18px;
    height: 17px;
    display: inline-block;
    background-repeat: no-repeat;
    margin-right: 6px;
}
.community .user-content-wrapper .content-bottom .bottom-info .feedback-info .like-cnt {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
}
.community .user-content-wrapper .content-bottom .bottom-info .feedback-info .comment-cnt {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
}
.community .user-content-wrapper .content-bottom .bottom-info .feedback-info .community-comment-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    margin-right: 6px;
}

.community .user-content-wrapper .content-image-wrapper {
    position: relative;
    display: flex;
    overflow: auto;
    margin-top: 10px;
}
.community .user-content-wrapper .content-image-wrapper .arrow {
    position: absolute;
    display: inline-flex;
    width: 20px;
    height: 20px;
    background: rgba(0,0,0,0.4);
    border-radius: 20px;
    z-index: 3;
    top: 45%;
    justify-content: center;
    align-items: center;
}
.community .user-content-wrapper .content-image-wrapper .arrow.arrow-left-wrapper {
    left: 10px;
}
.community .user-content-wrapper .content-image-wrapper .arrow.arrow-left-wrapper .icon {
    display: inline-block;
    width: 10px;
    height: 10px;
}
.community .user-content-wrapper .content-image-wrapper .arrow.arrow-right-wrapper {
    right: 10px;
}
.community .user-content-wrapper .content-image-wrapper .arrow.arrow-right-wrapper .icon {
    display: inline-block;
    width: 10px;
    height: 10px;
}
.community .user-content-wrapper .content-image-wrapper::-webkit-scrollbar {
    height: 5px;
}
.community .user-content-wrapper .content-image-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    border-radius: 20px;
	background-color: #FFF;
}

.community .user-content-wrapper .content-image-wrapper::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #E9E7EC;
    border-radius: 20px;
}

.community .user-content-wrapper .content-image-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #E9E7EC;
}
.community .user-content-wrapper .content-image-wrapper .card-item {
    position: relative;
    min-width: 126px;
    width: 126px;
    height: 147px;
    background: url('../../assets/banner/community-list-content.png') no-repeat center / cover;
    margin-left: 2px;
    color: #fff;
    /* margin: 0 10px; */
}
.community .user-content-wrapper .content-image-wrapper .card-item .blur-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}
.community .user-content-wrapper .content-image-wrapper .card-item:nth-child(1) {
    margin-left: 20px;
}
.community .add-community-content-wrapper {
    position: fixed;
    bottom: 105px;
    right: 30px;
    /* left: 57%; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #000;
    border-radius: 50px;
    cursor: pointer;
}
.community .add-community-content-wrapper .main-pink-cross-icon {
    display: inline-block;
    width:  24px;
    height: 24px;
}
.community .no-community-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 140px);
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #78737D;
}


/* community 상세 */
.community-detail-contents {
    min-height: 100vh;
    padding-bottom: 76px;
}
.community-detail-contents .community-detail-header,
.community-contents-preview .community-detail-header {
    height: 468px;
    position: relative;
}
.community-detail-contents .community-detail-header .community-contents-header,
.community-contents-preview .community-detail-header .community-contents-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    z-index: 3;
    padding-top:env(safe-area-inset-top);
    height: calc(60px + env(safe-area-inset-top));
}
.community-detail-contents .community-detail-header .community-contents-header.android,
.community-contents-preview .community-detail-header .community-contents-header.android {
    padding-top:var(--status-bar-height);
    height: calc(60px + var(--status-bar-height));
}
.community-detail-contents .community-detail-header .community-contents-header .content-back,
.community-detail-contents .community-detail-header .community-contents-header .options {
    cursor: pointer;
}
.community-detail-contents .community-detail-header .community-contents-header .content-back .arrow-left-white,
.community-detail-contents .community-detail-header .community-contents-header .options .option-white-threedot-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
}


.community-detail-imgs .carousel * {
    height: 465px;
}
.community-detail-contents .community-detail-header .community-detail-imgs,
.community-contents-preview .community-detail-header .community-detail-imgs {
    /* display: flex; */
    overflow: auto;
}
.community-detail-contents .community-detail-header .community-detail-imgs > div > div button,
.community-contents-preview .community-detail-header .community-detail-imgs > div > div button {
    background: rgba(0, 0, 0, 0.25) !important;
    border-radius: 24px;
    height: 24px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: absolute !important;
    top: 50%;
}
.community-detail-contents .community-detail-header .community-detail-imgs > div > div button:nth-child(1) {
    margin-left: 12px;
}
.community-detail-contents .community-detail-header .community-detail-imgs > div > div > div > ul li > div > img,
.community-contents-preview .community-detail-header .community-detail-imgs > div > div > div > ul li > div > img {
    object-fit: cover;
    object-position: center;
}

.community-detail-contents .community-detail-header .community-detail-imgs > div > div button > img {
    width: 50% !important;
}
.community-detail-contents .community-detail-header .community-detail-imgs::-webkit-scrollbar,
.community-contents-preview .community-detail-header .community-detail-imgs::-webkit-scrollbar {
    display: none;
}

.community-detail-contents .community-detail-header .community-detail-imgs .img-item,
.community-contents-preview .community-detail-header .community-detail-imgs .img-item
 {
    background: url('../../assets/banner/community-detail-dummy.png') no-repeat center / cover;
    min-width: 100%;
    height: 468px;
}

.community-detail-contents .community-detail-header .img-counter,
.community-contents-preview .community-detail-header .img-counter {
    position: absolute;
    font-size: 12px;
    bottom: 15px;
    right: 22px !important;
    width: 40px;
    height: 24px;
    border-radius: 11px;
    background: #000;
    color: #fff;
}

.carousel .carousel-status {
    position: absolute;
    top: inherit !important;
    bottom: 15px;
    right: 22px !important;
    width: 44px;
    height: 20px;
    border-radius: 12px;
    background-color: rgb(0 0 0 / 0.3);
    color: #fff;
    line-height: 20px;
    height: 24px;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    text-shadow: none !important;
    font-size: 12px !important;
    text-align: center;
    letter-spacing: -0.02em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.community-detail-contents .contents-info,
.community-contents-preview .contents-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}
.community-detail-contents .contents-info .writer,
.community-contents-preview .contents-info .writer {
    display: flex;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
}
.community-detail-contents .contents-info  .writer .profile,
.community-contents-preview .contents-info .writer .profile {
    width: 26px;
    height: 26px;
    border-radius: 30px;
    background: #000;
    margin-right: 5px;
}
.community-detail-contents .contents-info  .writer .name,
.community-contents-preview .contents-info .writer .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
}
.community-detail-contents .contents-info  .writer .delete-name,
.community-contents-preview .contents-info .writer .delete-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #9F9CA3;
}
.community-detail-contents .contents-info  .content-category,
.community-contents-preview .contents-info .content-category {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #9F9CA3;
}
.community-detail-contents .contents-title,
.community-contents-preview .contents-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    margin-bottom: 25px;
}
.community-detail-contents .contents-title .title,
.community-contents-preview .contents-title .title {
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}
.community-detail-contents .contents-title .contents-sub-info,
.community-contents-preview .contents-title .contents-sub-info {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #9F9CA3;
    margin-top: 12px;
}
.community-detail-contents .detail-contents,
.community-contents-preview .detail-contents {
    text-align: left;
    padding: 0 20px;
    word-break: break-all;
}
.community-detail-contents .brands,
.community-contents-preview .brands {
    margin: 15px 0 30px 0;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
}
.community-detail-contents .brands {
    cursor: default; /* till brands page open*/
}
.community-detail-contents .brands-divider,
.community-contents-preview .brands-divider {
    border-top: 1px solid #E9E7EC;
    height: 1px;
    margin: 40px 20px 0 20px;
    background: #F7F7FC;
}
.community-detail-contents .brands .brands-info .brands-logo,
.community-contents-preview .brands .brands-info .brands-logo {
    min-width: 40px;
    height: 40px;
    border-radius: 40px;
    background: url('../../assets/banner/community-brand-dummy.png') no-repeat center / cover;
}
.community-detail-contents .brands .brands-info,
.community-contents-preview .brands .brands-info {
    display: flex;
}
.community-detail-contents .brands .brands-info .non-verified-brand-txt,
.community-contents-preview .brands .brands-info .non-verified-brand-txt {
    margin-left: 10px;
    display: flex;
    align-items: center;
}
.community-detail-contents .brands .brands-info .brand-txt,
.community-contents-preview .brands .brands-info .brand-txt {
    margin-left: 10px;
}
.community-detail-contents .brands .brands-info .brand-txt .name,
.community-contents-preview .brands .brands-info .brand-txt .name {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    margin-bottom: 4px;
}
.community-detail-contents .brands .brands-info .brand-txt .category,
.community-contents-preview .brands .brands-info .brand-txt .category {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: #78737D;
}
.community-detail-contents .brands .move-to-brands,
.community-contents-preview .brands .move-to-brands {
    display: flex;
    align-items: center;
}
.community-detail-contents .brands .move-to-brands .icon,
.community-contents-preview .brands .move-to-brands .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.community-detail-contents .divider,
.community-contents-preview .divider {
    border-top: 1px solid #E9E7EC;
    height: 6px;
    background: #F7F7FC;
}
.community-contents-preview .divider.preview-bottom {
    margin-bottom: 110px;
}


.community-detail-contents .community-footer {
    position: fixed;
    bottom: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #E9E7EC;
    padding: 20px;
    height: 76px;
    width: 375px;
}
.community-detail-contents .community-footer .like-share-comment {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.community-detail-contents .community-footer .like-share-comment .like-wrapper span {
    display: inline-block;
}
.community-detail-contents .community-footer .like-share-comment .like-wrapper {
    margin-left: 11px;
}
.community-detail-contents .community-footer .like-share-comment .like-wrapper .like-icon {
    width: 18px;
    background: url('../../assets/icons/like-btn.svg') no-repeat center;
    margin-right: 6px;
}
.community-detail-contents .community-footer .like-share-comment .like-wrapper .reaction-best-large-icon.active {
    width: 18px;
    margin-right: 6px;
}
.community-detail-contents .community-footer .like-share-comment .comment-wrapper {
    margin-right: 21px;
}
.community-detail-contents .community-footer .like-share-comment .comment-wrapper span {
    display: inline-block;
}
.community-detail-contents .community-footer .like-share-comment .comment-wrapper .comment-icon {
    width: 18px;
    background: url('../../assets/icons/comment-btn.svg') no-repeat center;
    margin-right: 6px;
}
/* editor */
.community-edit-contents {
    min-height: 100vh;
}

.community-edit-contents .add-img-wrapper {
    padding: 20px 20px 0 20px;    
    margin-bottom: 20px;
    display: flex;
    overflow: auto;
}
.community-edit-contents .add-img-wrapper .uploaded-image {
    position: relative;
    min-width: 100px;
    height: 125px;
    background: #9F9CA3;
    margin-left: 5px;
}
.community-edit-contents .add-img-wrapper .uploaded-image .cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.4);
}
.community-edit-contents .add-img-wrapper .uploaded-image .delete-btn {
    position: absolute;
    display: flex;
    top: 4px;
    right: 4px;
    width: 12px;
    height: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #000;
    cursor: pointer;
}
.community-edit-contents .add-img-wrapper .uploaded-image .delete-btn .icon {
    display: inline-block;
    width: 6px;
    height: 6px;
}
.community-edit-contents .add-img-wrapper .picture-wrapper {
    position: relative;
    min-width: 100px;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
}
.community-edit-contents .add-img-wrapper .picture-wrapper .cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.4);
}
.community-edit-contents .add-img-wrapper .picture-wrapper.picture-add {
    border: 1px solid #E9E7EC;
}
.community-edit-contents .add-img-wrapper .picture-wrapper .icon {
    display: block;
    width: 20px;
    height: 20px;
    margin-bottom: 8px;
}
.community-edit-contents .community-title {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
    margin-bottom: 10px;
}
.community-edit-contents .community-title textarea {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.02em;
    width: 100%;
    resize: none;
    outline: none;
}
.community-edit-contents .community-title textarea::placeholder {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color:#D2D0D5;
}
.community-edit-contents .contents-editor {
    padding: 0 20px;
}
.community-edit-contents .contents-editor textarea {
    width: 100%;
    height: 190px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    resize: none;    
}

.community-edit-contents .contents-editor textarea::placeholder {    
    color:#D2D0D5;
}

.community-edit-contents .contents-editor textarea:focus {    
    outline:none;
}

.community-edit-contents .contents-limit {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    text-align: right;
    letter-spacing: -0.02em;
    color: #D2D0D5;
}
.community-edit-contents .contents-limit .txt {
    margin-right: 5px;
}
.community-edit-contents .contents-limit .desc-cnt.active {
    color: #78737D;
}

.community-edit-contents .contents-warning {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */

    letter-spacing: -0.02em;

    /* gray4 */

    color: #9F9CA3;
    text-align: left;
    padding: 0 20px 10px 20px;
    margin: 20px 0 0 0;
    border-bottom: 1px solid #F7F7FC;
}
.community-edit-contents .contents-options .option-item {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F7F7FC;
    padding: 0 20px;
    cursor: pointer;
}
.community-edit-contents .contents-options .option-item .name {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #000000;
}
.community-edit-contents .contents-options .option-item .name .mendatory {
    color: #FF3E48;
}
.community-edit-contents .contents-options .option-item .arrow-wrapper {
    display: flex;
    align-items: center;
}
.community-edit-contents .contents-options .option-item .arrow-wrapper .selection {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: -0.04em;
    color: #78737D;
}
.community-edit-contents .contents-options .option-item .arrow-wrapper .icon {
    display: block;
    width: 7px;
    height: 14px;
    margin-left: 17px;
}
.community-edit-contents .contents-btn-wrapper {
    padding: 40px 20px 20px 20px;
}
.community-edit-contents .contents-btn-wrapper.preview {
    position: fixed;
    bottom: 0;
    width: 375px;
}
.community-edit-contents .contents-btn-wrapper .btn {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.community-edit-contents .contents-btn-wrapper .btn.next {
    border: 1px solid #000;
    border-radius: 5px;
}
.community-edit-contents .contents-btn-wrapper .btn.complete {
    background: #000;
    color: #fff;
    border-radius: 5px;
}
.community-edit-contents .contents-btn-wrapper .btn.next.disabled {
    background: #E9E7EC;
    color: #fff;
    border: 1px solid #E9E7EC;
}
.community-edit-contents .contents-btn-wrapper .btn.temp-save {
    color: #9F9CA3;
}