.container {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 140px 0 24px 0;
}

.title {
    color: var(--W, #FFF);    
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px; /* 30px */
    letter-spacing: -0.6px;
}

.address {
    color: rgba(255, 255, 255, 0.70);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    margin: 16px 0;
}

.subtitle {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    margin: 0 20px 16px 20px;
    white-space: pre-wrap;
}

.carousel {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  .images {
    display: flex;
    gap: 4px;
    padding: 0 20px;

    .brandItem {
        flex: 0 0 160px;  // 기본 너비 160px로 설정

        &:first-child {
            flex: 0 0 220px;  // 첫 번째 아이템만 220px로 설정
        }
      
      img {
        width: 100%;
        height: 160px;
        object-fit: cover;
      }
    }
  }
}

.bookmarkBtn {
    width: 40px;
    height: 44px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 10px;
}

.btnWrapper {
    display: flex;
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
    margin-top: 16px;
    gap: 4px;
}
.allImageShortcut {
    width: 40px;
    height: 44px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 10px;
}
.allImageBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 44px;
    .allImageBtnText {
      color: var(--W, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
    }
  }
  .benefitBtn {
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    border-radius: 4px;
    background: rgba(18, 71, 120, 0.70);
    backdrop-filter: blur(5px);
    color: var(--W, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
  }

//   editor tip
.editorTip, .issue {
    padding: 0 20px 60px 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h3 {
        color: var(--DarkBlue, #124778);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 12px */
        letter-spacing: -0.24px;
        margin-bottom: 10px;
        text-align: left;
    }
    h2 {
        color: var(--B, #000);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
        letter-spacing: -0.48px;
        margin-bottom: 16px;
        text-align: left;
    }
    ul {
        width: 100%;
        margin-bottom: 16px;
        li {
            color: var(--G6, #433E48);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
            letter-spacing: -0.32px;
            padding: 12px 0;
            border-bottom: 1px solid #E9E7EC;
            width: 100%;
            text-align: left;
            &:last-child {
                border-bottom: none;
            }
            span {
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
    .imageWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        width: 100%;
        
        img {
            width: 100%;
        }
    }
}
.issue {
    padding: 0 20px 60px 20px;
}
.shortcutBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid var(--G2, #e9e7ec);
  background: #fff;
  height: 40px;
  padding: 12px 12px 12px 16px;
  gap: 8px;
  width: 130px;
  font-size: 14px;
}
.issueTitle {
    white-space: pre-wrap;
}
.issueContentWrapper {
    color: var(--G6, #433E48);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    margin-bottom: 16px;
}
.issueDivider {
    background: var(--G1, #F7F7FC);
    width: 100%;
    height: 8px;
}
.visitInfoWrapper {
    background: #EDEDED;
    padding: 30px 20px;
    h3 {
        color: var(--black, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
        margin-bottom: 16px;
        text-align: left;
    }
    .benefitCoupon {
        border-radius: 4px;
        background: #FFF;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .leftSide {
            display: flex;
            align-items: center;
            gap: 12px;
            img {
                width: 60px;
                height: 60px;
                border-radius: 60px;
                object-fit: cover;
            }
            .infoTxt {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                strong {
                    color: var(--color-black, #000);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%; /* 16px */
                    letter-spacing: -0.32px;
                }
                span {
                    color: var(--color-black, #000);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%; /* 14px */
                    letter-spacing: -0.28px;
                }
            }
        }
    }
}
.shareBtns {
    display: flex;
    gap: 4px;
    margin-top: 4px;
    button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        background: var(--W, #FFF);
        padding: 17px 16px;
        flex: 1;
        height: 58px;
        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
        }
    }
}