.joinsField {
    padding-top: 40px;
}
.error {
    color: #FF3E48;
    text-align: left;
    padding: 0 20px;
    margin-top: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    & > span {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 8px;
    }
}
.passwordReset {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #000000;
        padding-right: 20px;
        cursor: pointer;
    }
}

.password {
    margin-top: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    padding: 0 20px;
    color: #78737D;
    text-align: left;
}

.passwordError {
    margin-top: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    padding: 0 20px;
    color: #FF3E48;
    text-align: left;
}

.hide {
    display: none !important;
}

.btn_wrapper {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
}