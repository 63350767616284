.header {
  margin-top: 20px;
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .calander_icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
      background: url('/assets/icons/tdesign_calendar_bold.svg') no-repeat
        center / cover;
    }
    strong {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 18px */
      letter-spacing: -0.36px;
    }
  }
  .desc {
    color: var(--color-black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.28px;
    margin-top: 9px;
    text-align: left;
  }
}
.info_list {
  padding: 12px;
  background: var(--color-gray-1, #f7f7fc);
  margin-top: 8px;
  div {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
    text-align: left;
    margin-top: 2px;
  }
  span {
    text-decoration: underline;
  }
}
.modal_bottom {
  .row {
    padding: 18px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      display: flex;
      align-items: center;
    }
    .naver_icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('/assets/icons/icon_naver.svg') no-repeat center / cover;
    }
    .call_icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('/assets/icons/modal-call.svg') no-repeat center / cover;
    }
    .txt {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
      margin-left: 10px;
    }
    .arrow_right {
      display: inline-block;
      width: 5.6px;
      height: 11.2px;
      background: url('/assets/icons/arrow-right-my.svg') no-repeat center /
        cover;
    }
  }
}
