.aboutBannerWrapper {
    min-height: 430px;
    background: #000;
}
.aboutBannerImg{
    height: 300px;
    background: linear-gradient(370deg, #000000 0%, rgba(0, 0, 0, 0) 60%), url('/assets/imgs/about-banner-dummy.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-end;
}
.titles {
    width: 100%;
    padding-left: 20px;
    margin-bottom: 20px;
    text-align: left;
    color: #fff;
}
.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 20px;
}
.subTitle {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.txtContent {
    margin: 16px 20px 30px 20px;
    color: #fff;
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    span {
        font-size: 14px;
        font-weight: lighter;
    }
    strong {
        font-weight: 600;
        text-decoration: underline;
    }
}
