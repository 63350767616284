.magazineItemWrapper {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
    cursor: pointer;
}
.brands {
    background: url('/assets/imgs/magazine-item-dummy.png');
    background-size: cover;
}
.zip {
    background: url('/assets/banner//about-zip-background.png');
    background-size: cover;
    .txt-wrapper {
        margin-top: 300px;
    }
}
.news {
    background: url('/assets/banner//about-news-background.png');
    background-size: cover;
}
.txtWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    margin-bottom: 30px;
    margin-top: 264px;
}
.title {
    width: 200px;
    font-family: Poppins;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
}
.subtitle {
    margin-top: 10px;
}