
.recommendListWrapper {
    background-color: #F7F7FC;
    width: 100%;
    padding: 30px 0 20px 0;
    margin: 60px 0;
}
.titles {
    margin: 0 20px 20px 20px;
    h1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: left;
    }
}
.subTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    margin-top: 5px;
    text-align: left;
    color: #78737D;
}
.recommendCards {
    display: flex;
    overflow: auto;
}
.cardItem {
    min-width: 180px;
    width: 180px;
    height: 180px;
    border-radius: 5px;
    /* margin: 0 10px; */
    &:nth-child(1) {
        margin-left: 20px;
    }
}
