.wrapper {
    padding: 20px 0 50px 5px;
    background: #000;
}
.item {
    min-width: 310px;
    height: 478px;
    margin-left: 15px;
    cursor: pointer;
}
.img {
    height: 370px;
    border-radius: 5px;
    background: url('/assets/banner//magazine_item.jpeg') no-repeat center / cover;
}
.title {
    margin-top: 15px;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-align: left;
}
.desc {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-align: left;
}
.indicator_wrapper {
    display: flex;
}
.indicator {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #E9E7EC;
    cursor: pointer;
    &.active {
        background-color: #FF4B84;
        height: 3px;
    }
}
