.modalWrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);   
    z-index: 11;
}
.modalContents {
    position: absolute;
    /* bottom: -500px; */
    padding: 0 20px;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;
    padding: 23px;
    width: 375px;
    height: 415px;
    z-index:12;
    &.active {
        animation: slideUp 0.5s ease;
    }
    &.close {
        transform: translateY(500px);
    }
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        .headerIcon {
            display: inline-block;
            background: url('/assets/icons/brand_address_selector_down_icon.svg') no-repeat center / cover;
            width: 16px;
            height: 8px;
            cursor: pointer;
        }
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
.modalBody {
    margin-top: 10px;
    margin-bottom: 20px;
    li {
        font-weight: 500;
        line-height: 100%; /* 16px */
        letter-spacing: -0.32px;
        font-size: 16px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #F7F7FC;
        cursor: pointer;
        color: #9F9CA3;
        &.active {
            color: #000;
        }
    }
}


@keyframes slideUp {
    from {
        /* 하위 120px 에서 시작합니다. */
        transform: translateY(500px);
    }
    to {
        /* 원 위치로 이동합니다. */
        transform: translateY(0);
    }
}
