.profileArea {
    padding: 20px;
    .userInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .user {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .profileImg {
                width: 60px;
                height: 60px;
                border-radius: 60px;
            }
            .name {
                margin-left: 12px;
                .userTypeName {
                    color: #FF4B84;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%; /* 12px */
                    letter-spacing: -0.24px;
                    text-align: left;
                    margin-bottom: 6px;
                }
                .nameFields {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%; /* 20px */
                    letter-spacing: -0.4px;
                }
                .prefix {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    letter-spacing: -0.4px;
                }
            }
        }
        .snsArea {
            display: flex;
            justify-content: center;
            align-items: center;
            .sns {
                width: 24px;
                height: 24px;
                display: inline-block;
                margin-right: 8px;
                cursor: pointer;
                &:nth-last-child(1) {
                    margin-right: 0;
                }
                &.instagram {
                    background: url('/assets/icons/instagram-solid.svg') no-repeat center / contain;
                }
                &.blog {
                    background: url('/assets/icons/blog-solid.svg') no-repeat center / contain;
                }
                &.youtube {
                    background: url('/assets/icons/youtube-solid.svg') no-repeat center / contain;
                }
            }
        }
    }
    .creatorDesc {
        color: #78737D;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        letter-spacing: -0.28px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 12px;
        margin-bottom: 20px;
        text-align: left;
    }
    .shortCutArea {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #E9E7EC;
        border-radius: 5px;
        .shortCutItem {
            flex: 1;
            height: 38px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 16px 0;
            border-right: 1px solid #E9E7EC;
            &:nth-last-child(1) {
                border-right: 0;
            }
            cursor: pointer;
            .name {
                position: relative;
                color: #78737D;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 14px */
                letter-spacing: -0.28px;
                margin-bottom: 8px;
                &.hasnew {
                    &::after {
                        content: ' ';
                        display: block;
                        width: 4px;
                        height: 4px;
                        border-radius: 4px;
                        background: #FF4B84;
                        position: absolute;
                        top: 0;
                        right: -8px;
                    }
                }
            }
            .cnt {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 14px */
                letter-spacing: -0.28px;
            }
        }
    }
    .certBanner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px;
        background: rgba(255, 75, 132, 0.10);
        border-radius: 4px;
        margin-top: 12px;
        .certTxt {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 114.286% */
            letter-spacing: -0.28px;
        }
        .certArrow {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url('/assets/icons/cert_banner_arrow.svg') no-repeat center / cover;
        }
    }
}