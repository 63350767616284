.my-contents-wrapper {
    min-height: 100vh;
}
.my-contents-wrapper .back-navigation {
    margin: 20px 20px 40px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
}
.my-contents-wrapper .back-navigation .back-btn {
    display: inline-flex;
    width: 90px;
    height: 26px;
    background: #000;
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 6px 10px;
}
.my-contents-wrapper .back-navigation .back-btn .go-to-list-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
}
.my-contents-wrapper .my-contents-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    margin: 0 20px 20px 20px;;
}
.my-contents-wrapper .my-contents-title .cnt {
    color: #9F9CA3;
    font-weight: 400;
    font-size: 20px;
    margin-left: 10px;
}

.my-contents-wrapper .user-content-wrapper {
    padding-top: 20px;
    border-bottom: 1px solid #F7F7FC;
    cursor: pointer;
}
.my-contents-wrapper .user-content-wrapper .content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
}
.my-contents-wrapper .user-content-wrapper .content-top .writer {
    display: flex;
    align-items: center;
}
.my-contents-wrapper .user-content-wrapper .content-top .writer .profile {
    width: 26px;
    height: 26px;
    border-radius: 30px;
    background: #000;
    margin-right: 5px;
    background-position: center;
    background-size: cover;
}
.my-contents-wrapper .user-content-wrapper .content-top .writer .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
}
.my-contents-wrapper .user-content-wrapper .content-top .content-category {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #9F9CA3;
}
.my-contents-wrapper .user-content-wrapper .content-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 20px 0 20px;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .bottom-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 12px 0 22px 0;
    color: #9F9CA3;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .bottom-info .additional-content-info {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .bottom-info .feedback-info {
    display: flex;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .bottom-info .feedback-info .like-wrapper {
    width: 42px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .bottom-info .feedback-info .comment-wrapper {
    width: 42px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .bottom-info .feedback-info .community-like-icon {
    width: 18px;
    height: 17px;
    display: inline-block;
    background-repeat: no-repeat;
    margin-right: 6px;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .bottom-info .feedback-info .like-cnt {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .bottom-info .feedback-info .comment-cnt {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
}
.my-contents-wrapper .user-content-wrapper .content-bottom .bottom-info .feedback-info .community-comment-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    margin-right: 6px;
}

.my-contents-wrapper .user-content-wrapper .content-image-wrapper {
    display: flex;
    overflow: auto;
    margin-top: 10px;
}
.my-contents-wrapper .user-content-wrapper .content-image-wrapper::-webkit-scrollbar {
    height: 5px;
}
.my-contents-wrapper .user-content-wrapper .content-image-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
    border-radius: 20px;
	background-color: #FFF;
}

.my-contents-wrapper .user-content-wrapper .content-image-wrapper::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #E9E7EC;
    border-radius: 20px;
}

.my-contents-wrapper .user-content-wrapper .content-image-wrapper::-webkit-scrollbar-thumb:hover {
background-color: #E9E7EC;
}
.my-contents-wrapper .user-content-wrapper .content-image-wrapper .card-item {
    position: relative;
    min-width: 126px;
    width: 126px;
    height: 147px;
    /* border-radius: 5px; */
    background: url('../../assets/banner/community-list-content.png') no-repeat center / cover;
    margin-left: 2px;
    color: #fff;
    background-position: center !important;
    background-size: cover !important;
    /* margin: 0 10px; */
}
.my-contents-wrapper .user-content-wrapper .content-image-wrapper .card-item .blur-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}
.my-contents-wrapper .user-content-wrapper .content-image-wrapper .card-item:nth-child(1) {
    margin-left: 20px;
}
.my-contents-wrapper .community-contents-none {
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.my-contents-wrapper .community-contents-none .txt-contents {
    display: flex;
    flex-direction: column;
}
.my-contents-wrapper .community-contents-none .txt-contents .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #78737D;
}
.my-contents-wrapper .community-contents-none .txt-contents .go-to-write {
    width: 165px;
    height: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    border: 1px solid #000;
    border-radius: 30px;
    margin-top: 20px;
}
