.contents {
  padding: 30px 10px 10px 10px;
  .iconArea {
    display: flex;
    justify-content: center;
    align-items: center;
    .bookmarkIcon {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: relative;
      background: url('/assets/icons/popup_bookmark.svg') no-repeat center /
        contain;
    }
    margin-bottom: 8px;
  }
  .txtArea {
    color: var(--color-black, #000);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.36px;
    margin-bottom: 10px;
  }
  .descriptions {
    color: var(--color-gray-6, #433E48);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
    margin-bottom: 30px;
  }
  .buttonArea {
    background: #fff;
  }
}
