.option_selector_wrapper {
  height: 480px;
  position: relative;
}
.option_wrapper {
  width: 100%;
  margin-top: 5px;
  position: relative;

  & + .option_wrapper {
    margin-top: 8px;

    .option_list.open_list {
      max-height: 239px;
    }
  }
}
.option_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e9e7ec;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  padding: 15px 20px;
  cursor: pointer;
  &.open {
    border: 1px solid #9f9ca3;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.disabled_option_info {
    color: #9f9ca3;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.32px;

    background: #f7f7fc;
    border: 1px solid #ffff;
  }
}
.option_list {
  &.open_list {
    border-left: 1px solid #9f9ca3;
    border-right: 1px solid #9f9ca3;
    border-bottom: 1px solid #9f9ca3;
    li {
      padding: 16px 20px;
      border-bottom: 1px solid #f7f7fc;
      text-align: left;
      line-height: 18px;
      display: flex;
      justify-content: space-between;
      box-sizing: content-box;
      &.soldout_options {
        color: #d2d0d5;
      }
    }
  }
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.32px;
  max-height: 297px;
  overflow-y: auto;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
}

.buy_info_panel {
  margin-top: 20px;
  background: #f7f7fc;
  padding: 16px 20px;
  position: relative;
  border-radius: 5px;
  border: 1px solid #e9e7ec;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .option {
    display: block;
    text-align: left;
    color: var(--b, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-top: 8px;
  }
  .price_wrapper {
    display: flex;
    margin-top: 18px;
    justify-content: space-between;
    .price {
      color: var(--b, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
    .counter {
      display: flex;
      align-items: center;
      .count {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 30px;
        background: #fff;
        margin: 0 7px;
        border: 1px solid #e9e7ec;
      }
    }
  }
}

.warning {
  color: var(--gray-4, #9f9ca3);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.24px;
  text-align: left;
  margin-top: 10px;
}

.error_count {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ff3e48;
  margin-top: 10px;
}

.modal_footer {
  position: absolute;
  bottom: 13px;
  left: 20px;
  right: 20px;
}
.disable_button {
  background: #e9e7ec !important;
  color: #fff !important;
}
