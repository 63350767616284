.shareSheet {
    // padding: 10px 0 36px 0;
    .item {
        // height: 52px;
        padding: 18px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        &:nth-child(1) {
            border-bottom: 1px solid #F7F7FC;
        }
        .kakaoIcon {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url('/assets/icons/kakako_modal_logo.svg') no-repeat center / cover;
        }
        .linkIcon {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url('/assets/icons/link_modal_logo.svg') no-repeat center / cover;
        }
        .txt {
            margin-left: 10px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            letter-spacing: -0.32px;
        }
    }
}
