.pick-content {
  background-color: #000;
  color: #fff;
}

.pick-content .recommend-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 0 0 0 20px;
}

.pick-content .title {
  background: linear-gradient(90deg, #FF3E48 0%, #FF435F 44%, #FF4B84 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: 'SBAggroB';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.02em;
  margin: 19px 0 0 30px;
  text-align: left;
}

.pick-content .description {
  margin: 10px 0 0 30px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */
  letter-spacing: -0.02em;
  /* gray2 */
  color: #E9E7EC;
}
