.plus-icon {
  background: url('../../assets/icons/plus.svg') no-repeat center;
}
.link-to-shop-icon {
  background: url('../../assets/icons/link-to-shop.svg') no-repeat center;
}
.arrow-down-black-icon {
  background: url('../../assets/icons/arrow-down-black.svg') no-repeat center;
}
.arrow-down-black-darker-icon {
  background: url('../../assets/icons/arrow-down-black-darker.svg') no-repeat
    center;
}
.arrow-down-white-icon {
  background: url('../../assets/icons/arrow-down-white.svg') no-repeat center;
}
.option-white-threedot-icon {
  background: url('../../assets/icons/option-white-threedot.svg') no-repeat
    center;
}
.white-close-icon {
  background: url('../../assets/icons/white-close.svg') no-repeat center;
}
.gray-close-icon {
  background: url('../../assets/icons/gray-close.svg') no-repeat center;
}
.light-gray-close-icon {
  background: url('../../assets/icons/light-gray-close.svg') no-repeat center;
}
.retry-icon {
  background: url('../../assets/icons/refresh.svg') no-repeat center;
}
.reaction-best-icon {
  background: url('../../assets/icons/empty-heart.svg') no-repeat center;
}
.reaction-best-icon.active {
  background: url('../../assets/icons/pink-heart.svg') no-repeat center;
}
.reaction-best-large-icon {
  background: url('../../assets/icons/empty-heart.svg') no-repeat center;
}
.reaction-best-large-icon.active {
  background: url('../../assets/icons/icon_pink_fiiled_heart_large.svg')
    no-repeat center;
}
.reaction-like-icon {
  background: url('../../assets/icons/thumbs_up.svg');
}
.community-like-icon {
  background: url('../../assets/icons/community-like.svg');
}
.community-comment-icon {
  background: url('../../assets/icons/community-comment.svg');
}
.reaction-like-icon.active {
  background: url('../../assets/icons/orange_thumbs_up.svg') no-repeat center;
}
.reaction-normal-icon {
  background: url('../../assets/icons/triangle.svg') no-repeat center;
}
.reaction-normal-icon.active {
  background: url('../../assets/icons/green_triangle.svg') no-repeat center;
}
.reaction-bad-icon {
  background: url('../../assets/icons/thumbs_down.svg') no-repeat center;
}
.reaction-bad-icon.active {
  background: url('../../assets/icons/puple_thumbs_down.svg') no-repeat center;
}
.dice-icon {
  background: url('../../assets/icons/dice.svg') no-repeat center;
}
.no-image-icon {
  background: url('../../assets/icons/user_default.svg') no-repeat center;
}
.circle-white-plus-icon {
  background: url('../../assets/icons/circle-white-plus.svg') no-repeat center;
}
.arrow-right-icon {
  background: url('../../assets/icons/arrow-right-at-brand.svg') no-repeat
    center;
}
.arrow-right-for-magazine-icon {
  background: url('../../assets/icons/arrow-right-for-magazine.svg') no-repeat
    center;
}
.arrow-right2-icon {
  background: url('../../assets/icons/arrow-right2.svg') no-repeat center;
  background-size: contain;
}
.arrow-right-thick-icon {
  background: url('../../assets/icons/arrow-right-thick-icon.svg') no-repeat
    center;
}
.arrow-right-thick-icon-white {
  background: url('../../assets/icons/arrow-right-thick-icon-white.svg')
    no-repeat center;
}
.arrow-right-white-icon {
  background: url('../../assets/icons/arrow-right-white.svg') no-repeat center;
}
.arrow-left-white {
  background: url('../../assets/icons/arrow-left-white.svg') no-repeat center;
}
.arrow-right-white {
  background: url('../../assets/icons/arrow-right-white.svg') no-repeat center;
}
.arrow-right-for-mypage {
  background: url('../../assets/icons/arrow-right-for-mypage.svg') no-repeat
    center;
  width: 20px;
  height: 20px;
}
.my-options-icon {
  background: url('../../assets/icons/my-options.svg') no-repeat center;
}
.help-circle-icon {
  background: url('../../assets/icons/help-circle-icon.svg') no-repeat center;
}
.white-person-icon {
  background: url('../../assets/icons/white-person-icon.svg') no-repeat center;
}
.white-person-filled-icon {
  background: url('../../assets/icons/white-person-filled-icon.svg') no-repeat
    center;
}
.gray-arrow-right-icon {
  background: url('../../assets/icons/arrow-right-gray-icon.svg') no-repeat
    center;
}
.stick-arrow-right-icon {
  background: url('../../assets/icons/stick_arrow_right_icon.svg') no-repeat
    center;
}
.filter-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #000;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  margin-left: 7px;
  color: #000000;
  padding: 0 3px;
}
.filter-icon:nth-child(1) {
  margin-left: 0;
}
.filter-icon.kurly.active {
  border: 0;
  background-color: #461a69;
  color: #fff;
}
.filter-icon.coupang.active {
  border: 0;
  background: url('../../assets/imgs/coupang_bg.svg') no-repeat center;
  color: #fff;
}
.filter-icon.bmart.active {
  border: 0;
  background-color: #39beb6;
  color: #fff;
}
.filter-icon.ssg.active {
  border: 0;
  background: url('../../assets/imgs/ssg_bg.svg') no-repeat center;
  color: #fff;
}
.filter-icon.naver.active {
  border: 0;
  background: url('../../assets/imgs/naver_bg.svg') no-repeat center;
  color: #fff;
}
.filter-icon.etc.active {
  border: 0;
  background-color: #000;
  color: #fff;
}
.sns-icon {
  display: inline-block;
  width: 26px;
  height: 26px;
}
.sns-icon.youtube.inactive {
  background: url('../../assets/icons/youtube-unselect-icon.svg') no-repeat
    center;
  background-size: cover;
  cursor: default !important;
}
.sns-icon.youtube.active {
  background: url('../../assets/icons/youtube-select-icon.svg') no-repeat center;
  background-size: cover;
  cursor: default;
}
.sns-icon.instagram.inactive {
  background: url('../../assets/icons/instagram-unselect-icon.svg') no-repeat
    center;
  background-size: cover;
  cursor: default !important;
}
.sns-icon.instagram.active {
  background: url('../../assets/icons/instagram-select-icon.svg') no-repeat
    center;
  background-size: cover;
  cursor: default;
}
.sns-icon.naver-blog.inactive {
  background: url('../../assets/icons/naver-blog-unselect-icon.svg') no-repeat
    center;
  background-size: cover;
  cursor: default !important;
}
.sns-icon.naver-blog.active {
  background: url('../../assets/icons/naver-blog-select-icon.svg') no-repeat
    center;
  background-size: cover;
  cursor: default;
}
.pen-icon {
  background: url('../../assets/icons/pencil-icon.svg') no-repeat center;
}
.share-icon {
  background: url('../../assets/icons/share.svg') no-repeat center;
}
.comment-icon {
  background: url('../../assets/icons/comment-btn.svg') no-repeat center;
}
.like-icon {
  background: url('../../assets/icons/like-btn.svg') no-repeat center;
}
.menu-button-icon {
  background: url('../../assets/icons/menu-icon.svg') no-repeat center;
}
.close-white-icon {
  background: url('../../assets/icons/close-white-icon.svg') no-repeat center;
}
.close-gray-icon {
  background: url('../../assets/icons/close_gray.svg') no-repeat center;
}
.bookmark-icon {
  background: url('../../assets/icons/empty_heart.svg') no-repeat center;
}
.see-count-icon {
  background: url('../../assets/icons/eye-shape.svg') no-repeat center;
}
.go-to-list-icon {
  background: url('../../assets/icons/go-to-list-arrow.svg') no-repeat center /
    contain;
}
.arrow-right-at-brand {
  background: url('../../assets/icons/arrow-right-at-brand.svg') no-repeat
    center / contain;
}
.bookmark-pink-filled {
  background: url('../../assets/icons/pink_filled_heart.svg') no-repeat center;
}
.bookmark-pink {
  background: url('../../assets/icons/bookmark-pink.svg') no-repeat center /
    contain;
}
.gear-icon {
  background: url('../../assets/icons/gear-icon.svg') no-repeat center;
}
.close-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
}
.circle-check-icon {
  background: url('../../assets/icons/circle-check-icon.svg') no-repeat center;
}
.main-pink-cross-icon {
  background: url('../../assets/icons/main-pink-cross.svg') no-repeat center;
}
.add-picture-icon {
  background: url('../../assets/icons/add-picture-icon.svg') no-repeat center;
}
.trash-icon {
  background: url('../../assets/icons/trash-icon.svg') no-repeat center;
}
.search-black-icon {
  background: url('../../assets/icons/search-black-icon.svg') no-repeat center;
}
.search-icon {
  background: url('../../assets/icons/search-black-icon.svg') no-repeat center;
}
.search-gray-icon {
  background: url('../../assets/icons/search-gray-icon.svg') no-repeat center;
}
.bell-icon {
  background: url('../../assets/icons/bell-icon.svg') no-repeat center;
}
.arrow-right-main-pink-icon {
  background: url('../../assets/icons/arrow-right-main-pink-icon.svg') no-repeat
    center;
}
.email-icon {
  background: url('../../assets/icons/email_icon.svg') no-repeat center;
}
.kakao-login-icon {
  background: url('../../assets/icons/kakao-login-icon.svg') no-repeat center;
}
.kakao-share-icon {
  background: url('../../assets/icons/kakao-share-icon.svg') no-repeat center;
}
.google-login-icon {
  background: url('../../assets/icons/google-login-icon.svg') no-repeat center;
}
.naver-login-icon {
  background: url('../../assets/icons/naver-login-icon.svg') no-repeat center;
}
.apple-login-icon {
  background: url('../../assets/icons/apple-login-icon.svg') no-repeat center;
}
.small-pink-x-icon {
  background: url('../../assets/icons/small-pink-x-icon.svg') no-repeat center;
}
.large-pink-x-icon {
  background: url('../../assets/icons/large-pink-x-icon.svg') no-repeat center;
}
.pink-checker-icon {
  background: url('../../assets/icons/pink-checker-icon.svg') no-repeat center;
}
.white-ring-icon {
  background: url('../../assets/banner/white-ring-icon.svg') no-repeat center;
}
.white-ring-icon-red {
  background: url('../../assets/banner/white-ring-icon-red.svg') no-repeat
    center;
}
.black-ring-icon {
  background: url('../../assets/icons/black-ring-icon.svg') no-repeat center;
}
.black-ring-icon-red {
  background: url('../../assets/icons/black-ring-icon-red.svg') no-repeat center;
}
.home-active-icon {
  background: url('../../assets/icons/home-active-icon.svg') no-repeat center;
}
.home-deactive-icon {
  background: url('../../assets/icons/home-deactive-icon.svg') no-repeat center;
}
.magazine-active-icon {
  background: url('../../assets/icons/magazine-active-icon.svg') no-repeat
    center;
}
.magazine-deactive-icon {
  background: url('../../assets/icons/magazine-deactive-icon.svg') no-repeat
    center;
}
.collection-active-icon {
  background: url('../../assets/icons/collection-active-icon.svg') no-repeat
    center;
}
.collection-deactive-icon {
  background: url('../../assets/icons/collection-deactive-icon.svg') no-repeat
    center;
}
.comm-active-icon {
  background: url('../../assets/icons/comm-active-icon.svg') no-repeat center;
}
.comm-deactive-icon {
  background: url('../../assets/icons/comm-deactive-icon.svg') no-repeat center;
}
.my-active-icon {
  background: url('../../assets/icons/my-active-icon.svg') no-repeat center;
}
.my-deactive-icon {
  background: url('../../assets/icons/my-deactive-icon.svg') no-repeat center;
}
.white-home-icon {
  background: url('../../assets/icons/white-home-icon.svg') no-repeat center;
}
.ticket-icon {
  background: url('../../assets/icons/ticket_icon.svg') no-repeat center;
}

.add-picture-white-icon {
  background: url('../../assets/icons/add-picture-white-icon.svg') no-repeat
    center;
}
