.noCouponArea {
    height: 378px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .noCouponIcon {
        display: block;
        width: 50px;
        height: 50px;
        background: url('/assets/icons/coupon_modal_no_coupon_icon.svg') no-repeat center / cover;
        margin-bottom: 20px;
    }
    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;
        color: #78737D;
    }
}
.couponModalFooter {
    position: absolute;
    bottom: 0;
    left:0;
    right: 0;
    padding: 13px 20px 20px 20px;
    border-top: 1px solid #E9E7EC;    
    background: #fff;
    button {
        width: 100%;
        height: 50px;
        &.confirm {
            background: #fff;
            border: 1px solid #000;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            letter-spacing: -0.32px;
            border-radius: 5px;
        }
        &.noDiscount {
            background: #fff;
            border: 1px solid #000;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            letter-spacing: -0.32px;
            border-radius: 5px;
        }
        &.applyCoupon {
            background: #000;
            border: 1px solid #000;
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            letter-spacing: -0.32px;
            border-radius: 5px;
        }
    }
}
.noAuthArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    .txtArea {
        margin-top: 40px;
        h3 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
            letter-spacing: -0.32px;
            color: #000;
        }
        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: -0.32px;
            margin-top: 6px;
        }
    }
    .authButton {
        width: 192px;
        height: 30px;
        background: #000;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
        letter-spacing: -0.28px;
        margin-top: 20px;
        .arrow_icon {
            width: 12px;
            height: 12px;
            background: url('/assets/icons/coupon_modal_arrow_right_white.svg') no-repeat center / cover;
            display: inline-block;
            margin-left: 5px;
        }
    }
    .dummyCoupons {
        margin-top: 30px;
        width: 100%;
        min-height: 248px;
        background: url('/assets/banner//coupon_selection_modal_back.png') no-repeat center / contain;
    }
}
.couponWrapper {
    border: 1px solid #E9E7EC;
    padding: 16px 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    &.active {
        border: 1px solid #000;
    }
    &.disabledCard {
        background: #F7F7FC !important;
        color: #9F9CA3 !important;
        border: 0 !important;
        cursor: inherit;
    }
    .titleWrapper {
        display: flex;
        justify-content: space-between;
        div:nth-child(1) {
            h3 {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%; /* 18px */
                letter-spacing: -0.36px;
                text-align: left;
            }
            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 16px */
                letter-spacing: -0.32px;
                margin-top: 8px;
                text-align: left;
            }
        }
        .checkerWrapper {
            .checker {
                display: inline-block;
                width: 16px;
                height: 16px;
                &.active {
                    background: url('/assets/icons/coupon_modal_checker_active.svg') no-repeat center / cover;
                }
                &.disabled {
                    background: url('/assets/icons/coupon_modal_checker_disabled.svg') no-repeat center / cover;
                }
            }
        }
    }
    .infoArea {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .info {
            p {
                text-align: left;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; /* 15.6px */
                letter-spacing: -0.24px;
                color: #9F9CA3;
                margin-top: 2px;
                &:nth-child(1) {
                    margin-top: 0;
                }
            }
        }
        .discountPriceArea {
            width: 100px;
            height: 24px;
            padding: 6px 10px;
            border-radius: 16px;
            color: #FF4B84;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 12px */
            letter-spacing: -0.24px;
            background: #F7F7FC;
        }
    }
}
.coupons {
    padding-top: 20px;
    padding-bottom: 84px;
    height: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    .dummy_coupon {
        height: 88px;
        width: 100%;
    }
}

.caution_txt {
    color: #9F9CA3;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.24px;
    text-align: left;
    padding: 20px 0;
}

.onlyMobile {
    display: flex;
    justify-content: space-between;
    div {
        &:nth-child(1) {
            color: var(--5, #78737D);
            font-size: 12px;
            font-weight: 500;
            line-height: 130%; /* 15.6px */
            letter-spacing: -0.24px;
            display: flex;
            align-items: center;
            .errorIcon {
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url('/assets/icons/error_outline.svg') no-repeat center / cover;
                margin-right: 4px;
            }
        }
        &:nth-child(2) {
            color: var(--b, #000);
            font-size: 12px;
            font-weight: 400;
            line-height: 100%; /* 12px */
            letter-spacing: -0.24px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .arrowRight {
                display: inline-block;
                width: 12px;
                height: 12px;
                background: url('/assets/icons/coupon_selector_right_arrow.png') no-repeat center / cover;
            }
        }
    }
}