.coupon-card {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.coupon-content {
  display: flex;
  align-items: center;
}

.coupon-image {
  width: 80px;
  height: 80px;
  background-color: #e0e0e0;
  position: relative;
  border-radius: 4px;
}

.brand-home {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  background-color: rgba(17, 11, 11, 0.6);
  color: white;
  text-align: center;
  padding: 4px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  letter-spacing: -0.24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coupon-details {
  margin-left: 16px;
  text-align: left;
}

.coupon-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.coupon-expiry,
.coupon-condition {
  font-size: 14px;
  color: #757575;
}

.coupon-actions {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.use-coupon {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 8px 16px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--G6, #433e48);
  background: var(--color-white, #fff);
  flex: 1;
  color: var(--B, #000);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
  letter-spacing: -0.28px;
  height: 40px;
}

.used-coupon,
.expired-coupon {
  border-radius: 4px;
  background: var(--G2, #e9e7ec);
  padding: 8px 16px;
  margin-right: 8px;
  color: var(--G4, #9f9ca3);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
  letter-spacing: -0.28px;
  cursor: not-allowed;
  height: 40px;
  flex: 1;
}

.usage-history {
  background-color: #ffffff;
  border: 1px solid #e9e7ec;
  border-radius: 4px;
  padding: 8px 16px;
  margin-right: 8px;
  cursor: pointer;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  color: #78737d;
}

.location-icon {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.numberWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.36px;
    // margin-top: 60px;
  }
  .numbers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
    color: var(--G3, #d2d0d5);
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 30px */
    letter-spacing: -0.6px;
    &.entered {
      color: var(--B, #000);
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 30px */
      letter-spacing: -0.6px;
    }
  }
}
.divider {
  height: 1px;
  width: 270px;
  background: #e9e7ec;
}
.payInfo {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 42px;
  width: 100%;
  div {
    margin-bottom: 8px;
    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
}
.bottomWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  background: #fff;
}
.warningNums {
  text-align: center;
  color: var(--color-red, #ff3e48);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: -0.28px;
}
.giftBeneftsNotice {
  background: rgba(255, 75, 132, 0.1);
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  align-self: stretch;
  .notice_txt {
    color: var(--G6, #433e48);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.32px;
  }
  .notice_num {
    background: #433e48;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
  }
  color: var(--G6, #433e48);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: -0.32px;
}

.pricePanel {
  border-radius: 4px;
  border: 1px solid var(--G3, #d2d0d5);
  background: var(--W, #fff);
  padding: 12px 16px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--G5, #78737d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
    &.final {
      color: var(--B, #000);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 27px */
      letter-spacing: -0.36px;
      margin-bottom: 4px;
    }
  }
}

.itemWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--G3, #d2d0d5);
  background: var(--W, #fff);
  .imgWrapper {
    width: 80px;
    height: 80px;
    margin-right: 15px;
    img {
      border-radius: 5px;
      height: 80px;
    }
  }
  .txtWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .titWrapper {
      color: var(--black, var(--color-black, #000));
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.32px;
      margin-bottom: 10px;
    }
    .contWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: var(--G5, #78737d);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 12px */
      letter-spacing: -0.24px;
      span {
        margin-bottom: 4px;
      }
    }
  }
}

.benefitTxt {
  color: var(--G5, #78737D);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 18px */
  letter-spacing: -0.36px;
  padding: 30px 0 20px 0;
}

.benefitContainer {
  background: #ECECEC !important;
  height: 100vh;
}

.productCardWrapper {
  padding: 0 57.5px;
}

.productCard {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid var(--G3, #D2D0D5);
  background: var(--W, #FFF);
}

.productHeader {
  margin-bottom: 15px;
}

.productInfo {
  h3 {
    color: var(--black, var(--color-black, #000));
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
    letter-spacing: -0.48px;
    text-align: left;
    margin: 15px 0;
  }
  .productPrice {
    color: var(--G5, #78737D);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    text-align: left;
    div:nth-child(2) {
      margin: 6px 0;
    }
  }
}

.benefitMethod {
  margin-top: 20px;
  text-align: left;
  padding: 0 20px;
  strong {
    color: var(--B, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
    margin-bottom: 10px;
  }
  div {
    color: var(--B, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.28px;
  }
}
.benefitBtn {
  margin-top: 23px;
  padding: 13px 20px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  button {
    width: 100%;
    height: 50px;
    display: flex;
    height: 50px;
    padding: 0px 13px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 5px;
    background: var(--B, #000);
    color: var(--W, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
  }
}
