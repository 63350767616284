// .dimmed_wrapper {
//   position: absolute;
//   z-index: 3;
//   left: 0;
//   right: 0;
//   height: 100px;
//   background: linear-gradient(0, rgba(0, 0, 0, 0) 11.88%, #000000 95.86%);
// }

:global {
  .homeSwiper {
    width: 100%;
    height: 100%;

    .swiper-pagination {
      display: flex;
      z-index: 10;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .swiper-pagination-bullet {
      display: inline-block;
      flex: 1;
      height: 3px;
      background: rgba(255, 255, 255, 0.5);

      &.swiper-pagination-bullet-active {
        background: #ff4b84;
      }
    }
  }
}
