.curatorInfoWrapper {
    min-height: 100vh;
}
.curatorInfo {
    padding-top: 40px;
}
.backNavigation {
    margin: 20px 20px 40px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
}
.backBtn {
    display: inline-flex;
    width: 90px;
    height: 26px;
    background: #000;
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding: 6px 10px;
    justify-content: flex-start;
    align-items: center;
    & > span {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 4px;
    }
}
.profile {
    display: flex;
    margin: 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E9E7EC;
    background: #fff;
}
.curatorImage {
    min-width: 90px;
    width: 90px;
    height: 90px;
    border-radius: 90px;
    background: url('/assets/banner//curator-dummy-img.png');
    background: cover;
}
.txtInfo {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.nickName {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    text-align: left;
}
.hashtags {
    margin-top: 10px;
    color: #78737D;
    text-align: left;
    line-height: 100%;
}
.tag {
    margin-right: 5px;
    font-weight: 400;
    font-size: 14px;
}
.message {
    margin: 15px 20px 10px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: left;
    background: #fff;
}
.sns {
    margin: 0 20px 30px 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background: #fff;
}
.snsIcon {
    margin-left: 4px;
}
.curatorCreatedItems {
    background: #F7F7FC;
    padding-top: 40px;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 335px);
    /* width: 335px; */
}
.noItems {
    margin-top: 60px;
}
.noItemTxt {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #78737D;
    margin-top: 20px;
}
.noItemBtn {
    margin-top: 20px;
}
.moveToList {
    width: 165px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    padding: 8px 20px 8px 20px;
}
