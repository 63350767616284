h/* 읽지않은 콘텐츠 <!-- start --> */
.list-content {
  background: #fff;
  padding-top: 40px;
}
.list-content .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.64px;
  letter-spacing: -0.02em;
  text-align: left;
  margin: 18px 0 20px 20px;
  white-space: pre-line;
}
.list-content .description {
  margin: 10px 20px 40px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */
  letter-spacing: -0.02em;
  /* gray6 */
  color: #433E48;
  text-align: left;
}
.list-content .list-item-divider {
  height: 0;
  width: 80%;
  margin-left: 20px;
  border-bottom: 1px solid #E9E7EC;
}
/* 읽지않은 콘텐츠 <!--// end --> */