.contents {
  animation: fadeIn 1s;
}
.popularArea {
  padding-top: 30px;
  .popularAreaSubTit {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: -0.02em;
    margin-bottom: 10px;
  }
  .popularAreaTitle {
    font-size: 28px;
    font-weight: 700;
    line-height: 36.4px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 16px;
    white-space: pre-line;
  }
  .popularAreaList {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .popularItem {
    margin-right: 12px;
    cursor: pointer;
    .txt {
      margin-top: 10px;
      color: var(--color-gray-6, #433e48);
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 13px */
      letter-spacing: -0.26px;
    }
  }
}
.areaImg {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  &.sengsu {
    background: url('../../assets/imgs/areas/seongsu.jpg') no-repeat center /
      cover;
  }
  &.yongsan {
    background: url('../../assets/imgs/areas/yeongsan.jpg') no-repeat center /
      cover;
  }
  &.apgujeong {
    background: url('../../assets/imgs/areas/apgujung.jpg') no-repeat center /
      cover;
  }
  &.eljiro {
    background: url('../../assets/imgs/areas/eljiro.jpg') no-repeat center /
      cover;
  }
  &.jongro {
    background: url('../../assets/imgs/areas/jongro.jpg') no-repeat center /
      cover;
  }
  &.mangwon {
    background: url('../../assets/imgs/areas/mangwon.jpg') no-repeat center /
      cover;
  }
  &.samgakji {
    background: url('../../assets/imgs/areas/samgakji.jpg') no-repeat center /
      cover;
  }
  &.jamsil {
    background: url('../../assets/imgs/areas/jamsil.jpg') no-repeat center /
      cover;
  }
  &.munre {
    background: url('../../assets/imgs/areas/munre.jpg') no-repeat center /
      cover;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
