.link-to-shop-button {
    width: 335px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #FF4B84;
    border: 1px solid #FF4B84;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;

    letter-spacing: -0.02em;

    /* white */
    color: #FFFFFF;
}
.link-to-shop-button .link-to-shop-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
.modal.btn {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal.btn.main-pink-filled {
    background-color: #FF4B84;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
}
.modal.btn.black-filled {
    background-color: #000;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
}
.modal.btn.empty {
    color: #9F9CA3;
}
.modal.btn.main-pink-border {
    border: 1px solid #FF4B84;
}
.modal.btn.disabled {
    border: 1px solid #E9E7EC !important;
    background-color: #E9E7EC !important;
    color: #fff !important;
}
.login-button {
    background: url('../../assets/imgs/kakao-button-bg.svg') no-repeat center;
    background-size: 100% 100%;
}
.main-cont.btn.main-pink-border {
    border: 1px solid #FF4B84;
    color: #FF4B84;
    font-weight: 600;
    font-size: 16px;
}
.main-cont.btn.main-black-border {
    border: 1px solid #000;
    color: #000;
    font-weight: 600;
    font-size: 16px;
}
.main-cont.btn.main-black-filled {
    background-color: #000;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
}
.main-cont.btn.main-pink-filled {
    background-color: #FF4B84;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
}
.main-cont.btn.disabled {
    border: 1px solid #E9E7EC !important;
    background-color: #E9E7EC !important;
    color: #fff !important;
}
.main-cont.btn.main-gray1-filled {
    background-color: #F7F7FC;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
}
.main-cont.btn.main-gray3-border {
    border: 1px solid #D2D0D5;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
}
.main-cont.btn {
    width: 100%;
    height: 50px;
    border-radius: 5px;
}
.my-info-menu.btn {
    width: 100%;
    height: 56px;
    border-bottom: 1px solid #F7F7FC;
}
