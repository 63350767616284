
.join-user-info {
    padding-top: 40px;
}
.join-user-info .top-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.join-user-info .top-contents strong {
    font-weight: 700;
    font-size: 26px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
}
.join-user-info .top-contents span {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */
    text-align: center;
    letter-spacing: -0.02em;
    /* gray5 */
    color: #78737D;
    margin-top: 10px;
    margin-bottom: 35px;
}
.join-user-info .user-info-form .user-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.join-user-info .user-info-form .user-profile .profile-image {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-size: cover !important;
}
.join-user-info .user-info-form .user-profile .profile-image.no-image {
    background: #E9E7EC;
}

    
.join-user-info .user-info-form .user-profile .profile-image .no-image-icon {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-size: cover;
}
.join-user-info .user-info-form .user-profile .profile-image .circle-white-plus-icon {
    display: inline-block;
    position: absolute;
    bottom: 0;
    width: 18px;
    height: 18px;
    right: 0;
}
.join-user-info .user-info-form .user-profile .remove-image-txt {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    text-align: center;
    letter-spacing: -0.02em;
    /* gray3 */
    color: #D2D0D5;
    margin-top: 10px;
}
.join-user-info .user-info-form .info-form {
    margin-top: 20px;
    /* padding: 0 20px; */
}
.join-user-info .user-info-form .info-form .input-form .label {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
    padding: 0 20px;
}
.join-user-info .user-info-form .info-form .input-form .label .option {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.02em;
    /* gray3 */
    color: #D2D0D5;
}
.join-user-info .user-info-form .info-form .input-form.name .input {
    border-top: 1px solid #F7F7FC;
    border-bottom: 1px solid #F7F7FC;
    height: 54px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 40px 0;
    padding: 0 20px;
}

.join-user-info .user-info-form .info-form .input-form.name .input input[type='text'] {
    height: 24px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    letter-spacing: -0.02em;
}
.join-user-info .user-info-form .info-form .input-form .input.age {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 20px 0;
    border-top: 1px solid #F7F7FC;
    border-bottom: 1px solid #F7F7FC;
    padding: 20px 20px;
    align-items: center;
}
.join-user-info .user-info-form .info-form .input-form .input.age .age-group {
    font-weight: 500;
    font-size: 16px;
} 
.join-user-info .user-info-form .info-form .input-form .input.age .gender-group {
    font-weight: 400;
    font-size: 16px;
} 

.join-user-info .user-info-form .info-form .input-form .input.age input[type='text'] {
    width: 80px;
    height: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    letter-spacing: -0.02em;
    text-align: center;
}


.join-user-info .user-info-form .info-form .input-form .input.age .gender-group input[type='radio'] {
    vertical-align: middle;
    appearance: none;
    border: 2px solid #E9E7EC;
    border-radius: 50%;
    margin-right: 10px;
    width: 20px;
    height: 20px;
}
.join-user-info .user-info-form .info-form .input-form .input.age .gender-group input[type='radio']:checked {
    background-color: #000000;
    border: 4px solid #fff !important;
    border-radius: 50% !important;
    box-shadow: 1px 1px #E9E7EC, -1px -1px #E9E7EC;
    /* outline: max(1px, 0.1em) solid #E9E7EC !important; */
}
.join-user-info .user-info-form .info-form .input-form .input.age .gender-group .r-txt {
    display: inline-block;
    width: 45px;
    text-align: left;
}
.join-user-info .user-info-form .info-form .input-form .input.age .gender-group .r-txt:nth-child(1) {
    margin-right: 10px;
}
.join-user-info .frequntly-used-market {
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.join-user-info .frequntly-used-market .filter-icon {
    background-size: 100% 100%;
    width: 64px;
    height: 64px;
    border-radius: 300px;
    padding: 0 8px
}
.join-user-info .frequntly-used-market .etc_txt {
    width: 255px;
    height: 24px;
    font-weight: 400;
    font-size: 14px;
    margin-left: 17px;
    padding: 2px;
}

.join-user-info .frequntly-used-market-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
    padding: 0 20px;
    margin: 40px 0 20px 0;
}

.user-agree {
    height: 100vh;
    text-align: left;
    padding: 5em 20px 0 20px;
}
.user-agree .agree-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
}
.user-agree .agree-title strong {
    color: #FF4B84;
}
.user-agree .agree-txt-1 {
    margin-top: 60px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}
.user-agree .agree-txt-1 strong {
    font-weight: 600;
}
.user-agree .agree-txt-1 strong .emphasize {
    color: #FF4B84;
}
.user-agree .warning-illegal {
    margin-top: 20px;
    padding: 7px;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    background: #F7F7FC;
}
.user-agree .warning-illegal .emphasize {
    font-weight: 700;
}
.user-agree .user-agree-check-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 160px;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
}
.user-agree .user-agree-check-box input[type='checkbox'] {
    /* display: block; */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    /* margin: 0; */

    margin-right: 17px;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border: 2px solid currentColor;
    border-radius: 2px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
.user-agree .user-agree-check-box input[type="checkbox"]::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}
.user-agree .user-agree-check-box input[type="checkbox"]:checked {
  background: #FF4B84;
  border: 0;
}
.user-agree .user-agree-check-box input[type="checkbox"]:checked::before {
  transform: scale(1);
  background-color: #fff;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.join-user-info .food-life-form {
    padding: 0 20px;
}
.join-user-info .food-life-form .food-life-selector {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    margin-top: 40px;
}
.join-user-info .food-life-form .food-life-selector strong {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 15px;
}

.join-user-info .food-life-form .food-life-selector .food-life-toggler {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.join-user-info .food-life-form .food-life-selector .food-life-btn {
    padding: 8px 18px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 20px;
    border: 1px solid #E9E7EC;
    margin-right: 5px; 
    margin-bottom: 10px;
    letter-spacing: -2%;
}
.join-user-info .food-life-form .food-life-selector .food-life-btn.active {
    background: #000;
    color: #fff;
}
.my-home {
    position: relative;
}
.my-home .my-knewnew-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 50px;
    width: 375px;
    padding: 0 30px;
}
.my-home .my-knewnew-footer .knewnew .sns-links {
    display: flex;
    justify-content: flex-start;
}
.my-home .my-knewnew-footer .knewnew .sns-links .sns-icon {
    margin-right: 5px;
}
.my-home .my-knewnew-footer .black-logo {
    background: url('../../assets/imgs/knewnew_black_logo_new.svg') center no-repeat;
    width: 106px;
    height: 18px;
    background-size: cover;
}
.my-knewnew-options .my-knewnew-footer .signout {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    /* gray4 */
    color: #9F9CA3;
    text-align: left;
    padding: 20px 20px 0 20px;
}
.my-knewnew-options .my-knewnew-footer .signout span:nth-child(1) {
    text-decoration-line: underline;
}
.sign-up-complete-wrapper .contents {
    /* background: url('/public/login-complete-background.svg') no-repeat center / cover; */
    height: 416px;
    width: 100%;
}
.sign-up-complete-wrapper .logo {
    /* background: url('/public/login-complete-logo.svg') no-repeat center / cover; */
    width: 260px;
    height: 43px;
}
.sign-up-complete-wrapper .btn-wrapper {
    position: absolute;
    bottom: 34px;
    left: 20px;
    right: 20px;
}
.sign-up-complete-wrapper .btn-wrapper button {
    background: #fff !important;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #000;
}
.sign-up-complete-wrapper .btn-wrapper .sub-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */
    text-align: center;
    letter-spacing: -0.04em;
    /* gray4 */
    color: #9F9CA3;
    margin-top: 10px;
}